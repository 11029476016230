import React from "react";
import { NavLink } from "react-router-dom";
import "../Footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer">
        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="col-md-12 col-sm-6 col-xs-8">
              <div className="pull-right mr-4 d-flex policy">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/termsandconditions"
                >
                  {"      "}{" "}
                  <div style={{ color: "#067ADC", marginRight: "10px" }}>
                    Terms and Conditions
                  </div>
                </NavLink>

                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/privacypolicy"
                >
                  {"      "}{" "}
                  <div style={{ marginRight: "10px" }}>Privacy Policy</div>
                </NavLink>

                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/advertisingpolicy"
                >
                  {"      "}{" "}
                  <div style={{ color: "#067ADC", marginRight: "10px" }}>
                    Advertising Policy
                  </div>
                </NavLink>

                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/copyrightpolicy"
                >
                  {"      "}{" "}
                  <div style={{ marginRight: "10px" }}>Copyright Policy</div>
                </NavLink>

                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/peerreviewpolicy"
                >
                  {"      "}
                  <div style={{ color: "#067ADC", marginRight: "10px" }}>
                    {" "}
                    Our Peer Review Policy
                  </div>
                </NavLink>

                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/publicationpolicy"
                >
                  {"      "}
                  <div style={{ marginRight: "10px" }}>
                    Our Publication Policy
                  </div>
                </NavLink>

                {"      "}
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/disclaimer"
                >
                  <div style={{ color: "#067ADC", marginRight: "10px" }}>
                    Disclaimers
                  </div>
                </NavLink>

                {"      "}
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/articletypes"
                >
                  <div style={{ marginRight: "10px" }}>Article types</div>
                </NavLink>

                {"      "}
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/categories"
                >
                  <div style={{ color: "#067ADC", marginRight: "10px" }}>
                    Categories
                  </div>
                </NavLink>
              </div>
            </div>
            <hr />
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Webmed Limited, UK {currentYear}</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              {/* <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/ --> */}
              All site content, except where otherwise noted, is licensed under
              a{" "}
              <a href="http://creativecommons.org/licenses/by/3.0/">
                Creative Commons Attribution License
              </a>
            </div>
          </div>
          {/* <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <NavLink to="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </NavLink>
            <NavLink to="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </NavLink>
            <NavLink to="#" className="instagram">
              <i className="bx bxl-instagram"></i>
            </NavLink>
            <NavLink to="#" className="google-plus">
              <i className="bx bxl-skype"></i>
            </NavLink>
            <NavLink to="#" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </NavLink>
          </div> */}
        </div>
      </footer>
      {/* <!-- End Footer --> */}

      {/* <div id="preloader"></div> */}
      <NavLink
        to="#"
        className="back-to-top d-flex align-items-center justify-content-center"
        onClick={scrollToTop}
      >
        <i className="bi bi-arrow-up-short"></i>
      </NavLink>
    </>
  );
};

export default Footer;
