import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { getAllTours } from "../redux/features/tourSlice";

const Articletypes = () => {
  const dispatch = useDispatch();
  const allTours = useSelector((state) => state.tour.allTours); // Use the correct state property
  const [articleCounts, setArticleCounts] = useState({});
  const [loading, setLoading] = useState(true);

  const articleTypes = useMemo(
    () => [
      "Research articles",
      "Case report",
      "Clinical trials",
      "Editorials",
      "Meta Analysis",
      "My Opinion",
      "Original Articles",
      "Research Protocol",
      "Randomized Controlled Trials",
      "Review Articles",
      "Systematic Review",
      "WMC Advisor's Column",
      "Industry Sponsored Research",
    ],
    []
  );

  useEffect(() => {
    if (!allTours.length) {
      dispatch(getAllTours()).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch, allTours.length]);

  useEffect(() => {
    if (!loading) {
      const counts = articleTypes.reduce((acc, type) => {
        acc[type] = 0;
        return acc;
      }, {});

      allTours.forEach((tour) => {
        if (tour.approved && articleTypes.includes(tour.articletype)) {
          counts[tour.articletype] += 1;
        }
      });

      setArticleCounts(counts);
    }
  }, [allTours, articleTypes, loading]);

  return (
    <>
      <Navbar />
      <br /> <br /> <br /> <br /> <br />
      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            WebmedCentral article types
          </div>
        </p>

        <ul>
          {articleTypes.map((articletype) => (
            <li key={articletype}>
              <Link
                to={`/articletypes/${articletype}`}
                style={{ color: "#067ADC" }}
              >
                {articletype}{" "}
                <span style={{ color: "#F74B02" }}>
                  (
                  {articleCounts[articletype] !== undefined
                    ? articleCounts[articletype]
                    : "Loading..."}
                  )
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Articletypes;
