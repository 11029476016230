import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCountriesAsync } from "../redux/features/countrySlice";

const AddReviewersForm = ({ setReviewerValue }) => {
  const [reviewer, setReviewer] = useState({
    title: "Select",
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    designation: "",
    deptinstitute: "",
  });

  const [reviewersList, setReviewersList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [RevieweditIndex, setReviewEditIndex] = useState(null);

  const { userTours } = useSelector((state) => ({ ...state.tour }));
  const { id } = useParams();
  const countries = useSelector((state) => state.country);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const singleTour = userTours.find((tour) => tour._id === id);
      setReviewersList(singleTour.reviewers || []);
    } else {
      setReviewersList([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(fetchCountriesAsync());
  }, [dispatch]);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // const updatedReviewer = { ...reviewer, [name]: value };
    const updatedReviewer = { ...reviewer, [name]: name === "email" ? value : capitalizeFirstLetter(value) };
    setReviewer(updatedReviewer);
    if (editMode) {
      setReviewersList((prevReviewersList) => {
        const updatedList = [...prevReviewersList];
        updatedList[RevieweditIndex] = updatedReviewer;
        return updatedList;
      });
    }
  };

  const handleAddReviewer = (event) => {
    event.preventDefault();
    if (editMode) {
      // Update reviewer in edit mode
      const updatedReviewersList = [...reviewersList];
      updatedReviewersList[RevieweditIndex] = reviewer;
      setReviewersList(updatedReviewersList);
      setEditMode(false);
      setReviewEditIndex(null);
    } else {
      // Add reviewer in add mode
      setReviewersList((prevReviewersList) => [...prevReviewersList, reviewer]);
    }

    setReviewer({
      title: "Select",
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      designation: "",
      deptinstitute: "",
    });
  };

  useEffect(() => {
    setReviewerValue(reviewersList);
  }, [reviewersList, setReviewerValue]);

  const handleEditReviewer = (index) => {
    const selectedReviewer = reviewersList[index];
    setReviewer({
      ...selectedReviewer,
    });
    setEditMode(true);
    setReviewEditIndex(index);
  };

  const deleteReviewer = (index) => {
    const updatedReviewersList = [...reviewersList];
    updatedReviewersList.splice(index, 1);
    setReviewersList(updatedReviewersList);
  };

  return (
    <>
      <div className="card">
        <div className="card-header" style={{ color: "#067ADC" }}>
          Add reviewers
        </div>
        <br />

        <div className="row">
          <div className="col">
            <div className="form-outline">
              <select
                className="form-select"
                aria-label="Default select example"
                name="title"
                value={reviewer.title}
                onChange={handleInputChange}
              >
                <option value="Select">Please select</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
                <option value="Mr">Mr</option>
                <option value="Miss">Miss</option>
              </select>
              <p>{`You selected ${reviewer.title}`}</p>
            </div>
          </div>

          <div className="col">
            <div className="form-outline">
              <input
                type="text"
                id="form8Example3"
                className="form-control"
                name="firstname"
                value={reviewer.firstname}
                onChange={handleInputChange}
              />
              <label className="form-label" htmlFor="form8Example3">
                First name
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <input
                type="text"
                id="form8Example3"
                className="form-control"
                name="middlename"
                value={reviewer.middlename}
                onChange={handleInputChange}
              />

              <label className="form-label" htmlFor="form8Example3">
                Middle name
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <input
                type="text"
                id="form8Example4"
                className="form-control"
                name="lastname"
                value={reviewer.lastname}
                onChange={handleInputChange}
              />
              <label className="form-label" htmlFor="form8Example4">
                Last name
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <input
                type="email"
                id="form8Example5"
                className="form-control"
                name="email"
                value={reviewer.email}
                onChange={handleInputChange}
              />
              <label className="form-label" htmlFor="form8Example5">
                Email address
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <input
                type="text"
                id="form8Example5"
                className="form-control"
                name="designation"
                value={reviewer.designation}
                onChange={handleInputChange}
              />

              <label className="form-label" htmlFor="form8Example5">
                Designation
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <input
                type="text"
                id="form8Example5"
                className="form-control"
                name="deptinstitute"
                value={reviewer.deptinstitute}
                onChange={handleInputChange}
              />

              <label className="form-label" htmlFor="form8Example5">
                Department and Institute
              </label>
            </div>
          </div>

          <div className="col">
            <select
              className="form-select"
              type="country"
              id="form8Example5"
              name="country"
              value={reviewer.country}
              onChange={handleInputChange}
            >
              <option value="Select">Select country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <Button
            className="btn btn-primary"
            onClick={handleAddReviewer}
            style={{ border: "1px solid #FFFFFF", borderRadius: "4px" }}
          >
            {editMode ? "Update Reviewer" : "Add Reviewer"}
          </Button>
        </div>
      </div>

      <br />
      <div className="card">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <strong>Title</strong>
            </div>
            <div className="col-sm">
              <strong>Firstname</strong>
            </div>
            <div className="col-sm">
              <strong>Middlename</strong>
            </div>
            <div className="col-sm">
              <strong>Lastname</strong>
            </div>
            <div className="col-sm">
              <strong>Email</strong>
            </div>
            <div className="col-sm">
              <strong>Designation</strong>
            </div>
            <div className="col-sm">
              <strong>Dept/Institute</strong>
            </div>
            <div className="col-sm">
              <strong>Actions</strong>
            </div>
          </div>
          <hr />
          {reviewersList.map((reviewer, index) => (
            <div className="container">
              <div className="row" key={index}>
                <div className="col-sm">{reviewer.title}</div>
                <div className="col-sm">{reviewer.firstname}</div>
                <div className="col-sm">{reviewer.middlename}</div>
                <div className="col-sm">{reviewer.lastname}</div>
                <div className="col-sm">{reviewer.email}</div>
                <div className="col-sm">{reviewer.designation}</div>
                <div className="col-sm">{reviewer.deptinstitute}</div>
                <div className="col-sm">
                  <span
                    className="pi pi-file-edit"
                    onClick={() => handleEditReviewer(index)}
                  >
                    {" "}
                  </span>

                  {"  "}

                  <span
                    className="pi pi-trash"
                    style={{ color: "#F74B02" }}
                    onClick={() => deleteReviewer(index)}
                  >
                    {" "}
                  </span>
                </div>
              </div>

              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddReviewersForm;
