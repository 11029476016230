import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileBase from "react-file-base64";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { updateUser } from "../redux/features/authSlice";
import { showUser } from "../redux/features/userSlice";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";
import { fetchCountriesAsync } from "../redux/features/countrySlice";
import "../EditUser.css";

const EditUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateData, setUpdateData] = useState({ category: [] });
  const { user } = useSelector((state) => state.auth);
  const categories = useSelector((state) => state.category);
  const countries = useSelector((state) => state.country);

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCheckboxChange = (event, categoryId) => {
    const isChecked = event.target.checked;
    setCheckedItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === categoryId ? { ...item, isChecked } : item
      );
      return updatedItems;
    });

    // Update the category array in updateData
    setUpdateData((prevData) => {
      const updatedCategories = isChecked
        ? [...prevData.category, categoryId]
        : prevData.category.filter((catId) => catId !== categoryId);

      return { ...prevData, category: updatedCategories };
    });
  };

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
    dispatch(fetchCountriesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (id && user && user.result) {
      setUpdateData(user.result);

      // Set the selected image for the preview
      if (user.result.profileImage) {
        setSelectedImage(user.result.profileImage);
      }

      // Initialize checked categories
      const selectedCategories = categories.map((category) => {
        const userCategory = user.result.category.find(
          (userCat) => userCat.id === category._id
        );
        return {
          id: category._id,
          name: category.name,
          isChecked: userCategory ? userCategory.isChecked : false,
        };
      });
      setCheckedItems(selectedCategories);
    }
  }, [id, user, categories]);

  // Max length for textarea fields
  const maxLength = 300;

  // Remaining character count state for textarea fields
  const [remainingCharsBiography, setRemainingCharsBiography] =
    useState(maxLength);
  const [remainingCharsKeywords, setRemainingCharsKeywords] =
    useState(maxLength);
  const [remainingCharsPositions, setRemainingCharsPositions] =
    useState(maxLength);
  const [remainingCharsInterests, setRemainingCharsInterests] =
    useState(maxLength);
  const [remainingCharsOther, setRemainingCharsOther] = useState(maxLength);
  const [remainingCharsIdea, setRemainingCharsIdea] = useState(maxLength);
  const [remainingCharsInformation, setRemainingCharsInformation] =
    useState(maxLength);
  const [remainingCharsPlace, setRemainingCharsPlace] = useState(maxLength);
  const [remainingCharsPublications, setRemainingCharsPublications] =
    useState(maxLength);
  const [remainingCharsReview, setRemainingCharsReview] = useState(maxLength);

  const newData = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "category") {
      // Handle category checkbox change
      const updatedCategories = checkedItems
        .map((item) => (item.isChecked ? item.name : null))
        .filter(Boolean);
      setUpdateData({ ...updateData, category: updatedCategories });
    } else if (name === "profileImage") {
      // Handle profile image change (this will be handled by FileBase)
      setSelectedImage(e.target.files[0]);
      setUpdateData({ ...updateData, profileImage: null });
    }

    // else if (type === "textarea" || "text") {
    //   setUpdateData((prevData) => ({
    //     ...prevData,
    //     [name]: value.charAt(0).toUpperCase() + value.slice(1), // Corrected line
    //   }));
    // }
    else if (name === "biography") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsBiography(maxLength - value.length);
    } else if (name === "keywords") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsKeywords(maxLength - value.length);
    } else if (name === "positions") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsPositions(maxLength - value.length);
    } else if (name === "interests") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsInterests(maxLength - value.length);
    } else if (name === "other") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsOther(maxLength - value.length);
    } else if (name === "idea") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsIdea(maxLength - value.length);
    } else if (name === "information") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsInformation(maxLength - value.length);
    } else if (name === "place") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsPlace(maxLength - value.length);
    } else if (name === "publications") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsPublications(maxLength - value.length);
    } else if (name === "review") {
      // Check if the field is "Keywords for Areas of Specialisation"
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
      // Update remaining characters count
      setRemainingCharsReview(maxLength - value.length);
    } else {
      // Handle other input fields
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    // Update remaining characters count
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const updatedData = { ...updateData, category: checkedItems };
    console.log("%%%", updateData);

    if (selectedImage instanceof File) {
      // ... (your existing code)
    } else {
      // If selectedImage is null or not a File object, directly proceed with the form submission
      dispatch(updateUser({ id: updateData._id, data: updatedData })).then(
        (response) => {
          if (response.payload) {
            dispatch(showUser({ id: updateData._id }));
            // Redirect to the dashboard after successful update
            navigate("/");
          }
        }
      );
    }
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <section style={{ backgroundColor: "#eee" }}>

          <nav>
            <div
              className="nav nav-tabs w-50 mx-auto my-20"
              id="nav-tab"
              role="tablist"
            >
              <button
                className="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                <div style={{ color: "#274052" }}>Profile settings</div>
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                <div style={{ color: "#274052" }}>Advanced settings</div>
              </button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="card tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <form
                className="container w-50 mx-auto my-5"
                onSubmit={handleUpdate}
              >
                <div className="row">
                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="firstName"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Title:
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          type="text"
                          id="title"
                          name="title"
                          value={updateData.title || ""}
                          onChange={newData}
                          disabled
                        >
                          <option value="Select">Select title</option>
                          <option value="Dr">Dr</option>
                          <option value="Prof">Prof</option>
                          <option value="Mr">Mr</option>
                          <option value="Miss">Miss</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {/* <hr className="col-sm-9" /> */}
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="firstName"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        First Name:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          value={updateData.firstName || ""}
                          onChange={newData}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="middleName"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Middle Name:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="middleName"
                          name="middleName"
                          className="form-control"
                          value={updateData.middleName || ""}
                          onChange={newData}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="lastName"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Last Name:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          className="form-control"
                          value={updateData.lastName || ""}
                          onChange={newData}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="email"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Email:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          value={updateData.email || ""}
                          onChange={newData}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="institute"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Institute:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="institute"
                          name="institute"
                          className="form-control"
                          value={updateData.institute || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="designation"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Designation:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="designation"
                          name="designation"
                          className="form-control"
                          value={updateData.designation || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="phone"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Phone:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="form-control"
                          value={updateData.phone || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="address"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Address:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="form-control"
                          value={updateData.address || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="city"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        City:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          className="form-control"
                          value={updateData.city || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="country"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Country:
                      </label>

                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          type="text"
                          id="country"
                          name="country"
                          value={updateData.country || ""}
                          onChange={newData}
                        >
                          <option value="Select">Select country</option>
                          {countries.map((country) => (
                            <option key={country.id} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="zipcode"
                        className="col-sm-3 col-form-label text-end"
                        style={{ color: "#067ADC" }}
                      >
                        Post/Zip Code:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="zipcode"
                          name="zipcode"
                          className="form-control"
                          value={updateData.zipcode || ""}
                          onChange={newData}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <label
                    className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                    htmlFor="exampleCheck1"
                    style={{ color: "#067ADC" }}
                  >
                    Please choose your speciality of interest:
                  </label>
                  {/* {categories.map((topping, index) => (
        <div className="topping" key={topping.id}>
          <input
            type="checkbox"
            id={`topping-${topping.id}`}
            name={`topping-${topping.id}`}
            value={topping.name}
            checked={checkedItems[index] || false}
            onChange={(event) => handleCheckboxChange(event, index)}
          />
          {topping.name}
        </div>
      ))} */}
                  {/* {categories.map((category) => (
  <div className="topping" key={category._id}>
    <input
      className="form-check-input"
      type="checkbox"
      id={`topping-${category._id}`}
      name={`topping-${category._id}`}
      value={category.name}
      checked={checkedItems.find((item) => item.id === category._id)?.isChecked || true}
      onChange={(event) => handleCheckboxChange(event, category._id)}
    />
    {category.name}
  </div>
))} */}

                  <div className="topping-container">
                    {categories.map((category) => (
                      <div className="topping" key={category.id}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`category-${category.id}`}
                          name="category"
                          value={category.name}
                          checked={
                            checkedItems.find(
                              (item) => item.id === category._id
                            )?.isChecked || false
                          }
                          onChange={(event) =>
                            handleCheckboxChange(event, category._id)
                          }
                        />
                        <label htmlFor={`category-${category.id}`}>
                          {"  "}
                          {category.name}
                        </label>
                      </div>
                    ))}
                  </div>

                  <br />
                  <br />

                  <hr />

                  <label
                    className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                    htmlFor="exampleCheck1"
                    style={{ color: "#067ADC" }}
                  >
                    Preferences:
                  </label>

                  <div className="topping">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="work"
                      name="work"
                      checked={updateData.work}
                      onChange={newData}
                    />

                    <label className="form-check-label" htmlFor="work">
                      Please check this box if you are a consultant in your
                      field of work?
                    </label>
                  </div>
                  <br />

                  <div className="topping">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="newsletter"
                      name="newsletter"
                      checked={updateData.newsletter}
                      onChange={newData}
                    />

                    <label className="form-check-label" htmlFor="newsletter">
                      Please check this box if you would like to opt for our
                      newsletter?
                    </label>
                  </div>
                  <br />
                  <br />
                  <hr />

                  <label
                    className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                    htmlFor="exampleCheck1"
                  >
                    Profile Image:
                  </label>
                  <br />

                  <div className="col-sm-9">
                    <div className="form-group row">
                      <label
                        htmlFor="profileImage"
                        className="col-sm-3 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        Profile Image:
                      </label>
                      <div className="col-sm-9">
                        <FileBase
                          type="file"
                          multiple={false}
                          onDone={({ base64, file }) => {
                            setSelectedImage(base64);
                            setUpdateData((prevData) => ({
                              ...prevData,
                              profileImage: base64,
                            }));
                          }}
                        />

                        {selectedImage && (
                          <>
                            <img
                              src={selectedImage}
                              alt="Profile Preview"
                              style={{ marginTop: "10px", maxWidth: "200px" }}
                            />
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                setSelectedImage(null);
                                setUpdateData({
                                  ...updateData,
                                  profileImage: null,
                                });
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <br />
                  </div>

                  <br />
                  <br />
                  <br />

                  <hr />

                  <div>
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="card tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <form
                className="container w-55 mx-auto my-5"
                onSubmit={handleUpdate}
              >
                <div className="row">
                  <div className="col-sm-9 offset-sm-3">
                    <div className="topping">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="editor"
                        name="editor"
                        checked={updateData.editor}
                        onChange={newData}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="editor"
                        style={{ color: "#067ADC" }}
                      >
                        {"    "} Editor {"  "}
                        <label
                          className="form-check-label"
                          htmlFor="editor"
                          style={{ color: "#F74B02" }}
                        >
                          (Chooose if you want to apply for the WebmedCentral
                          Editor position)
                        </label>
                      </label>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="biography"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Biography:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="biography"
                        name="biography"
                        className="form-control h-100"
                        value={updateData.biography || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsBiography} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="keywords"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Keywords for Areas of Specialisation:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="keywords"
                        name="keywords"
                        className="form-control h-100"
                        value={updateData.keywords || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsKeywords} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="positions"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Academic positions:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="positions"
                        name="positions"
                        className="form-control h-100"
                        value={updateData.positions || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsPositions} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="interests"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Research interests:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="interests"
                        name="interests"
                        className="form-control h-100"
                        value={updateData.interests || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsInterests} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="other"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Any other information:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="other"
                        name="other"
                        className="form-control h-100"
                        value={updateData.other || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsOther} characters remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="idea"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      What I think of the idea behind WebmedCentral: (Only for
                      scientists applying for WebmedCentral):
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="idea"
                        name="idea"
                        className="form-control h-100"
                        value={updateData.idea || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsIdea} characters remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="information"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Supplementary Information: Please detail information here
                      which will help us conclude your appointment to the
                      position you want. Contents in this box will only be seen
                      by our admin staff):
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="information"
                        name="information"
                        className="form-control h-100"
                        value={updateData.information || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsInformation} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="place"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Place of work:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="place"
                        name="place"
                        className="form-control h-100"
                        value={updateData.place || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsPlace} characters remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="publications"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Most recent publications:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="publications"
                        name="publications"
                        className="form-control h-100"
                        value={updateData.publications || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsPublications} characters
                        remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="review"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Previous experience of Peer review:
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        id="review"
                        name="review"
                        className="form-control h-100"
                        value={updateData.review || ""}
                        onChange={newData}
                        maxLength={maxLength}
                      />
                      <span style={{ color: "#F74B02" }}>
                        {maxLength - remainingCharsReview} characters remaining
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className="col-sm-18">
                  <div className="form-group row">
                    <label
                      htmlFor="homepage"
                      className="col-sm-3 col-form-label text-end"
                      style={{ color: "#067ADC" }}
                    >
                      Home Page (site URL):
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        id="homepage"
                        name="homepage"
                        className="form-control"
                        value={updateData.homepage || ""}
                        onChange={newData}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="col-sm-9 offset-sm-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

      </section>

      <Footer />
    </>
  );
};

export default EditUser;
