import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getTour } from "../redux/features/tourSlice";
import { getTours } from "../redux/features/tourSlice";
import { fetchAllUsers } from "../redux/features/userSlice";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const ArticlesByType = () => {
  const { articletype } = useParams();
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.tour.allTours);
  const users = useSelector((state) => state.user.users);

  const articlesPerPage = 10; // Set the number of articles per page
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTours());
  }, [dispatch]);

  useEffect(() => {
    if (articletype) {
      dispatch(getTour(articletype));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articletype]);

  // Ensure rendering with existing data from Redux store if available
  const articlesInType = articles.filter(
    (article) =>
      article.articletype === articletype && article.approved === true
  );

  const stripTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  // Pagination
  const totalPages = Math.ceil(articlesInType.length / articlesPerPage);
  const maxPagesToShow = 5; // You can adjust this value according to your preference

  let startPage, endPage;
  if (totalPages <= maxPagesToShow) {
    // Less than or equal to the maximum number of pages to show, so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // More pages than maximum pages to show, so calculate start and end pages with ellipsis
    const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  const pageNumbers = [...Array(endPage - startPage + 1).keys()].map(
    (i) => startPage + i
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articlesInType.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  return (
    <>
      <Navbar />
      <br /> <br /> <br /> <br /> <br />
      <div className="card">
        <br />
        <p style={{ fontWeight: "bold" }}>
          <div className="card-header">
            {" "}
            Articles with Article type:{" "}
            <span style={{ color: "#067ADC" }}>{articletype}</span>
          </div>
        </p>
        {currentArticles.length === 0 ? (
          <p>No articles found for this Article type.</p>
        ) : (
          <div>
            {currentArticles.map((article) => (
              <div key={article._id}>
                <h4 className="mb-1">
                  <Link to={`/article/${article.articleId}`} target="_blank">
                    {article.title.slice(0, 70)}
                    {"....."}
                  </Link>
                </h4>
                <p>
                  Tags: {article.tags.join(", ").slice(0, 10)}
                  {"....."}
                </p>
                <span style={{ color: "#067ADC" }}>
                  <span className="pi pi-user" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {"    "}
                  Submitted by : {"   "}
                </span>{" "}
                {"  "}
                <span style={{ color: "#000000" }}>
                  {users.map((user) => {
                    if (user._id === article.creator) {
                      return `${user.title}. ${user.firstName} ${user.middleName}  ${user.lastName}`;
                    }
                    return null;
                  })}

                  {/* {article.usertitle}
                  {".  "} {article.userfirstname} {"  "}
                  {article.usermiddlename} {article.userlastname} */}
                </span>
                <Row>
                  <Col md={5}>
                    <label
                      htmlFor="title"
                      className="col-sm-3.5 col-form-label"
                      style={{ color: "#067ADC" }}
                    >
                      <span
                        className="pi pi-calendar"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {"  "}
                      Submitted on:{" "}
                    </label>

                    <span style={{ color: "#000000", marginLeft: "0px" }}>
                      {` ${new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(article.createdAt))}`}
                    </span>
                  </Col>
                  <Col md={5}>
                    <label
                      htmlFor="title"
                      className="col-sm-3.5 col-form-label"
                      style={{ color: "#067ADC" }}
                    >
                      <span
                        className="pi pi-calendar"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {"  "}
                      Approved on:
                    </label>

                    <span style={{ color: "#000000", marginRight: "0px" }}>
                      {` ${new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(article.publishedAt))}`}
                    </span>
                  </Col>
                </Row>
                <div style={{ color: "#000000" }}>
                  {article.articleinfo[0].content &&
                  typeof article.articleinfo[0].content === "string" ? (
                    <p className="fst-normal" style={{ color: "#000000" }}>
                      {stripTags(article.articleinfo[0].content.slice(0, 150))}
                    </p>
                  ) : (
                    <p className="fst-normal">Content not available</p>
                  )}
                </div>
                <hr />
              </div>
            ))}
          </div>
        )}
        {/* Pagination */}
        <ul className="pagination">
          {startPage !== 1 && (
            <li className="page-item">
              <button className="page-link" onClick={() => paginate(1)}>
                1
              </button>
            </li>
          )}
          {startPage > 2 && (
            <li className="page-item disabled">
              <button className="page-link">...</button>
            </li>
          )}
          {pageNumbers.map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => paginate(page)}>
                {page}
              </button>
            </li>
          ))}
          {endPage < totalPages - 1 && (
            <li className="page-item disabled">
              <button className="page-link">...</button>
            </li>
          )}
          {endPage !== totalPages && (
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => paginate(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          )}
        </ul>
        <br /> <br /> <br /> <br /> <br />
        <br /> <br /> <br />
      </div>
      <Footer />
    </>
  );
};

export default ArticlesByType;
