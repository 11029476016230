import React, { useEffect, useState } from "react";
import "../illustrations.css";
import profilepic from "../components/img/samplepic.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { getTour } from "../redux/features/tourSlice";
import { postComment } from "../redux/features/tourSlice";
import { fetchReviews } from "../redux/features/reviewSlice";
import { fetchAllUsers } from "../redux/features/userSlice";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Container, Row, Col } from "react-bootstrap";
import "primeicons/primeicons.css";

const ArticleView = () => {
  // ... (rest of your component code)
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState({ src: "", title: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { tour } = useSelector((state) => ({ ...state.tour }));
  const { tour } = useSelector((state) => state.tour);
  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.user.users);
  const review = useSelector((state) => state.review);
  // console.log("jijij", users);
  const correspondingauthor = tour?.authors;
  console.log("correspondingauthor", correspondingauthor);

  const { id } = useParams();

  // const {user} = useSelector((state) => state.auth);
  // console.log("KKK", user.result.title)
  // console.log("KKMK", user?.result?.title)

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getTour(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(fetchReviews());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tour?.title) {
      document.title = tour.title;
    }
  }, [tour?.title]);

  const reviewsForArticle = review.reviews.filter(
    (review) => review.artid === tour.articleId
  );

  const totalReviews = reviewsForArticle.length;

  const [comment, setComment] = useState("");
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const isAuthenticated = user && user.result;

  const handleSubmitComment = (event) => {
    event.preventDefault();

    if (comment.length > 200) {
      // You can display an error message or handle it in your preferred way
      alert("Comment should not exceed 200 characters.");
      return;
    }

    if (isAuthenticated) {
      dispatch(
        postComment({
          id: tour._id,
          comment: comment,
          userid: user.result._id,
          toast,
        })
      );
      // toast.success("Comment Posted Successfully");
      setComment("");
    } else {
      // Handle the case where the user is not logged in
      // You can show a message or trigger a login modal, etc.
      console.log("Please login to comment");
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (tour?._id) {
      setIsLoading(false);
    }
  }, [tour]);

  // Render the loading message while data is being fetched
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // //This is the function to remove HTML tags saved in DB
  // const stripTags = (html) => {
  //   const tmp = document.createElement("DIV");
  //   tmp.innerHTML = html;
  //   return tmp.textContent || tmp.innerText || "";
  // };

  //   // This function removes HTML tags while preserving text content and formatting
  // const stripTags = (html) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };

  //   // This function removes HTML tags
  // const stripTags = (html) => {
  //   return html.replace(/<[^>]*>?/gm, '');
  // };

  // const stripTags = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;
  //   const textContent = div.textContent || div.innerText || "";
  //   return textContent.trim(); // Trim to remove leading/trailing white spaces
  // };

  const images = tour.illustrations.map((data) => ({
    original: data.image,
    thumbnail: data.image,
    description: data.title,
  }));

  // const latestComments = tour.comments.slice(0, 5);
  // const latestComments = tour.comments.reverse().slice(0, 5);
  const latestComments = [...tour.comments].reverse().slice(0, 5);

  const commentsTotalcount = tour.comments.length;

  const handlePostReviewClick = () => {
    if (isAuthenticated) {
      // Check if the current user is the author of the article
      if (user.result._id === tour.creator) {
        // If the user is the author, you can display a message or prevent the action
        console.log("Authors cannot post reviews on their own articles.");
      } else {
        // If the user is not the author, allow them to post a review
        navigate(`/postreview/${id}`);
      }
    } else {
      // Handle the case where the user is not logged in
      console.log("Please login to post a review.");
    }
  };

  const handleImageClick = (src, title) => {
    console.log("Clicked Image:", src, title);
    setClickedImage({ src, title });
    setShowModal(true);
  };

  const createdAtDate = new Date(tour.createdAt);
  // Extract the year from the Date object
  const createdYear = createdAtDate.getFullYear();

  return (
    <>
      <Navbar />

      <br />
      <br />
      <br />

      <section style={{ backgroundColor: "#eee" }}>
        <div className="card mt-5">
          <div className="row">
            <div className="col-lg-8">
              {/* Post content*/}
              <article>
                {/* Post header*/}
                <header className="mb-4">
                  {/* Post title*/}
                  <h3 className="fw-lite mb-1" style={{ color: "#048DD2" }}>
                    {tour.title.charAt(0).toUpperCase() + tour.title.slice(1)}{" "}
                  </h3>
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <h4>
                    <span class="badge bg-secondary">
                      {tour?.result?.articletype}
                    </span>
                  </h4>
                  {/* Post meta content*/}
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-user" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    Submitting Author:
                  </label>
                  {users.map((userData) => {
                    if (userData._id === tour.creator) {
                      return (
                        <React.Fragment key={userData._id}>
                          {userData.title}
                          {". "}
                          {userData.firstName} {userData.middleName}{" "}
                          {userData.lastName}
                        </React.Fragment>
                      );
                    }
                    return null; // If no matching user is found, return null
                  })}
                  {/* {tour.usertitle}
                  {". "} {tour.userfirstname} {tour.usermiddlename}{" "}
                  {tour.userlastname} */}
                  <br />
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-user" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    Corresponding Author:
                  </label>
                  {users.map((userData) => {
                    if (userData._id === tour.creator) {
                      return (
                        <React.Fragment key={userData._id}>
                          {userData.title}
                          {". "}
                          {userData.firstName} {userData.middleName}{" "}
                          {userData.lastName}
                        </React.Fragment>
                      );
                    }
                    return null; // If no matching user is found, return null
                  })}
                  {", "}
                  {/* {tour.usertitle}
                  {". "}
                  {tour.userfirstname} {tour.usermiddlename} {tour.userlastname}
                  {", "} */}
                  {tour &&
                    tour.authors &&
                    tour.authors.map((data, index) => {
                      if (data.correspondingAuthor === true) {
                        return (
                          <>
                            {data.title}
                            {". "}
                            {data.firstname} {data.middlename} {data.lastname}
                            {index !== tour.authors.length - 1 ? ", " : ""}
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                  <br />
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-user" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    Additional Authors:
                  </label>
                  {tour &&
                    tour.authors &&
                    tour.authors.map((data, index) => {
                      return (
                        <>
                          {data.title}
                          {"."} {data.firstname} {data.middlename}{" "}
                          {data.lastname}
                          {index !== tour.authors.length - 1 ? ", " : ""}
                        </>
                      );
                    })}
                  <br />
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-info" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    Article Id:
                  </label>
                  <span style={{ color: "#067ADC" }}></span>{" "}
                  <span style={{ color: "#000000" }}> {tour.articleId}</span>
                  <br />
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-tags" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    Keywords:
                  </label>
                  {tour &&
                    tour.tags &&
                    tour.tags.map((data, index) => (
                      <React.Fragment key={index}>
                        {data}, {"       "}
                      </React.Fragment>
                    ))}
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <Row>
                    <Col md={6}>
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        <span
                          className="pi pi-th-large"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        {"  "}
                        Subject Category:
                      </label>{" "}
                      {tour.subjectcategory}
                      <hr
                        style={{ borderColor: "#F74B02", borderWidth: "1px" }}
                      />
                    </Col>
                    <Col md={6}>
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        <span
                          className="pi pi-book"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        {"  "}
                        Article type:
                      </label>
                      {tour.articletype}

                      <hr
                        style={{ borderColor: "#F74B02", borderWidth: "1px" }}
                      />
                    </Col>
                    <br />
                    <br />

                    <Col md={6}>
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        <span
                          className="pi pi-calendar"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        {"  "}
                        Submitted on:{" "}
                      </label>

                      {` ${new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(tour.createdAt))}`}
                      <hr
                        style={{ borderColor: "#F74B02", borderWidth: "1px" }}
                      />
                    </Col>
                    <Col md={6}>
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        <span
                          className="pi pi-calendar"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        {"  "}
                        Approved on:{" "}
                      </label>

                      {` ${new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }).format(new Date(tour.publishedAt))}`}
                      <hr
                        style={{ borderColor: "#F74B02", borderWidth: "1px" }}
                      />
                    </Col>
                  </Row>
                  <label
                    htmlFor="title"
                    className="col-sm-3 col-form-label"
                    style={{ color: "#067ADC" }}
                  >
                    <span className="pi pi-plus" style={{ color: "#F74B02" }}>
                      {" "}
                    </span>{" "}
                    {"  "}
                    How to cite the article:
                  </label>
                  <div className="card-body">
                    {tour.usertitle} {tour.userlastname}
                    {tour.authors &&
                      tour.authors.map((data, index) => (
                        <>
                          {index >= 0 ? ", " : "."} {data.title} {data.lastname}
                        </>
                      ))}
                    {tour.authors && tour.authors.length >= 0 ? "." : ""}{" "}
                    {tour.title} WebmedCentral. {tour.subjectcategory}{" "}
                    {/* {tour.currentYear};  */}
                    {/* {tour.currentYear - 2009} */}
                    {createdYear};{createdYear - 2009}(
                    {`${new Intl.DateTimeFormat("en-US", {
                      month: "numeric",
                    }).format(new Date(tour.publishedAt))}`}
                    ) : {tour.articleId}
                  </div>
                  <hr style={{ borderColor: "#F74B02", borderWidth: "1px" }} />
                  <div className="card-body" style={{ color: "#F74B02" }}>
                    <p style={{ color: "#F74B02" }}>
                      {" "}
                      &copy; Copyright: This is an open-access article
                      distributed under the terms of the{" "}
                      <NavLink
                        to={`http://creativecommons.org/licenses/by/3.0/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Creative Commons Attribution{" "}
                      </NavLink>
                      License(CC-BY), which permits unrestricted use,
                      distribution, and reproduction in any medium, provided the
                      original author and source are credited.
                    </p>
                  </div>
                  {images.thumbnail}
                </header>

                {/* Preview image figure*/}
                <figure className="mb-4">
                  {tour &&
                    tour.articleinfo &&
                    tour.illustrations.length > 0 && (
                      <div className="card mb-4">
                        <div className="card-header">
                          <h4>Illustrations</h4>
                        </div>
                        <div className="card-body">
                          <Container>
                            <Row>
                              {tour.illustrations.map((data, index) => (
                                <Col md={4} key={index} className="mb-4">
                                  <div
                                    className="gallery-item border rounded overflow-hidden"
                                    style={{ height: "190px" }}
                                    onClick={() =>
                                      handleImageClick(data.image, data.title)
                                    }
                                  >
                                    <img
                                      src={data.image}
                                      alt="illustrations"
                                      className="img-fluid"
                                      style={{
                                        height: "150%",
                                        width: "150%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                  <div className="gallery-item border rounded overflow-hidden">
                                    <h6 className="mt-2">{data.title}</h6>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Container>
                        </div>
                      </div>
                    )}
                </figure>

                {/* Post content*/}
              </article>

              {tour.articleinfo.map((data) =>
                // Check if content is not blank
                data.content ? (
                  <div className="card mb-4" key={data._id}>
                    {/* Render the title if content is not blank */}
                    <div className="card-header">
                      <h5 style={{ color: "#067ADC" }}>{data.title}</h5>
                    </div>
                    {/* Render the content */}
                    <div className="card-body">
                      <div className="input-group">
                        {/* Check if the content contains an image tag or a table tag */}
                        {data.content.includes("<img") ||
                        data.content.includes("<table") ? (
                          // If it contains an image or a table, render with appropriate styling
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.content
                                // Remove extra line breaks and spaces
                                .replace(/\n\s*\n/g, "\n")
                                .replace(/\n/g, "")
                                .replace(/\s\s+/g, " ")
                                // Apply styling for images and tables
                                .replace(
                                  /<img /g,
                                  '<br/><img style="max-width: 450px; max-height: 450px;" '
                                )
                                .replace(
                                  /<table/g,
                                  '<table style="border-collapse: collapse; border: 2px solid #ccc;" '
                                )
                                .replace(
                                  /<td/g,
                                  '<td style="border: 2px solid #ccc;" '
                                )
                                .replace(
                                  /<tr/g,
                                  '<tr style="border: 2px solid #ccc;" '
                                )
                                .replace(
                                  /<th/g,
                                  '<th style="border: 2px solid #ccc;" '
                                ),
                            }}
                          />
                        ) : (
                          // If it doesn't contain an image or a table, render the content as is
                          <div
                            dangerouslySetInnerHTML={{ __html: data.content }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : // If content is blank, do not render the title or the card wrapper
                null
              )}

              {/* Comments section*/}
              <section className="mb-5">
                <div className="card bg-light">
                  <div className="card-body">
                    {/* Comment form */}
                    <form className="mb-4" onSubmit={handleSubmitComment}>
                      <textarea
                        className="form-control h-100"
                        rows={3}
                        placeholder="Type ateast a word to enabled the 'Post Comment' button"
                        value={comment}
                        onChange={handleCommentChange}
                      />

                      <span style={{ color: "#F74B02" }}>
                        {200 - comment.length} characters remaining
                      </span>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={
                            !isAuthenticated ||
                            comment.trim().length === 0 ||
                            comment.length > 200
                          }
                        >
                          {isAuthenticated
                            ? "Post Comment"
                            : "Please login to comment"}
                        </button>
                      </div>

                      <div className="d-flex justify-content-end">
                        <NavLink to={`/comments/${id}`}>
                          View all comments ({commentsTotalcount})
                        </NavLink>
                      </div>
                    </form>

                    {tour &&
                      tour.comments &&
                      latestComments.map((comment) => {
                        // Find the user corresponding to the comment's userid
                        const matchingUser = users.find(
                          (user) => user._id === comment.userid
                        );

                        // Check if a matching user is found
                        if (matchingUser) {
                          return (
                            <React.Fragment key={comment._id}>
                              <div class="d-flex">
                                <div class="flex-shrink-0">
                                  <img
                                    class="rounded-circle"
                                    // src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                                    src={matchingUser.image || profilepic}
                                    alt="ProfileImage"
                                    style={{
                                      marginTop: "0px",
                                      maxWidth: "75px",
                                      maxHeight: "75px",
                                    }}
                                  />
                                </div>

                                <div class="ms-3">
                                  <div class="fw-lite">
                                    <label
                                      htmlFor="title"
                                      className="col-sm-0 col-form-label"
                                      style={{ color: "#067ADC" }}
                                    >
                                      <span
                                        className="pi pi-user"
                                        style={{ color: "#F74B02" }}
                                      >
                                        {" "}
                                      </span>{" "}
                                      {"  "}
                                    </label>{" "}
                                    {matchingUser.title}
                                    {".  "}
                                    {matchingUser.firstName}
                                    {"  "}
                                    {matchingUser.middleName}
                                    {"  "}
                                    {matchingUser.lastName}
                                  </div>
                                  <span style={{ color: "#067ADC" }}>
                                    " {comment.comment} "
                                  </span>

                                  <br />

                                  <label
                                    htmlFor="title"
                                    className="col-sm-12 col-form-label"
                                    style={{ color: "#067ADC" }}
                                  >
                                    <span
                                      className="pi pi-calendar"
                                      style={{ color: "#F74B02" }}
                                    >
                                      {" "}
                                    </span>{" "}
                                    {` ${new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }).format(new Date(comment.postedDate))}`}
                                  </label>
                                  <hr />
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={comment._id}>
                              {/* Render comment with placeholders for user info */}
                              Unknown User
                              <span style={{ color: "#067ADC" }}>
                                {comment.comment}
                              </span>
                            </React.Fragment>
                          );
                        }
                      })}

                    {/* {tour &&
                      tour.comments &&
                      latestComments.map((data) => (
                        <>
                          <div class="d-flex">
                            <div class="flex-shrink-0">
                              <img
                                class="rounded-circle"
                                // src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                                src={data.image || profilepic}
                                alt="ProfileImage"
                                style={{
                                  marginTop: "0px",
                                  maxWidth: "75px",
                                  maxHeight: "75px",
                                }}
                              />
                            </div>
                            <div class="ms-3">
                              <div class="fw-lite">
                                <label
                                  htmlFor="title"
                                  className="col-sm-0 col-form-label"
                                  style={{ color: "#067ADC" }}
                                >
                                  <span
                                    className="pi pi-user"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                </label>{" "}
                                {data.title}
                                {"  "}
                                {data.firstname}
                                {"  "}
                                {data.middlename}
                                {"  "}
                                {data.lastname}

                              </div>
                              <span style={{ color: "#067ADC" }}>
                                " {data.comment} "
                              </span>

                              <br />

                              <label
                                htmlFor="title"
                                className="col-sm-12 col-form-label"
                                style={{ color: "#067ADC" }}
                              >
                                <span
                                  className="pi pi-calendar"
                                  style={{ color: "#F74B02" }}
                                >
                                  {" "}
                                </span>{" "}
                                {` ${new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(data.postedDate))}`}
                              </label>
                              <hr />
                            </div>
                          </div>
                        </>
                      ))} */}
                  </div>
                </div>
              </section>
            </div>
            {/* Side widgets*/}
            <div className="col-lg-4">
              {/* Search widget*/}
              <div className="card mb-4">
                <div className="card-header" style={{ color: "#067ADC" }}>
                  <span className="pi pi-dollar" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {"  "}
                  Source(s) of Funding
                </div>
                <div className="card-body">
                  <div className="input-group">
                    {tour.fundingsource !== "" ? tour.fundingsource : "None"}

                    {/* <input
                    className="form-control"
                    type="text"
                    placeholder="Enter search term..."
                    aria-label="Enter search term..."
                    aria-describedby="button-search"
                  />
                  <button
                    className="btn btn-primary"
                    id="button-search"
                    type="button"
                  >
                    Go!
                  </button> */}
                  </div>
                </div>
              </div>
              {/* Categories widget*/}

              <div className="card mb-4">
                <div className="card-header" style={{ color: "#067ADC" }}>
                  <span
                    className="pi pi-angle-right"
                    style={{ color: "#F74B02" }}
                  >
                    {" "}
                  </span>
                  <span
                    className="pi pi-angle-left"
                    style={{ color: "#F74B02" }}
                  >
                    {" "}
                  </span>{" "}
                  {"  "}
                  Competing Interests
                </div>
                <div className="card-body">
                  {tour.competinginterests !== ""
                    ? tour.competinginterests
                    : "None"}
                  {/* <div className="row">
                  <div className="col-sm-6">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href="#!">Web Design</a>
                      </li>
                    
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href="#!">JavaScript</a>
                      </li>
                      <li>
                        <a href="#!">CSS</a>
                      </li>
                      <li>
                        <a href="#!">Tutorials</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                </div>
              </div>
              {/* Side widget*/}

              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlePostReviewClick}
                >
                  {isAuthenticated
                    ? user.result._id === tour.creator
                      ? "Authors cannot post reviews"
                      : "Post Review"
                    : "Please login to post a review"}
                </button>
              </div>

              <div className="d-flex justify-content-end">
                <NavLink to={`/ViewReviews/${id}`}>
                  View all reviews ({totalReviews})
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>

          <img
            src={clickedImage.src}
            alt={clickedImage.title}
            className="img-fluid"
          />
        </Modal.Body>
        <Modal.Header closeButton>
          <Modal.Title>{clickedImage.title}</Modal.Title>
        </Modal.Header>
      </Modal>

      <Footer />
    </>
  );
};

export default ArticleView;
