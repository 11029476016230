import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const Whypublishwithus = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <br />
      <br />
      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Why Publish With Us
          </div>
        </p>

        <p>
          WebmedCentral, with its unique approach of publication without peer
          review, offers following to scientists:
        </p>
        <p>1. Speed of Publication. Usually within 48 hours of submission</p>
        <p>
          2. Post Publication peer review by a highly acclaimed team of
          thousands of WebmedCentral Editors
        </p>
        <p>3. Improved visibility for their research through Open Access.</p>
        <p>4. Minimal costs of publication. GBP 50.00 per article</p>
        <p>
          5. Complete freedom of expression especially to express views
          different from main stream science
        </p>
        <p>
          6. Open and transparent reviews and communication amongst scientists.
        </p>
        <p>7. Permanent archiving in digital archives.</p>
        <p>8. Articles tracked by Google Scholar.</p>
        <p>
          9. Scientists retain copyright and can publish articles elsewhere if
          they like. Please see our {" "}
          <NavLink to="/copyrightpolicy" target="_blank">
            copyright policy
          </NavLink>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Whypublishwithus;
