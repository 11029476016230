import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../CategoriesList.css";

const CategoriesList = () => {
  const categories = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            List of Categories
          </div>
        </p>
        {categories.map((category) => (
          <div key={category._id} className="category-item">
            <span>{category.name}</span>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default CategoriesList;
