import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCountries } from "../api";

// Async action to fetch countries
export const fetchCountriesAsync = createAsyncThunk(
  "country/fetchCountries",
  async () => {
    const countries = await fetchCountries();
    return countries;
  }
);

// Country reducer
const countrySlice = createSlice({
  name: "country",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesAsync.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default countrySlice.reducer;
