import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchReviews } from "../redux/features/reviewSlice";
import { useSelector, useDispatch } from "react-redux";
import { getTour } from "../redux/features/tourSlice";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { fetchAllUsers } from "../redux/features/userSlice";
import "../viewReview.css";

const ViewReview = () => {
  const { reviewId } = useParams();
  const dispatch = useDispatch();
  // const reviews = useSelector((state) => state.review); //Can we defined like this also
  // const review = reviews.reviews.find((review) => review._id === reviewId); //Can we defined like this also

  const reviews = useSelector((state) => state.review.reviews);
  // Find the specific review based on reviewId
  const review = reviews.find((review) => review._id === reviewId);

  const users = useSelector((state) => state.user.users);

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReviews());
    dispatch(getTour());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <p className="card-title text-center">
        <div className="card ">
          <div className="card-body justify-content-center">
            <br />

            {/* <Link to={`/article/${review.artid}`}>{review.articletitle}</Link> */}

            <div className="row">
              {review ? (
                // Render review content here
                <div key={review._id} className="card">
                  {/* Review content */}
                  <Link
                    to={`/article/${review.artid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <h2 style={{ color: "#067ADC" }}>{review.articletitle}</h2>
                  </Link>
                  {/* Other review properties */}
                </div>
              ) : (
                <p>Loading review...</p> // You can display a loading message
              )}
            </div>

            <div className="row" style={{ textAlign: "left" }}>
              {review ? (
                <div key={review._id} className="col-sm-8">
                  <div className="card">
                    <div className="card-header">
                      <h2>{review.title} </h2>

                      {users.map((user) => {
                        if (user._id === review.userId) {
                          return (
                            <span key={user._id} style={{ color: "#067ADC" }}>
                              <span
                                className="pi pi-user"
                                style={{ color: "#F74B02" }}
                              ></span>{" "}
                              Submitted by : {"     "}
                              <span style={{ color: "#000000" }}>
                                {review.anonymously === "yes"
                                  ? "Anonymous user"
                                  : `${user.title}. ${user.firstName} ${
                                      user.middleName
                                        ? user.middleName + " "
                                        : ""
                                    }${user.lastName}`}
                              </span>
                              <br />
                              {"     "}
                              <span
                                className="pi pi-calendar"
                                style={{ color: "#F74B02" }}
                              >
                                {" "}
                              </span>
                              {"     "}
                              <label
                                htmlFor="title"
                                style={{ color: "#067ADC" }}
                              >
                                Posted on{"           "} :{"     "}{" "}
                              </label>
                              <span style={{ color: "#000000" }}>
                                {" "}
                                {"     "}
                                {`${new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(review.createdAt))}`}
                              </span>
                            </span>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        What are the main claims of the paper and how important
                        are they?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.claims}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Are these claims novel? If not, please specify papers
                        that weaken the claims to the originality of this one.
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.novel}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Are the claims properly placed in the context of the
                        previous literature?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.properly}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Do the results support the claims? If not, what other
                        evidence is required?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.support}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        If a protocol is provided, for example for a randomized
                        controlled trial, are there any important deviations
                        from it? If so, have the authors explained adequately
                        why the deviations occurred?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.protocol}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Is the methodology valid? Does the paper offer enough
                        details of its methodology that its experiments or its
                        analyses could be reproduced?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.methodology}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Would any other experiments or additional information
                        improve the paper? How much better would the paper be if
                        this extra work was done, and how difficult would such
                        work be to do, or to provide?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.experiments}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Is this paper outstanding in its discipline? (For
                        example, would you like to see this work presented in a
                        seminar at your hospital or university? Do you feel
                        these results need to be incorporated in your next
                        general lecture on the subject?) If yes, what makes it
                        outstanding? If not, why not?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.outstanding}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Any other comments?:
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.comments}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Were you invited by the author for your reviews?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.invited}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Have you previously published on this or a similar
                        topic?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.previously}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Please confirm that you have suitable experience and
                        knowledge to review this article
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.suitable}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Do you have any competing interest(s) in posting this
                        review?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.interest}
                        </label>
                      </p>
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        <span
                          className="pi pi-question-circle"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        Do you wish to submit this review anonymously?
                        <br />
                        <label htmlFor="title" style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-comment"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {review.anonymously}
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Review not found.</p>
              )}
            </div>
          </div>
        </div>
      </p>

      <Footer />
    </>
  );
};

export default ViewReview;
