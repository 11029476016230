import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const Authors = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <br />
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Authors
          </div>
        </p>
        <p>
          Please read our{" "}
          <NavLink to="/instructionsforauthors" target="_blank">
            Instructions for Authors
          </NavLink>{" "}
          before submitting any manuscript on WebmedCentral. Please make
          yourself familiar with{" "}
          <NavLink to="/philosophy" target="_blank">
            "Our philosophy"
          </NavLink>
          ,{" "}
          <NavLink to="/peerreviewpolicy" target="_blank">
            "Our Peer Review System"
          </NavLink>
          ,{" "}
          <NavLink to="/publicationpolicy" target="_blank">
            "Our Publication Policy"
          </NavLink>
          , and{" "}
          <NavLink to="/copyrightpolicy" target="_blank">
            "Our Copyright Policy"
          </NavLink>
          . You will require to{" "}
          <NavLink to="/register" target="_blank">
            "register"
          </NavLink>{" "}
          with us before submitting an article on the website. Please ensure
          that you have all the information in our{" "}
          <NavLink to="/checklistforarticlesubmission" target="_blank">
            "checklist"
          </NavLink>{" "}
          before starting article submission. It would make the submission
          process easy and hassle-free.
        </p>
        <br /> <br /> <br /> <br /> <br /> <br />
      </div>

      <Footer />
    </>
  );
};

export default Authors;
