import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../components/Config";

export const fetchComments = createAsyncThunk(
  "coments/fetchComments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/comments`);
      return response.data; // response.data contains the reviews array
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    error: "",
    loading: false,
    comments: [],
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: {
    [fetchComments.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchComments.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.comments = action.payload; // Use "comments" instead of "reviews"
    },

    [fetchComments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setCurrentPage } = commentsSlice.actions;

export default commentsSlice.reducer;
