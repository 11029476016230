import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Disclaimers = () => {
  return (
    <>
    <Navbar />
    <br />
    <br />
    <br />
    <br />
    <br />
   
      <div className="card">
   

        <p style={{ fontWeight: "bold" }}>
          <div className="card-header" style={{ color: '#067ADC', fontSize: '16px' }}>Disclaimers</div>
      
        </p>

        <p>
          The domains www.webmedcentral.com, www.webmedcentral.co.uk and
          www.webmedcentralplus.com are owned by Webmed Limited registered at 35
          Rosemount, Durham, DH1 5GA United Kingdom. The pronouns "you" and
          "your" are used to refer to the author, reviewer and the end user,
          while ''WebmedCentral' "we," "us", "our" “company”, and
          www.webmedcentral.com, www.webmedcentral.co.uk, and
          www.webmedcentralplus.com (also knows as plus)are used to refer to
          Webmed Limited.
        </p>
        <p>
          Contents on our websites are purely for biomedical researchers and
          scientists. They are not meant to cater to the needs of individual
          patients. The websites or any content(s) therein is neither designed
          to support, nor replace, the relationship that exists between a
          patient/site visitor and his/her treating doctor. Your use of the
          WebmedCentral sites and their contents is entirely at your own risk.
          We do not take any responsibility for any harm that you may suffer or
          inflict on a third person by following the contents of our websites.
        </p>
        <p>
          Contents on our sites are not intended to be of any direct benefit to
          members of general public. They are intended to facilitate scientific
          communications amongst biomedical researchers and should not be used
          to form basis for any medical treatment. Hence any information on our
          websites must be used purely for the purpose of scientific discussion
          and must not be used to guide or alter patient treatment.
        </p>
        <p>
          Medical practitioners and scientists must be aware that our sites
          www.webmedcentral.com and www.webmedcentral.co.uk do not use any
          pre-publication checks.. Contents on WebmedCentralplus.com do undergo
          editorial and peer checks but that does not in any way guarantee the
          truth and accuracy of the information.
        </p>
        <p>
          WebmedCentral does not claim that any information contained herein is
          in any respect accurate or complete. We take no responsibility for any
          errors, omissions, or harm incurred as a result of use of such
          material. Users are strongly encouraged to confirm the information
          contained herein with other sources. Patients and members of general
          public, if they happen to visit our websites, should review any
          information on them carefully with their professional healthcare
          provider. We make no representations or warranties with respect to any
          treatment, action, or application of medication or preparation by any
          person following the information offered or provided within or through
          our websites and we will not be liable for any direct, indirect,
          consequential, special, exemplary, or other damages arising there
          from.
        </p>
        <p>
          Our users are made aware that in connection with the WebmedCentral,
          information will be transmitted over local exchange, servers and
          through internet backbone carrier lines and through routers, switches
          and other devices owned, maintained and serviced by third party local
          exchange and long distance carriers, utilities, internet service
          providers and others, all of which are beyond the control and
          jurisdiction of WebmedCentral and its suppliers. Accordingly
          WebmedCentral, its officers, directors, employees, agents, licensors
          and suppliers assumes no liability for or relating to the delay,
          failure, interruption or corruption of any data or other information
          transmitted in connection with use of the WebmedCentral portal.
        </p>
        <p>
          The service, including all information, products, and other content
          (including third party information) is provided on an “as is” and “as
          available” basis without warranties of any kind either expressed or
          implied. To the fullest extent permissible, pursuant to applicable
          law, company disclaims all warranties expressed or implied. The
          Company does not warrant any portion of the sites or the service will
          be uninterrupted or error free, that defects will be corrected or that
          the sites or the service that makes it available are free of viruses
          or other harmful code. Company does not warrant or make any
          representations regarding the use or the results of the use of the
          materials in this site in terms of correctness, accuracy, reliability
          or otherwise. Any material downloaded or otherwise obtained thought
          the use of the service is done at your own discretion and risk.
        </p>
        <p>
          In no event shall WebmedCentral, its officers, directors, employees,
          agents, licensors and suppliers be liable for any damages whatsoever
          including direct, indirect, special, punitive, incidental and
          consequential damages, lost profits, or damages resulting from data or
          business interruption resulting from the use of or inability to use
          the WebmedCentral websites or the content whether based on warranty,
          contract, tort or any other legal theory, whether or not WebmedCentral
          is advised of the possibility of such damages.
        </p>
        <p>
          By visiting WebmedCentral or any related site(s), or by using one of
          its services, you are accepting the practices described in this
          “Disclaimer” statement. We may amend this statement at any time; if we
          make changes to it, we will post those changes on this page so that
          you are always aware of our policies. Any questions regarding our
          “Disclaimers” should be directed to Webmed Limited. Please contact us,
          if you have any questions or concerns about this section.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimers;
