import React, { useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "../components/Footer";
import { BASE_URL } from "./Config";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/users/forgotpassword`, {
        email,
      });

      setMessage(response.data.message);
      toast.success(response.data.message);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      setMessage(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <section className="card mb-0 vh-100">
        <div className="container py-10 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-10 col-lg-5 col-xl-5 offset-xl-1 card mb-10">
              <h3 className="card-header" style={{ color: "#F74B02" }}>
                <span className="pi pi-lock" style={{ color: "#067ADC" }}>
                  {" "}
                </span>{" "}
                {"   "}
                Forgot{" "}
                <span
                  style={{
                    color: "#067ADC",
                    marginRight: "3px",
                    marginLeft: "3px",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  /
                </span>{" "}
                Change Password
              </h3>
              <br />
              <p className="card-title text-center">
                Enter your email address to receive a password reset link:
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="container d-flex align-items-center justify-content-center vh-50"
              >
                <input
                  type="email"
                  value={email}
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  style={{ width: "80%", marginBottom: "15px" }}
                />
                <button
                  className="btn btn-primary"
                  style={{
                    width: "35%",
                    border: "1px solid #FFFFFF",
                    borderRadius: "10px",
                    color: "#FFFFFF",
                  }}
                  onClick={handleResetPassword}
                >
                  Reset Password
                </button>
              </div>
              <p
                style={{ color: "#067ADC" }}
                className="card-title text-center"
              >
                {message}
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ForgotPassword;
