import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Checklistforarticlesubmission = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Check List for Submitting an Article
          </div>
        </p>

        <p>
          1. Manuscripts should be composed in accordance with our 'instructions
          for authors'
        </p>

        <p>
          2. You will need name, designation, affiliation and email id for all
          authors.
        </p>

        <p>
          3. For patient identifiable information, a consent form from the
          patient or legal guardian should accompany the manuscript.
        </p>

        <p>
          4. Copyright Transfer Form would only be required if you are not one
          of the authors.
        </p>

        <p>
          5. All the diagrams, tables, pictures and figures (identified as
          "Illustration") can be uploaded as JPG, GIF, PNG, GIF, PNG, BMP or in
          DOC formats. Please label each file as Illustration 1, Illustration 2,
          Illustration 3 etc.
        </p>
        <p>
          6. You can also upload upto five additional files with the article.
          These may be original data files, questionnaire files etc . You may
          use this functionality to upload files in your preferred format.
          Please give these files a self-descriptive name. Our readers will have
          free access to these files.
        </p>

        <p>
          7. You will be required to choose at least five referees from the
          WebmedCentral Editorial board. You can enter details of more
          reviewers, if you so wish.
        </p>

        <p>
          8. We will require information regarding source(s) of funding and
          competing interest(s).
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Checklistforarticlesubmission;
