import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Patientconsentform = () => {
  return (
    <>

<Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
  
      <div className="card">
    

        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Patient Consent Form
          </div>
        </p>


        WebmedCentral Patient Consent Form

For a patient’s consent to publication of information about them on WebmedCentral and its publications and products.


Name of the subject in the article or illustration__________________________

Title of article: _________________________________________________

Responsible author: ______________________________________________

 

I_________________________________________   [insert full name] give my consent for this information about MYSELF/MY CHILD OR WARD/MY RELATIVE [circle correct description] relating to the subject matter above (“the Information”) to appear in the WebmedCentral and its publications and products.

I have seen and read the material to be published on WebmedCentral in accordance with the Webmed ltd “terms and conditions”, “disclaimers”, and “copyright policy”.

 

It is my understanding that:

 

(1) The information related to me (or my relative), investigation results, or photographs would be published without disclosing my identity. I am however aware that it may still be possible for close relatives, associates and the concerned medical team to identify patients from those details.

 

(2) The users of the WebmedCentral, which mostly include biomedical researchers and doctors, but may also include members of general public, would have free access to the information and may download, save, or print the information for private circulation.

 

 

 

 

 

 

Signed:                                                                          Date:

 

 

 

Name (please print):


       
      </div>
      <Footer />




       
 
    </>
  )
}

export default Patientconsentform