import axios from "axios";
import { BASE_URL } from "../components/Config";

const API = axios.create({ baseURL: `${BASE_URL}` });
// const API = axios.create({baseURL:"https://omkie.com:3001/"});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }
  return req;
});

export const signIn = (formData) => API.post("/users/signin", formData);
export const signUp = (formData) => API.post("/users/signup", formData);
export const googleSignIn = (result) => API.post("/users/googleSignIn", result);
export const createTour = (tourData) => API.post("/tour", tourData);

// export const getTours = (page) => API.get(`/tour?page=${page}`);

// export const getTours = () => API.get(`/tour`);
// export const fetchTours = (pageNumber, pageSize) => API.fetch(`/tour?pageNumber=${pageNumber}&pageSize=${pageSize}`);
// export const getTours = (pageNumber, pageSize) => API.get(`/tour?pageNumber=${pageNumber}&pageSize=${pageSize}`);



// export const getAllTours = () => API.get(`/tour/all`);

export const getTour = (id) => API.get(`/tour/${id}`);
export const deleteTour = (id) => API.delete(`/tour/${id}`);
export const getToursByUser = (userId) => API.get(`/tour/userTours/${userId}`);
export const getToursBySearch = (searchQuery) => API.get(`/tour/search?searchQuery=${searchQuery}`);
export const getTagTours = (tag) => API.get(`/tour/tag/${tag}`);
export const getRelatedTours = (tags) => API.post(`/tour/relatedTours`, tags);
export const likeTour = (id) => API.patch(`/tour/like/${id}`);

// export const updateuser = (data,id) => API.patch(`/users/${id}`, data);



export const updateuser = (data, id) => {
  const token = JSON.parse(localStorage.getItem("profile")).token;
  return API.patch(`/users/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const showUser = (data, id) => API.get(`/users/${id}`, data);
export const updateTour = (updatedTourData, id) => API.patch(`/tour/${id}`, updatedTourData);
export const fetchCategories = async () => {
  try {
    const response = await API.get("/categories/categories");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

export const fetchCountries = async () => {
  try {
    const response = await API.get("/countries");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data);
  }
};

// export const verifyEmail = (data, id, token) => API.get(`/users/${id}/verify/${token}`, data);
export const verifyEmail = (id, token, data) => API.get(`/users/${id}/verify/${token}`, data);
export const fetchAllUsers = () => API.get(`/users`);