import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { BASE_URL } from "./Config";
import Footer from "./Footer";
import { useSelector, useDispatch } from "react-redux";
import { updateTour } from "../redux/features/tourSlice";
import { showUser } from "../redux/features/userSlice";
import { getToursByUser } from "../redux/features/tourSlice";

const SuccessPage = () => {
  const [paymentId, setpaymentId] = useState("");
  const [payerId, setPayerId] = useState("");
  const [token, setToken] = useState("");

  // Use useLocation to get access to URL parameters
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({ ...state.auth }));
  const { userTours } = useSelector((state) => state.tour);
  const userId = user?.result?._id;

  useEffect(() => {
    if (userId) {
      dispatch(getToursByUser(userId));
      dispatch(showUser());
    }
  }, [userId, dispatch]);

  const userArticles = userTours.filter((tour) => tour.creator === userId);
  userArticles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const id = userArticles[0]?._id;

  // console.log("Article ID:", id);

  const updatedTourData = { paymentId, payerId, token };

  useEffect(() => {
    if (id) {
      dispatch(updateTour({ id, updatedTourData }));
    } // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    // Extract paymentId, token, and PayerID from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get("paymentId");
    const token = searchParams.get("token");
    const payerId = searchParams.get("PayerID");

    // Perform additional actions like fetching the transaction ID from the backend
    axios
      .get(`${BASE_URL}/paypal/get-transaction-id`, {
        paymentId,
        token,
        payerId,
      })
      .then((response) => {
        setpaymentId(response.config.paymentId);
        setPayerId(response.config.payerId);
        setToken(response.config.token);
        console.log("Updated Transaction ID:", response.data.transactionId);

        // Perform axios.post here or in a separate function
        savePaymentDetails(paymentId, token, payerId);
      })
      .catch((error) => {
        console.error("Error fetching transaction ID:", error);
      });
  }, [location.search]); // Re-run the effect when the location.search changes

  const savePaymentDetails = (paymentId, token, payerId) => {
    axios
      .post(`${BASE_URL}/paypal/success`, {
        paymentId,
        token,
        payerId,
      })
      .then((response) => {
        console.log("Payment details saved:", response.data);
      })
      .catch((error) => {
        console.error("Error saving payment details:", error);
      });
  };

  return (
    <>
      <Navbar />

      <br />
      <br />
      <br />
      <br />
      <br />

      <div class="card">
        <br />
        <br />
        <h1>Payment is Successful... </h1>
        <p style={{ color: "#067ADC", fontSize: "16px" }}>
          Thank you for your payment!
        </p>
        {paymentId && (
          <div>
            <span style={{ color: "#067ADC", fontSize: "16px" }}>
              PayPal Transaction ID:{" "}
            </span>
            {paymentId}
            <br />
            <span style={{ color: "#067ADC", fontSize: "16px" }}>
              PayPal Payer ID:{" "}
            </span>
            {payerId}
            <br />
            <span style={{ color: "#067ADC", fontSize: "16px" }}>
              PayPal Token:{" "}
            </span>
            {token}
            <br />
            <span style={{ color: "#067ADC", fontSize: "16px" }}>
              {/* Article ID:{" "} */}
            </span>
            {/* {id}  */}
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>

      <Footer />
    </>
  );
};

export default SuccessPage;
