import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./Config";
import { toast } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ResetPassword = () => {
  const { userId, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    try {
      if (!password || password.trim() === "") {
        setMessage("Password not entered");
        toast.error("Password not entered"); // Use toast.error instead of toast.success
        return;
      }

      const response = await axios.post(
        `${BASE_URL}/users/resetpassword/${userId}/${token}`,
        { userId, token, password }
      );

      if (password !== confirmPassword) {
        setMessage("Passwords do not match.");
        toast.error("Passwords do not match.");
        return;
      } else {
        setMessage("Password reset successfull");
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Reset Password Error:", error);
    }
  };

  useEffect(() => {
    // Clear any existing toasts when the component mounts
    toast.dismiss();
  }, []);

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <br />






      <section className="card mb-0 vh-100">
        <div className="container py-10 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-10 col-lg-5 col-xl-5 offset-xl-1 card mb-10">
              <h2 className="card-header" style={{ color: '#F74B02' }}>
              <span className="pi pi-lock-open" style={{ color: '#067ADC' }}> </span> {"   "}
                Reset Password
              </h2>
           <br />
              <p className="card-title text-center" style={{ color: '#F74B02' }}>
             Enter your new password:
              </p>
      

              <input
            className="form-control vh-10"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your new password"
            style={{ display: "flex", gap: "35px" }}
          />
          <br />
          <input
            className="form-control"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your new password"
          />
          <br />
          
          <button onClick={handleResetPassword} className="btn btn-primary"  style={{
                    width: "35%" }}>
            Reset Password
          
          </button>
         
          <p style={{ color: "#067ADC" }} className="card-title text-center">
            {message}
          </p>
              

            
    
            </div>
          </div>
        </div>
      </section>








      <Footer />
    </>
  );
};

export default ResetPassword;
