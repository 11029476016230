import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { postReview } from "../redux/features/reviewSlice";
import { fetchReviews } from "../redux/features/reviewSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PostReview = () => {
  const [formData, setFormData] = useState({
    title: "",
    claims: "",
    novel: "",
    properly: "",
    support: "",
    protocol: "",
    methodology: "",
    experiments: "",
    outstanding: "",
    comments: "",
    invited: "no",
    previously: "no",
    suitable: "no",
    interest: "no",
    anonymously: "no",
  });

  const { tour } = useSelector((state) => state.tour);
  // console.log("dsdsddsd", tour)
  const user = useSelector((state) => state.auth.user);
  // const review = useSelector((state) => state.review);
  // const review = useSelector((state) => state.review.reviews);

  // const reviewsForArticle = review.reviews.filter(
  //   (review) => review.articleId === tour._id
  // );

  // console.log("tour._id:", tour);
  // console.log("Reviews:", review);
  // console.log("Reviews for the article:", reviewsForArticle);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const reviewCountForArticle = reviewsForArticle.length;
  // const sortedReviewsForArticle = reviewsForArticle.sort(
  //   (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  // );

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
  
    let updatedValue = value;
  
    if (type === "text" && value.length > 0) {
      updatedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
  
    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked ? "yes" : "no",
      }));
    } else if (type === "radio") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: updatedValue,
      }));
    }
  };
  

  useEffect(() => {
    dispatch(fetchReviews());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   dispatch(fetchReviews(tour._id));
  // }, [dispatch, tour._id]);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   dispatch(postReview({ formData, id: tour._id, userid: user.result._id }));
  // };

  const handleSubmit = (event) => {
    event.preventDefault();



    dispatch(
      postReview({
        formData,
        id: tour._id,
        artid: tour.articleId,
        articletitle: tour.title,
        userid: user.result._id,
        usertitle: user.result.title,
        userfirstname: user.result.firstName,
        usermiddlename: user.result.middleName,
        userlastname: user.result.lastName,
        navigate, toast
      })
    );
  };

  const isSubmitDisabled = () => {
    // Check if any required fields are empty
    return Object.values(formData).some(
      (value) =>
        value === "" &&
        !["invited", "previously", "suitable", "interest", "anonymously"].includes(value)
    );
  };




  return (
    <>
      <Navbar />

      <br />
      <br />
      <br />
      <br />



     
  

      <div
        className="card "
        //   style={{ borderRadius: "400rem" }}
      >
        <div className="row col-sm-20">
          <div className="col-2">
            {" "}
            <img
              // src="https://i.imgur.com/xELPaag.jpg"
              width={70}
              alt=""
              className="rounded-circle mt-2"
            />{" "}
          </div>
          <div className="col-10 ">
            <div className="comment-box ml-2">
            <br />
          


          
              <div className="col-sm-10 ">
              <div className="card mb-9">
                <div className="card-header" >
               
                <h4 style={{ color: '#067ADC' }}>Submit the review</h4>
                <p>All fields marked as  <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i> are mandatory to submit the review</p>
                </div>
                <div className="card-body col-sm-20">
                <form onSubmit={handleSubmit}>
                <div className="col-sm-30">
                  <div className="form-group row">
                  <label htmlFor="claims" className="col-sm-12 col-form-label">
                      Title  <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control h-100"
                        value={formData.title}
                        onChange={handleChange}
                      />

                      
                      
                    </div>
                  </div>
                 
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
               

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label htmlFor="claims" className="col-sm-12 col-form-label">
                      What are the main claims of the paper and how important
                      are they? <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="claims"
                        name="claims"
                        className="form-control h-100"
                        value={formData.claims}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label htmlFor="novel" className="col-sm-16 col-form-label">
                      Are these claims novel? If not, please specify papers that
                      weaken the claims to the originality of this one. 
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="novel"
                        name="novel"
                        className="form-control h-100"
                        value={formData.novel}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                 
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
               

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="properly"
                      className="col-sm-12 col-form-label"
                    >
                      Are the claims properly placed in the context of the previous literature?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="properly"
                        name="properly"
                        className="form-control h-100"
                        value={formData.properly}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
              
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="support"
                      className="col-sm-16 col-form-label"
                    >
                      Do the results support the claims? If not, what other
                      evidence is required?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="support"
                        name="support"
                        className="form-control h-100"
                        value={formData.support}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
          
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="protocol"
                      className="col-sm-16 col-form-label"
                    >
                      If a protocol is provided, for example for a randomized
                      controlled trial, are there any important deviations from
                      it? If so, have the authors explained adequately why the
                      deviations occurred?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="protocol"
                        name="protocol"
                        className="form-control h-100"
                        value={formData.protocol}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
       
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
               

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="methodology"
                      className="col-sm-16 col-form-label"
                    >
                      Is the methodology valid? Does the paper offer enough
                      details of its methodology that its experiments or its
                      analyses could be reproduced?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="methodology"
                        name="methodology"
                        className="form-control h-100"
                        value={formData.methodology}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
                

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="experiments"
                      className="col-sm-16 col-form-label"
                    >
                      Would any other experiments or additional information
                      improve the paper? How much better would the paper be if
                      this extra work was done, and how difficult would such
                      work be to do, or to provide?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="experiments"
                        name="experiments"
                        className="form-control h-100"
                        value={formData.experiments}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                 
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
               

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="outstanding"
                      className="col-sm-16 col-form-label"
                    >
                      Is this paper outstanding in its discipline? (For example,
                      would you like to see this work presented in a seminar at
                      your hospital or university? Do you feel these results
                      need to be incorporated in your next general lecture on
                      the subject?) If yes, what makes it outstanding? If not,
                      why not?
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="outstanding"
                        name="outstanding"
                        className="form-control h-100"
                        value={formData.outstanding}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
              
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
             

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="comments"
                      className="col-sm-12 col-form-label"
                    >
                      Any other comments?:
                      <i className="bi bi-asterisk" style={{ color: 'red', fontSize: '10px' }}></i>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="comments"
                        name="comments"
                        className="form-control h-100"
                        value={formData.comments}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
                

                <div className="col-sm-9">
                  <div className="form-group row">
                    <label
                      htmlFor="invited"
                      className="col-sm-9 col-form-label"
                    >
                      Were you invited by the author for your reviews?
                   
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        id="invited"
                        name="invited"
                        checked={formData.invited === "yes"}
                        onChange={handleChange}
                      />  <label
                      htmlFor="invited"
                      className="col-sm-9 col-form-label"
                    >
                        (Select the checkbox if Yes)
                    </label>
                    </div>
                  </div>
             
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="previously"
                      className="col-sm-9 col-form-label"
                    >
                      Have you previously published on this or a similar topic?
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="radio"
                        id="previously-yes"
                        name="previously"
                        value="yes"
                        checked={formData.previously === "yes"}
                        onChange={handleChange}
                      />
                      <label htmlFor="previously-yes">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="previously-no"
                        name="previously"
                        value="no"
                        checked={formData.previously === "no"}
                        onChange={handleChange}
                      />
                      <label htmlFor="previously-no">No</label>
                    </div>
                  </div>
               
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="suitable"
                      className="col-sm-16 col-form-label"
                    >
                      Please confirm that you have suitable experience and
                      knowledge to review this article
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="radio"
                        id="suitable-yes"
                        name="suitable"
                        value="yes"
                        checked={formData.suitable === "yes"}
                        onChange={handleChange}
                      />
                      <label htmlFor="suitable-yes">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="suitable-no"
                        name="suitable"
                        value="no"
                        checked={formData.suitable === "no"}
                        onChange={handleChange}
                      />
                      <label htmlFor="suitable-no">No</label>
                    </div>
                  </div>
            
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
              

                <div className="col-sm-12">
                  <div className="form-group row">
                    <label
                      htmlFor="interest"
                      className="col-sm-9 col-form-label"
                    >
                      Do you have any competing interest(s) in posting this
                      review?
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="radio"
                        id="interest-yes"
                        name="interest"
                        value="yes"
                        checked={formData.interest === "yes"}
                        onChange={handleChange}
                      />
                      <label htmlFor="interest-yes">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="interest-no"
                        name="interest"
                        value="no"
                        checked={formData.interest === "no"}
                        onChange={handleChange}
                      />
                      <label htmlFor="interest-no">No</label>
                    </div>
                  </div>
                  
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
           

                <div className="col-sm-9">
                  <div className="form-group row">
                    <label
                      htmlFor="anonymously"
                      className="col-sm-9 col-form-label"
                    >
                      Do you wish to submit this review anonymously?
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="radio"
                        id="anonymously-yes"
                        name="anonymously"
                        value="yes"
                        checked={formData.anonymously === "yes"}
                        onChange={handleChange}
                      />
                      <label htmlFor="anonymously-yes">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="anonymously-no"
                        name="anonymously"
                        value="no"
                        checked={formData.anonymously === "no"}
                        onChange={handleChange}
                      />
                      <label htmlFor="anonymously-no">No</label>
                    </div>
                  </div>
                  
                </div>
                <hr style={{ borderColor: '#F74B02', borderWidth: '2px' }} />
          

                <div className="comment-btns mt-2">
                  <div className="row">
                    <div className="row">
                      <div className="col-sm-9">
                     
                        <Button type="submit" className="btn btn-primary" disabled={isSubmitDisabled()}
                        style={{
                  backgroundColor: isSubmitDisabled() ? "#F74B02" : "#067ADC",
                  color: isSubmitDisabled() ? "#F74B02" : "#067ADC",
                }}
                        >
                          Submit
                        </Button>
                        <br/>
                        
                    
                      </div>
                      <br />
                  <br />
                    </div>
                    <br />

                    <div className="col-6">
                      <div className="pull-right"></div>
                    </div>
                  </div>
                </div>
              </form>
           
                </div>
              </div>
              </div>

              

             

           
            </div>
          </div>
        </div>
      </div>
      {/* <h2>Reviews:</h2>
      <ul>
        {user?.result?._id}

        {review.reviews.map((review) => (
          <li key={review._id}>
            <strong>{review.title}</strong>: {review.details}
          </li>
        ))}
      </ul>

      <div>
        <h2>Reviews for the Article: {tour.title}</h2>
        <p>Review Count: {reviewCountForArticle}</p>
        {sortedReviewsForArticle.length === 0 ? (
          <p>No reviews posted for this article yet.</p>
        ) : (
          <ul>
            {sortedReviewsForArticle.reverse().map((review) => (
              <li key={review._id}>
                <strong>{review.title}</strong>: {review.claims}
              </li>
            ))}
          </ul>
        )}
      </div> */}

     

    
      <Footer />
    </>
  );
};

export default PostReview;
