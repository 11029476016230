import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
 
        <p style={{ fontWeight: "bold" }}>
          <div className="card-header" style={{ color: '#067ADC', fontSize: '16px' }}>Privacy Policy</div>
      
        </p>

        <p>
          The domains www.webmedcentral.com, www.webmedcentral.co.uk and
          www.webmedcentralplus.com are owned by Webmed limited registered at 35
          Rosemount, Durham, DH1 5GA, United Kingdom. The pronouns ‘you’ and
          ‘your’ are used to refer to the end user, while ‘we’, ‘us’, ‘portal
          ‘’our’ ‘company’, ‘WebmedCentral’ and www.webmedcentral.com,
          www.webmedcentral.co.uk, and www.webmedcentralplus.com (also known as
          plus)are used to refer to Webmed limited.
        </p>
        <p>
          We at Webmed limited take your privacy very seriously. We make every
          effort to handle collected information appropriately. We treat our
          user’s information as private and confidential. We do not store credit
          card details nor do we disclose your data to third parties without
          your expressed permission or unless required by law.{" "}
        </p>

        <p style={{ fontWeight: "bold" }}>Collecting personal Information:</p>
        <p>
          Webmed limited gathers data that users provide through registration,
          comment/review submission or article submission on our portal.
          Submission of articles and reviewing/commenting an article with us
          requires certain basic information.. We only ask that personal
          information of our authors and reviewers which are essential to
          attribute the information published by us. It would help our readers
          evaluate the information properly. . Certain other information is
          sought to provide you with a better service and you can choose to
          decline to provide this information. One does not require registering
          with us for reading, downloading or printing articles from our
          websites
        </p>

        <p style={{ fontWeight: "bold" }}>Collecting usage data:</p>
        <p>
          To provide you with the highest standards of service, we collect
          information regarding the patterns of usage (like how often users
          visit, what parts of the site users visit etc) on our portal. This
          enables us to identify the most popular articles and services. We also
          gather information through an aggregated tracking system that derives
          data mainly by tallying page views throughout our sites and capturing
          information on the type of browser being used to view the site. This
          information enables us to better provide content and to respond to our
          users' needs. It also is used to help our advertisers and sponsors to
          better understand the demographics of our audience.
        </p>
        <p>
          Statistical demographic information about our users may be supplied to
          advertisers. We may target online advertising to specific users on the
          basis of this demographic information, or on the basis of their past
          behaviour on the site, but information about an individual will not be
          passed on to an advertiser without the users' express consent. This
          information is only used to create broad demographic summaries of
          where our users come from.
        </p>

        <p style={{ fontWeight: "bold" }}>Displaying the information:</p>
        <p>
          The name and credentials (not email or phone number or fax numbers) of
          all WebmedCentral Community members (authors, reviewers, advisors,
          editors etc) will be available freely on our portal. These details are
          made available purely to facilitate scientific communication and to
          provide credentials to every manuscript/review/comment published on
          our portal. We will ourselves never send unsolicited e-mail to our
          authors, unless it directly concerns the paper they have published
          with us.
        </p>
        <p style={{ fontWeight: "bold" }}>Cookies:</p>
        <p>
          We may use “cookies,” which are small files that the site places on
          your hard drive for identification purposes. These cookies are used
          for site registration, track usage and customization the next time you
          visit us. You should note that cookies cannot read data off of your
          hard drive.
        </p>
        <p>
          However, if you do not want information collected through the use of
          cookies, there is a simple procedure in most browsers that allows the
          user to deny or accept the cookie feature. Please note that cookies
          may be necessary to provide the Subscriber or User with certain
          features available on our portal.{" "}
        </p>
        <p>
          By visiting WebmedCentral or any related sites, or by using one of its
          services, you are accepting the practices described in this Privacy
          Policy Statement. We may amend this Statement at any time; so please
          check these policy pages periodically. If you have any questions or
          concerns about your privacy, please contact us for any further
          clarification.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
