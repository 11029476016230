import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const AdvertisingPolicy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Advertising Policy
          </div>
        </p>
        <p>
          The domains www.webmedcentral.com , www.webmedcentral.co.uk and
          www.webmedcentralplus.com are owned by Webmed limited. The pronouns
          "you" and "your" are used to refer to the end user, while "we," "us",
          "our" “company”, www.webmedcentral.com,
          www.webmedcentral.co.uk,www.webmedcentralplus.com (also known as
          plus),and WebmedCentral are used to refer to Webmed limited registered
          at 35 Rosemount, Durham, DH1 5GA United Kingdom.
        </p>
        <p>
          We may sell advertising space on any of our web pages as well as in
          any future print version to generate revenue. We may also allow
          commercial sponsorship to support the medical issues or supplements,
          subscriptions, seminars and conferences, as well as our international
          editions programme.
        </p>
        <p>
          We have a dual publishing model. Articles and comments posted on
          www.webmedcentral.com and www.webmedcentral.co.uk do not undergo any
          prior peer or editorial review. On these websites, we aim to publish
          all articles and comments submitted to us. However, we reserve the
          right to not publish, or remove from our website at any time following
          publication, any article or comment as judged suitable by us.
        </p>
        <p>
          Articles published on www.webmedcentralplus.com undergo traditional
          pre publication peer review prior to publication on the website.
        </p>
        <p>
          Our advertisers have no control over published material under any
          circumstances. The contents on this website or those of any printed
          material published by us are in no way influenced by the funding
          source or advertisers.
        </p>
        <p>
          All our advertisements will clearly identify the advertiser and the
          product or service being offered. Advertisements would be suitably
          identified as such both on the website and any printed material to
          prevent confusion with scientific material published on the website.
        </p>
        <p>
          Acceptance of advertisements: We reserve the right to decline any
          advertisement or discontinue the publication of any advertisement
          previously approved. Our decision shall be final in this regard. We
          also reserve the right to amend this advertising policy, and any other
          policies, disclaimers, and terms and conditions governing the use of
          our websites from time to time. Please follow these web pages to keep
          yourself updated. We want our advertisers to ensure that
          advertisements are not deceptive or misleading. Exaggerated or
          extravagantly worded advertisements should be avoided. Products or
          services advertised may or may not be related to the practice of
          medicine, medical education, or health care delivery. Advertisements
          should not be indecent or offensive in either text or artwork, or
          contain negative content of a personal, racial, ethnic, sexual
          orientation, or religious character. It is also the responsibility of
          individual advertisers/sponsors to ensure that the advertisements meet
          the relevant legal and ethical standards.
        </p>
        <p>
          Users click on the advertising links on our website at their own risk.
          We do not endorse any claims made in the advertisements; nor do we
          take any liability for any harm they might suffer themselves or
          inflict on a third party by following these links. The use of such
          links would be bound by the terms and conditions of respective
          websites.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default AdvertisingPolicy;
