import React from 'react';

const BASE_URL = process.env.REACT_APP_SECRET;
// const BASE_URL = 'https://omkie.com:3001'; 

const Config = () => {
  return (
    <>

    baseurl is the te: {process.env.REACT_APP_SECRET}

   
    </>
  );
};

export default Config;
export { BASE_URL };
