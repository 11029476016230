import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Charges = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Charges
          </div>
        </p>

        <p>
          Our article processing charges are GBP 50.00 per article. There will
          be an article processing charges waiver option for disadvantaged
          authors.{" "}
        </p>
        <p>
          We encourage authors to submit revised versions in response to
          referees suggestions and thus publish revised versions free of charge.
        </p>
        <p>
          Further reviewers can avail 50% discount on one article submission for
          every 2 non – anonymous reviews submitted. WebmedCentral editors can
          submit 3 articles at 50% discount.
        </p>
        <p>
          We endeavour to publish most articles within 48 hours of submission.
        </p>
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Charges;
