import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/authSlice";
import TourReducer from "./features/tourSlice";
import userReducer from "./features/userSlice";
import categoryReducer  from "./features/categorySlice";
import reviewReducer from "./features/reviewSlice";
import commentsReducer from "./features/commentsSlice";
import countryReducer from "./features/countrySlice";



export default configureStore({
  reducer: {
    auth: AuthReducer,
    tour: TourReducer,
    user: userReducer,
    category:categoryReducer,  
    review:reviewReducer,
    comments:commentsReducer,
    country:countryReducer,
  


  },
});
