import React, { useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { BASE_URL } from "./Config";
import Footer from "./Footer";
import { Button } from "react-bootstrap";
import { Oval as Loader } from "react-loader-spinner";

const PaymentPage = () => {
  const [paymentStatus, setPaymentStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/paypal/create-payment`);
      const approvalUrl = response.data.approvalUrl;
      window.location.replace(approvalUrl);
      setLoading(true);
    } catch (error) {
      console.error("Error initiating payment:", error);
      setPaymentStatus("Error initiating payment");
    }
  };

  return (
    <>
      <Navbar />

      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card mb-0 vh-100">
        <br />

        <h1>
          Thank you for submitting the article. Kindly make the payment to
          finalize the article submission process.
        </h1>
        <br />
        <div>
          <Button onClick={handlePayment}>
            {loading ? (
              <Loader type="Oval" color="#FFFFFF" height={20} width={20} />
            ) : (
              "Initiate Payment"
            )}
          </Button>
        </div>

        <p>{paymentStatus}</p>
      </div>

      <Footer />
    </>
  );
};

export default PaymentPage;
