import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getToursByUser } from "../redux/features/tourSlice";
import { deleteTour } from "../redux/features/tourSlice";
import { showUser } from "../redux/features/userSlice";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";
import { fetchReviews } from "../redux/features/reviewSlice";
import { getTours } from "../redux/features/tourSlice";
import profilepic from "../components/img/samplepic.jpg";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../Dashboard.css";

const Dashboard = () => {
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const { user } = useSelector((state) => ({ ...state.auth }));
  // console.log("mahi", user)
  // const reviews = useSelector((state) => state.review);
  const reviews = useSelector((state) => state.review.reviews);
  const { userTours } = useSelector((state) => state.tour);
  const userId = user?.result?._id;
  // console.log("LOVE", userTours);

  const reviewsPerPage = 5;
  const pagesToShow = 3;

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;

  const currentReviews = reviews
    .filter((review) => user?.result?._id === review.userId)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(indexOfFirstReview, indexOfLastReview);

  console.log("currentReviews", currentReviews.length, currentReviews);
  // Use the length of the original reviews array for the total count
  const totalReviewCount = reviews.filter(
    (review) => user?.result?._id === review.userId
  ).length;

  // console.log("totalReviewCount", totalReviewCount);

  // Calculate the total number of pages
  const totalReviewPages = Math.ceil(totalReviewCount / reviewsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the start and end page numbers to show
  let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  let endPage = Math.min(totalReviewPages, startPage + pagesToShow - 1);

  // Adjust startPage when near the end of the pages
  if (endPage - startPage + 1 < pagesToShow) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  // Page numbers to show
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const [currentPagemyarticle, setCurrentPagemyarticle] = useState(1);
  const indexOfLastReviews = currentPagemyarticle * reviewsPerPage;
  const indexOfFirstReviews = indexOfLastReviews - reviewsPerPage;

  const currentReviewsonmyarticle = reviews
    .filter(
      (review) => userTours.some((tour) => tour._id === review.articleId)
      // && user?.result?._id === review.userId
      // user?.result?._id === review.userId
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(indexOfFirstReviews, indexOfLastReviews);

  console.log(
    "currentReviewsonmyarticle",
    currentReviewsonmyarticle.length,
    currentReviewsonmyarticle
  );

  const totalReviewCountmyarticle = reviews.filter((review) =>
    userTours.some((tour) => tour._id === review.articleId)
  ).length;

  // Calculate the total number of pages
  const totalReviewPagesmyarticle = Math.ceil(
    totalReviewCountmyarticle / reviewsPerPage
  );

  const handlePageChangemyarticle = (pageNumber) => {
    setCurrentPagemyarticle(pageNumber);
  };

  // Calculate the start and end page numbers to show
  let startPages = Math.max(
    1,
    currentPagemyarticle - Math.floor(pagesToShow / 2)
  );
  let endPages = Math.min(
    totalReviewPagesmyarticle,
    startPages + pagesToShow - 1
  );

  // Adjust startPage when near the end of the pages
  if (endPages - startPages + 1 < pagesToShow) {
    startPages = Math.max(1, endPages - pagesToShow + 1);
  }

  // Page numbers to show
  const pageNumbersmyarticle = Array.from(
    { length: endPages - startPages + 1 },
    (_, index) => startPages + index
  );

  // const reviews = useSelector((state) => state.review.reviews);
  // // Find the specific review based on reviewId
  // const article = reviews.find((review) => review.articleId === reviewId);

  useEffect(() => {
    dispatch(fetchReviews());
    dispatch(getTours());
  }, [dispatch]);

  // console.log("TOURS", userTours);
  // const users = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
  }, [dispatch]);

  // console.log("user", user);
  // console.log("user.result", user?.result);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this tour ?")) {
      dispatch(deleteTour({ id, navigate, toast }));
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(getToursByUser(userId));
      dispatch(showUser());
    }
  }, [userId, dispatch]);

  // const unapprovedArticles = userTours.filter((item)=>(
  //   item.approved===false
  // ))
  // console.log("unapprovedArticles", unapprovedArticles)

  //pagination for unapproved articles section
  const unapprovedPerPage = 5;
  const unapprovedPagestoShow = 3;

  const [unapproved, setUnapproved] = useState(1);
  const indexOfLastUnapproved = unapproved * unapprovedPerPage;
  const indexOfFirstUnapproved = indexOfLastUnapproved - unapprovedPerPage;

  // Filter unapproved articles
  const unapprovedArticlesFiltered = userTours.filter(
    (item) => item.approved === false
  );

  // Sort the filtered array by createdAt
  const sortedUnapprovedArticles = unapprovedArticlesFiltered.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // Slice the sorted array based on pagination
  const unapprovedArticles = sortedUnapprovedArticles.slice(
    indexOfFirstUnapproved,
    indexOfLastUnapproved
  );

  const totalUnapprovedCount = userTours.filter(
    (item) => item.approved === false
  ).length;

  const totalUnapproved = Math.ceil(totalUnapprovedCount / unapprovedPerPage);

  const handleUnapprovedChange = (pageNumber) => {
    setUnapproved(pageNumber);
  };

  // Calculate the start and end page numbers to show
  let UnapprovedstartPage = Math.max(
    1,
    unapproved - Math.floor(unapprovedPagestoShow / 2)
  );
  let UnapprovedendPage = Math.min(
    totalUnapproved,
    UnapprovedstartPage + unapprovedPagestoShow - 1
  );

  // Adjust startPage when near the end of the pages
  if (UnapprovedendPage - UnapprovedstartPage + 1 < unapprovedPagestoShow) {
    UnapprovedstartPage = Math.max(
      1,
      UnapprovedendPage - unapprovedPagestoShow + 1
    );
  }

  // Page numbers to show
  const UnapprovedpageNumbers = Array.from(
    { length: UnapprovedendPage - UnapprovedstartPage + 1 },
    (_, index) => UnapprovedstartPage + index
  );

  // const approvedArticles = userTours.filter((item) => item.approved === true);
  // console.log("approvedArticles", approvedArticles);

  //pagination for approved articles section
  const approvedPerPage = 5;
  const approvedPagestoShow = 3;

  const [approved, setapproved] = useState(1);
  const indexOfLastapproved = approved * approvedPerPage;
  const indexOfFirstapproved = indexOfLastapproved - approvedPerPage;

  // Filter approved articles
  const approvedArticlesFiltered = userTours.filter(
    (item) => item.approved === true
  );

  // Sort the filtered array by createdAt
  const sortedapprovedArticles = approvedArticlesFiltered.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // Slice the sorted array based on pagination
  const approvedArticles = sortedapprovedArticles.slice(
    indexOfFirstapproved,
    indexOfLastapproved
  );

  const totalapprovedCount = userTours.filter(
    (item) => item.approved === true
  ).length;

  const totalapproved = Math.ceil(totalapprovedCount / approvedPerPage);

  const handleapprovedChange = (pageNumber) => {
    setapproved(pageNumber);
  };

  // Calculate the start and end page numbers to show
  let approvedstartPage = Math.max(
    1,
    approved - Math.floor(approvedPagestoShow / 2)
  );
  let approvedendPage = Math.min(
    totalapproved,
    approvedstartPage + approvedPagestoShow - 1
  );

  // Adjust startPage when near the end of the pages
  if (approvedendPage - approvedstartPage + 1 < approvedPagestoShow) {
    approvedstartPage = Math.max(1, approvedendPage - approvedPagestoShow + 1);
  }

  // Page numbers to show
  const approvedpageNumbers = Array.from(
    { length: approvedendPage - approvedstartPage + 1 },
    (_, index) => approvedstartPage + index
  );

  const stripTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <section className="card" style={{ backgroundColor: "#ffffff" }}>
        <div className="card container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <div
                    className="nav flex-column nav-pills me-3"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="card-body text-center">
                      {user?.result?._id && (
                        <div>
                          <img
                            className="img-fluid"
                            src={user.result.profileImage || profilepic}
                            alt="ProfileImage"
                            style={{
                              marginTop: "0px",
                              maxWidth: "200px",
                              maxHeight: "200px",
                            }}
                          />
                        </div>
                      )}

                      <p className="text-muted mb-0"></p>
                    </div>
                    <hr />
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="false"
                    >
                      <span
                        className="pi pi-user"
                        style={{ color: "#F74B02" }}
                      ></span>
                      {"  "}Profile
                    </button>
                    <hr />

                    <button
                      className="nav-link"
                      id="v-pills-articles-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-articles"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-articles"
                      aria-selected="false"
                    >
                      <span
                        className="pi pi-tablet"
                        style={{ color: "#F74B02" }}
                      ></span>
                      {"  "}Articles Submitted{" "}
                      <span
                        class="badge"
                        style={{
                          color: "#F74B02",
                          border: "1px solid #F74B02",
                          borderRadius: "20px",
                          padding: "4px",
                        }}
                      >
                        {" "}
                        {totalUnapprovedCount}
                      </span>
                    </button>

                    <hr />

                    <button
                      className="nav-link"
                      id="v-pills-articles-approved-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-articles-approved"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-articles-approved"
                      aria-selected="false"
                    >
                      <span
                        className="pi pi-tablet"
                        style={{ color: "#F74B02" }}
                      ></span>
                      {"  "}Approved Articles{" "}
                      <span
                        class="badge"
                        style={{
                          color: "#F74B02",
                          border: "1px solid #F74B02",
                          borderRadius: "20px",
                          padding: "4px",
                        }}
                      >
                        {" "}
                        {totalapprovedCount}
                      </span>
                    </button>

                    <hr />

                    <button
                      className="nav-link"
                      id="v-pills-reviews-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-reviews"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-reviews"
                      aria-selected="false"
                    >
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      ></span>
                      {"  "} Reviews Posted by me{" "}
                      <span
                        class="badge"
                        style={{
                          color: "#F74B02",
                          border: "1px solid #F74B02",
                          borderRadius: "20px",
                          padding: "4px",
                        }}
                      >
                        {" "}
                        {totalReviewCount}
                      </span>
                    </button>

                    <hr />

                    <button
                      className="nav-link"
                      id="v-pills-myarticlereviews-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-myarticlereviews"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-myarticlereviews"
                      aria-selected="false"
                    >
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      ></span>
                      {"  "} Reviews on my Articles{"  "}
                      <span
                        class="badge"
                        style={{
                          color: "#F74B02",
                          border: "1px solid #F74B02",
                          borderRadius: "20px",
                          padding: "4px",
                        }}
                      >
                        {"  "}
                        {totalReviewCountmyarticle}
                      </span>
                    </button>

                    <hr />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-100">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                          tabIndex="0"
                        >
                          <div className="form-group row">
                            <div className="col-sm-12 col-form-label">
                              {user?.result?.editorstatus === false &&
                                user?.result?.editor === false && (
                                  <p
                                    style={{
                                      color: "#F74B02",
                                      border: "1px solid #F74B02",
                                      borderRadius: "20px",
                                      padding: "4px",
                                    }}
                                  >
                                    You can apply for our Editor position and
                                    review articles as an WebmedCentral Editor
                                    by submitting your details in our Advanced
                                    Settings section.
                                  </p>
                                )}
                              {user?.result?.editorstatus === false &&
                                user?.result?.editor === true && (
                                  <p
                                    style={{
                                      color: "#F74B02",
                                      border: "1px solid #F74B02",
                                      borderRadius: "20px",
                                      padding: "4px",
                                    }}
                                  >
                                    Your application for Editor at WebmedCentral
                                    is pending.
                                  </p>
                                )}
                              {user?.result?.editorstatus === true &&
                                user?.result?.editor === true && (
                                  <p
                                    style={{
                                      color: "#F74B02",
                                      border: "1px solid #F74B02",
                                      borderRadius: "20px",
                                      padding: "4px",
                                    }}
                                  >
                                    You are now an Editor at WebmedCentral.
                                  </p>
                                )}
                            </div>
                          </div>

                          <div>
                            <nav>
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  className="nav-link active"
                                  id="nav-home-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-home"
                                  aria-selected="true"
                                >
                                  <span
                                    className="pi pi-user-edit"
                                    style={{ color: "#F74B02" }}
                                  ></span>
                                  {"  "} Profile Settings
                                </button>

                                <button
                                  className="nav-link"
                                  id="nav-contact-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-contact"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-contact"
                                  aria-selected="false"
                                >
                                  <span
                                    className="pi pi-user-plus"
                                    style={{ color: "#F74B02" }}
                                  ></span>
                                  {"  "} Advanced settings
                                </button>
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                              >
                                <br />

                                <div className="row">
                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="title"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Title:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.title}
                                          </span>
                                        </label>
                                        <br />
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="firstName"
                                          className="col-sm-6 col-form-label"
                                        >
                                          First Name:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.firstName}
                                          </span>
                                        </label>
                                        {/* {user?.result?.firstName} */}
                                        <br />
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="middleName"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Middle Name:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.middleName}
                                          </span>
                                        </label>
                                        {/* {user.result.middleName} */}
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="lastName"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Last Name:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.lastName}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="email"
                                          className="col-sm-6 col-form-label"
                                        >
                                          E-mail:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.email}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="designation"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Designation:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.designation}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="institute"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Institute:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.institute}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="phone"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Phone:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.phone}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="address"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Address:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.address}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="city"
                                          className="col-sm-6 col-form-label"
                                        >
                                          City:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.city}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="zipcode"
                                          className="col-sm-6 col-form-label"
                                        >
                                          Zipcode:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.zipcode}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <div className="col-sm-9">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="country"
                                          className="col-sm-6 col-form-label"
                                        >
                                          country:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.country}
                                          </span>
                                        </label>
                                        <br />
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <br />
                                  <hr />

                                  <label
                                    className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                                    htmlFor="exampleCheck1"
                                  >
                                    You have choosen the following speciality of
                                    interest
                                  </label>
                                  {user?.result?._id && (
                                    <div className="form-group row">
                                      <label
                                        htmlFor="category"
                                        className="col-sm-6 col-form-label"
                                        style={{ color: "#067ADC" }}
                                      >
                                        {/* {user.result.category
                                          .filter((ele) => ele.isChecked)
                                          .map((ele, index) => (
                                            <React.Fragment key={ele._id}>
                                              {ele.name}{","}
                                              {index !==
                                                user.result.category.length -
                                                  1 && <br />}
                                            </React.Fragment>
                                          ))} */}

                                        {user.result.category
                                          .filter((ele) => ele.isChecked)
                                          .map((ele) => ele.name)
                                          .join(", ")}
                                      </label>
                                    </div>
                                  )}

                                  <br />
                                  <br />
                                  <hr />
                                  <label
                                    className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                                    htmlFor="exampleCheck1"
                                  >
                                    Preferences ( Use{" "}
                                    <span style={{ color: "#F74B02" }}>
                                      Edit
                                    </span>{" "}
                                    to change the preferences )
                                  </label>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="work"
                                      name="work"
                                      value={user.result?.work || false}
                                      checked={user.result?.work || false}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="work"
                                    >
                                      Please check this box if you are a
                                      consultant in your field of work?
                                    </label>
                                  </div>
                                  <br />
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="newsletter"
                                      name="newsletter"
                                      value={user.result?.newsletter || false}
                                      checked={user.result?.newsletter || false}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="newsletter"
                                    >
                                      Please check this box if you would like to
                                      opt for our newsletter?
                                    </label>
                                  </div>
                                  <br />
                                  <br />

                                  <hr />
                                </div>
                              </div>

                              <div
                                className="tab-pane fade"
                                id="nav-contact"
                                role="tabpanel"
                                aria-labelledby="nav-contact-tab"
                              >
                                <br />
                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="biography"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Biography:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.biography}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="keywords"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Keywords for Areas of Specialisation:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.keywords}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="positions"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Academic positions:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.positions}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="interests"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Research interests:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.interests}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="other"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Any other information:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.other}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="idea"
                                          className="col-sm-12 col-form-label"
                                        >
                                          What I think of the idea behind
                                          WebmedCentral: ( Only for scientists
                                          applying for WebmedCentral):{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.idea}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="information"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Supplementary Information: Please
                                          detail information here which will
                                          help us conclude your appointment to
                                          the position you want. Contents in
                                          this box will only be seen by our
                                          admin staff):{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.information}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="place"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Place of work:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.place}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="publications"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Most recent publications:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.publications}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="review"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Previous experience of Peer review:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.review}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    {user?.result?._id && (
                                      <div className="form-group row">
                                        <label
                                          htmlFor="homepage"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Home page:{" "}
                                          <span style={{ color: "#067ADC" }}>
                                            {user.result.homepage}
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-9">
                              <Link to={`/edituser/${user?.result?._id}`}>
                                <Button className="btn btn-primary">
                                  Edit
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="v-pills-articles"
                          role="tabpanel"
                          aria-labelledby="v-pills-articles-tab"
                          tabIndex="1"
                        >
                          <label className="fw-bolder" htmlFor="exampleCheck1">
                            Submitted Articles by{" "}
                            {user?.result?._id && (
                              <>
                                {user.result.title}{" "}
                                {user.result.firstName
                                  ? user.result.firstName
                                      .charAt(0)
                                      .toUpperCase() +
                                    user.result.firstName.slice(1)
                                  : ""}{" "}
                                {user.result.middleName
                                  ? user.result.middleName
                                      .charAt(0)
                                      .toUpperCase() +
                                    user.result.middleName.slice(1)
                                  : ""}{" "}
                                {user.result.lastName
                                  ? user.result.lastName
                                      .charAt(0)
                                      .toUpperCase() +
                                    user.result.lastName.slice(1)
                                  : ""}
                              </>
                            )}
                            {/* {userTours.length > 0 && <>{user?.result?.name}</>} */}
                          </label>
                          <div>
                            <hr />
                          </div>
                          {unapprovedArticles
                            .filter((item) => item.approved === false)
                            .map((item) => (
                              <div className="media" key={item._id}>
                                <div className="media-body">
                                  <h2>
                                    <Link to={`/article/${item.articleId}`}>
                                      {item.title && item.title.slice(0, 20)}
                                      {"..."}{" "}
                                    </Link>
                                    <i onClick={() => handleDelete(item._id)}>
                                      <span
                                        className="pi pi-trash"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        style={{
                                          display: "inline-block",
                                          border: "1px solid #F74B02",
                                          borderRadius: "10%",
                                          padding: "2px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      ></span>
                                    </i>{" "}
                                    <Link to={`/editarticle/${item._id}`}>
                                      <span
                                        className="pi pi-file-edit"
                                        data-bs-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        style={{
                                          display: "inline-block",
                                          border: "1px solid #F74B02",
                                          borderRadius: "10%",
                                          padding: "2px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                      </span>
                                    </Link>
                                  </h2>
                                  <span
                                    className="pi pi-tags"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Keywords:{" "}
                                  </span>
                                  {item.tags && item.tags.slice(0, 3)}
                                  {"....."}
                                  <br />
                                  <span
                                    className="pi pi-info"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Article Id:{" "}
                                  </span>
                                  {item.articleId}
                                  <Col md={8}>
                                    <label
                                      htmlFor="title"
                                      className="col-sm-15 col-form-label"
                                      style={{ color: "#067ADC" }}
                                    >
                                      <span
                                        className="pi pi-calendar"
                                        style={{ color: "#F74B02" }}
                                      >
                                        {" "}
                                      </span>{" "}
                                      {"  "}
                                      Submitted on:{" "}
                                    </label>

                                    <span style={{ color: "#" }}>
                                      {` ${new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }).format(new Date(item.createdAt))}`}
                                    </span>
                                  </Col>
                                  <span
                                    className="pi pi-dollar"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Payment Status:{" "}
                                  </span>
                                  {item.paymentId &&
                                  item.payerId &&
                                  item.token ? (
                                    <span style={{ color: "green" }}>
                                      Payment is Success. <br />
                                      <span style={{ color: "#067ADC" }}>
                                        Payment ID:{" "}
                                      </span>
                                      <span style={{ color: "green" }}>
                                        {item.paymentId}
                                      </span>
                                      <br />
                                      <span style={{ color: "#067ADC" }}>
                                        Payer ID:{" "}
                                      </span>
                                      <span style={{ color: "green" }}>
                                        {item.payerId}
                                      </span>
                                      <br />
                                      <span style={{ color: "#067ADC" }}>
                                        Token:{" "}
                                      </span>
                                      <span style={{ color: "green" }}>
                                        {item.token}
                                      </span>
                                    </span>
                                  ) : (
                                    <span style={{ color: "#F74B02" }}>
                                      Pending <br />
                                      Payment ID: N/A <br />
                                      Payer ID: N/A <br />
                                      Token: N/A
                                    </span>
                                  )}
                                  {/* {item.paymentId ? (
                                    <div>
                                      {item.paymentId && (
                                        <span style={{ color: "green" }}>
                                          Payment is Success. {item.paymentId}
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <span style={{ color: "#F74B02" }}>
                                        Pending failed
                                      </span>
                                      <button
                                        onClick={() => {}} // Assuming handlePayNow is your function to handle payment
                                        style={{
                                          backgroundColor: "#F74B02",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          cursor: "pointer",
                                          marginLeft: "10px", // Adjust margin as needed
                                        }}
                                      >
                                        Pay Now
                                      </button>
                                    </div>
                                  )} */}
                                  <br />
                                  <hr />
                                </div>
                              </div>
                            ))}

                          {/* Pagination */}
                          <ul className="pagination">
                            {/* Previous page button */}
                            <li
                              className={`page-item ${
                                unapproved === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handleUnapprovedChange(unapproved - 1)
                                }
                              >
                                Previous
                              </button>
                            </li>

                            {/* First page button */}
                            {UnapprovedstartPage > 1 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => handleUnapprovedChange(1)}
                                >
                                  1
                                </button>
                              </li>
                            )}

                            {/* "..." if not starting from the first page */}
                            {UnapprovedstartPage > 2 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Page numbers */}
                            {UnapprovedpageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={`page-item ${
                                  unapproved === pageNumber ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handleUnapprovedChange(pageNumber)
                                  }
                                >
                                  {pageNumber}
                                </button>
                              </li>
                            ))}

                            {/* "..." if not ending at the last page */}
                            {UnapprovedendPage < totalUnapproved - 1 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Last page button */}
                            {UnapprovedendPage < totalUnapproved && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handleUnapprovedChange(totalUnapproved)
                                  }
                                >
                                  {totalReviewPages}
                                </button>
                              </li>
                            )}

                            {/* Next page button */}
                            <li
                              className={`page-item ${
                                unapproved === totalUnapproved ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handleUnapprovedChange(unapproved + 1)
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="v-pills-articles-approved"
                          role="tabpanel"
                          aria-labelledby="v-pills-articles-approved-tab"
                          tabIndex="1"
                        >
                          <label className="fw-bolder" htmlFor="exampleCheck1">
                            Approved Articles{" "}
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              {" "}
                              {totalapprovedCount}
                            </span>
                          </label>
                          <div>
                            <hr />
                          </div>

                          {approvedArticles
                            .filter((item) => item.approved === true)
                            .map((item) => (
                              <div className="media" key={item._id}>
                                <div className="media-body">
                                  <h2>
                                    <Link to={`/article/${item.articleId}`}>
                                      {item.title && item.title.slice(0, 35)}
                                      {"....."}
                                    </Link>
                                  </h2>
                                  {/* {item.articleinfo[0].content && item.articleinfo[0].content.slice(0, 20)} */}
                                  {item.articleinfo[0].content &&
                                  typeof item.articleinfo[0].content ===
                                    "string" ? (
                                    <span
                                      className="fst-normal"
                                      style={{ color: "#000000" }}
                                    >
                                      {stripTags(
                                        item.articleinfo[0].content &&
                                          item.articleinfo[0].content.slice(
                                            0,
                                            150
                                          )
                                      )}
                                    </span>
                                  ) : (
                                    <p className="fst-normal">
                                      Content not available
                                    </p>
                                  )}
                                  <br />
                                  <span
                                    className="pi pi-tags"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Keywords:{" "}
                                  </span>
                                  {item.tags && item.tags.slice(0, 3)}
                                  {"....."}
                                  <br />
                                  <span
                                    className="pi pi-info"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Article Id:{" "}
                                  </span>
                                  {item.articleId}
                                  <br />
                                  <Row>
                                    <Col md={6}>
                                      <span
                                        className="pi pi-calendar"
                                        style={{ color: "#F74B02" }}
                                      >
                                        {" "}
                                      </span>{" "}
                                      {"  "}
                                      <span style={{ color: "#067ADC" }}>
                                        Submitted on:{" "}
                                      </span>
                                      <span style={{ color: "#000000" }}>
                                        {` ${new Intl.DateTimeFormat("en-US", {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                        }).format(new Date(item.createdAt))}`}
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      <span
                                        className="pi pi-calendar"
                                        style={{ color: "#F74B02" }}
                                      >
                                        {" "}
                                      </span>{" "}
                                      {"  "}
                                      <span style={{ color: "#067ADC" }}>
                                        Approved on:{"   "}
                                      </span>
                                      <span style={{ color: "#000000" }}>
                                        {` ${new Intl.DateTimeFormat("en-US", {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                        }).format(new Date(item.publishedAt))}`}
                                      </span>
                                      <br />
                                    </Col>
                                  </Row>
                                  <span
                                    className="pi pi-dollar"
                                    style={{ color: "#F74B02" }}
                                  >
                                    {" "}
                                  </span>{" "}
                                  {"  "}
                                  <span style={{ color: "#067ADC" }}>
                                    Payment Status:{" "}
                                  </span>
                                  {item.paymentId ? (
                                    <span style={{ color: "green" }}>
                                      Payment is Success. {item.paymentId}
                                    </span>
                                  ) : (
                                    <span style={{ color: "#F74B02" }}>
                                      Pending Pending
                                    </span>
                                  )}
                                  <i onClick={() => handleDelete(item._id)}>
                                    {/* ... */}
                                  </i>
                                  <Link to={`/editarticle/${item._id}`}>
                                    {/* ... */}
                                  </Link>
                                  <hr />
                                </div>
                              </div>
                            ))}

                          {/* Pagination */}
                          <ul className="pagination">
                            {/* Previous page button */}
                            <li
                              className={`page-item ${
                                approved === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handleapprovedChange(approved - 1)
                                }
                              >
                                Previous
                              </button>
                            </li>

                            {/* First page button */}
                            {approvedstartPage > 1 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => handleapprovedChange(1)}
                                >
                                  1
                                </button>
                              </li>
                            )}

                            {/* "..." if not starting from the first page */}
                            {approvedstartPage > 2 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Page numbers */}
                            {approvedpageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={`page-item ${
                                  approved === pageNumber ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handleapprovedChange(pageNumber)
                                  }
                                >
                                  {pageNumber}
                                </button>
                              </li>
                            ))}

                            {/* "..." if not ending at the last page */}
                            {approvedendPage < totalapproved - 1 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Last page button */}
                            {approvedendPage < totalapproved && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handleapprovedChange(totalapproved)
                                  }
                                >
                                  {totalReviewPages}
                                </button>
                              </li>
                            )}

                            {/* Next page button */}
                            <li
                              className={`page-item ${
                                approved === totalapproved ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handleapprovedChange(approved + 1)
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="v-pills-reviews"
                          role="tabpanel"
                          aria-labelledby="v-pills-reviews-tab"
                          tabIndex="2"
                        >
                          {currentReviews.map((review) => (
                            <div key={review._id}>
                              <Link
                                to={`/ViewReview/${review._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {review.title}
                              </Link>
                              <p>
                                Reviewed on the Article:{" "}
                                <Link to={`/article/${review.artid}`}>
                                  {review.articletitle.length > 100
                                    ? `${review.articletitle.slice(
                                        0,
                                        150
                                      )}.....`
                                    : review.articletitle}
                                </Link>
                              </p>
                              <hr />

                              {/* ... */}
                            </div>
                          ))}

                          {/* Pagination */}
                          <ul className="pagination">
                            {/* Previous page button */}
                            <li
                              className={`page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                              >
                                Previous
                              </button>
                            </li>

                            {/* First page button */}
                            {startPage > 1 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => handlePageChange(1)}
                                >
                                  1
                                </button>
                              </li>
                            )}

                            {/* "..." if not starting from the first page */}
                            {startPage > 2 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Page numbers */}
                            {pageNumbers.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={`page-item ${
                                  currentPage === pageNumber ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => handlePageChange(pageNumber)}
                                >
                                  {pageNumber}
                                </button>
                              </li>
                            ))}

                            {/* "..." if not ending at the last page */}
                            {endPage < totalReviewPages - 1 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Last page button */}
                            {endPage < totalReviewPages && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChange(totalReviewPages)
                                  }
                                >
                                  {totalReviewPages}
                                </button>
                              </li>
                            )}

                            {/* Next page button */}
                            <li
                              className={`page-item ${
                                currentPage === totalReviewPages
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-myarticlereviews"
                          role="tabpanel"
                          aria-labelledby="v-pills-myarticlereviews-tab"
                          tabIndex="3"
                        >
                          {currentReviewsonmyarticle.map((review) => (
                            <div key={review._id}>
                              <Link
                                to={`/ViewReview/${review._id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {review.title}
                              </Link>
                              <p>
                                Review posted on my Articles:{" "}
                                <Link to={`/article/${review.artid}`}>
                                  {review.articletitle.length > 100
                                    ? `${review.articletitle.slice(
                                        0,
                                        150
                                      )}.....`
                                    : review.articletitle}
                                </Link>
                              </p>
                              <hr />

                              {/* ... */}
                            </div>
                          ))}

                          {/* Pagination */}
                          <ul className="pagination">
                            {/* Previous page button */}
                            <li
                              className={`page-item ${
                                currentPagemyarticle === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChangemyarticle(
                                    currentPagemyarticle - 1
                                  )
                                }
                              >
                                Previous
                              </button>
                            </li>

                            {/* First page button */}
                            {startPages > 1 && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => handlePageChangemyarticle(1)}
                                >
                                  1
                                </button>
                              </li>
                            )}

                            {/* "..." if not starting from the first page */}
                            {startPages > 2 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Page numbers */}
                            {pageNumbersmyarticle.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={`page-item ${
                                  currentPagemyarticle === pageNumber
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChangemyarticle(pageNumber)
                                  }
                                >
                                  {pageNumber}
                                </button>
                              </li>
                            ))}

                            {/* "..." if not ending at the last page */}
                            {endPages < totalReviewPagesmyarticle - 1 && (
                              <li className="page-item disabled">
                                <span className="page-link">...</span>
                              </li>
                            )}

                            {/* Last page button */}
                            {endPages < totalReviewPagesmyarticle && (
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    handlePageChangemyarticle(
                                      totalReviewPagesmyarticle
                                    )
                                  }
                                >
                                  {totalReviewPagesmyarticle}
                                </button>
                              </li>
                            )}

                            {/* Next page button */}
                            <li
                              className={`page-item ${
                                currentPagemyarticle ===
                                totalReviewPagesmyarticle
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChangemyarticle(
                                    currentPagemyarticle + 1
                                  )
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default Dashboard;
