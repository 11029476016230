import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { getToursWithCounts } from "../redux/features/tourSlice";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Oval as Loader } from "react-loader-spinner";

const Categories = () => {
  const { categoriesWithTours, error } = useSelector((state) => state.tour);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getToursWithCounts());
  }, [dispatch]);

  const handleCategoryClick = (category) => {
    navigate(`/categories/${category.name}`, { state: { tours: category.tours } });
  };


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            List of Categories
          </div>
        </p>


        <div className="row">
          {categoriesWithTours.length === 0 ? (
            <div>
            Please wait.. while we are trying to fetch the categories list...
            <Loader />
            </div>
          ) : (
            categoriesWithTours.map((category) => {

              return (
                <div key={category._id} className="col-2 mb-3">
                  {category.count > 0 ? (
                    <span
                    onClick={() => handleCategoryClick(category)}
                    style={{ cursor: 'pointer', color: '#067ADC' }}
                  >
                      {category.name}
                      <span style={{ color: "#067ADC" }}>
                        {"  "}
                        ({category.count})
                      </span>
                      </span>
                  ) : (
                    <span>
                      {category.name}
                      <span style={{ color: "#F74B02" }}>
                        {"  "}
                        ({category.count})
                      </span>
                    </span>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Categories;
