import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const TermsandConditions = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Terms and Conditions
          </div>
        </p>

        <p>
          The domains www.webmedcentral.com, www.webmedcentral.co.uk and
          www.webmedcentralplus.com are owned by Webmed Limited registered at 35
          Rosemount, DH1 5GA, Durham, United Kingdom. The pronouns "you" and
          "your" are used to refer to the end user, while "we," "us", "our"
          “company”, www.webmedcentral.com www.webmedcentral.co.uk ,
          www.webmedcentralplus.com (also known as plus) and “WebmedCentral” are
          used to refer to Webmed limited.{" "}
        </p>

        <p>
          In use of WebmedCentral you agree to following terms and conditions:
        </p>
        <p>
          1. That you have read understood and agree to abide by our privacy
          policy, copyright policy, advertising policy, terms and conditions,
          and disclaimers.
        </p>
        <p>
          2. We have a dual publication model. www.webmedCentralplus.com uses
          the traditional pre-publication peer review model.{" "}
        </p>
        <p>
          3.The contents posted on www.webmedcentral.com and
          www.webmedcentral.co.uk portal do not undergo any prepublication
          checks or peer review and hence it is completely the responsibility of
          the person posting the information to ensure not only scientific and
          ethical standards but also check it for grammatical and typing errors.
        </p>
        <p>
          4. All our Authors (on any website) must ensure that they obtain all
          the necessary permissions before submitting any information that
          requires obtaining a consent or approval from a third party. Authors
          should also ensure not to submit any information on this portal which
          they do not have the copyright of or of which they have transferred
          the copyrights to a third party. Authors should try to present any
          information in a completely unbiased way and must disclose any source
          of funding and competing interests. They must not submit anything to
          us that is illegal or can hurt sentiments of people belonging to a
          particular continent, nationality, race, religion, group, sex or
          affiliation. Any communication that you post on our websites is
          considered to be non-confidential. You agree that you will not upload
          or transmit any communications that infringe any patent, trademark,
          trade secret, copyright or other proprietary rights of any third
          party.
        </p>
        <p>
          You undertake that you will not use any of our websites for any
          purpose in violation of local, state, national, or international laws.
          You agree not to post material that infringes on the intellectual
          property rights of others or on the privacy or publicity rights of
          others. You will not post material that is unlawful, obscene,
          defamatory, threatening, harassing, abusive, hateful, false claiming,
          non patriotic, or embarrassing to another user of the website or any
          other person or entity as determined by WebmedCentral at its sole
          discretion. You will not post advertisements or solicitations of
          business. You will not post chain letters or pyramid schemes or will
          not impersonate another person. You will not allow any other person or
          entity to use your identification to post or view comments. You will
          not post the same note more than once. You will not engage in any
          other conduct that restricts or inhibits any other person from using
          any of our websites, or which, in the judgment of Webmed Limited, UK,
          exposes any of its users or its officers, directors, employees,
          agents, licensors and suppliers to any liability or detriment of any
          type.
        </p>
        <p>
          5. We are fully aware that some people may try to misuse the “freedom
          of expression” and “complete transparency” that we intend to instill
          in scientific biomedical communications. We urge our readers to bring
          to our attention any such misuse of our portal. Please let us know if
          you come across any information on this website that is improper,
          scientifically wrong or factually incorrect. We also request our
          readers to point out any discrepancies in the articles posted on
          WebmedCentral.
        </p>
        <p>
          We reserve the right to ask authors or reviewers or commentators to
          explain, modify, or delete any section or the whole of an article or
          comment published on WebmedCentral. We may disallow the publication of
          an article or a review/comment, ask authors to submit a revision, or
          completely remove the objectionable material in part or whole without
          giving any further explanation. The decision of Webmed Limited shall
          be final in this regard and no further dispute shall be entertained.
        </p>
        <p>
          6. We do not ask authors to transfer the copyrights of the information
          to us and they are hence free to submit the article elsewhere if they
          so choose after publishing it on our portal. It is the authors’
          responsibility to ensure that they have the copyrights of the article
          or information they are submitting on any of our websites. Please read
          our detailed document on copyrights for our copyright policy.
        </p>
        <p>
          7. Advertisements and Third party links: We will carry advertisement
          features and links to third party websites from time to time on this
          website, in the pdf generated in our website and in any print format.
          These links are provided solely as a convenience to you and when
          advertisements are clearly so identified. It is not meant to be an
          endorsement by Webmed Limited, UK of the content on such third-party
          websites. Unless otherwise specifically stated, Webmed Limited, UK
          does not endorse any product or make any representation regarding the
          content or accuracy of any materials contained in, or linked to, any
          advertisement on its websites. Webmed Limited, UK does not own
          responsibility for the content of linked third-party sites and does
          not make any representations regarding the content or accuracy of
          materials on such third-party websites. Users click on these
          third-party websites or advertisement features at their own risk.
          Subsequent use of these third party websites and advertisement
          features would be directed by their own policies and terms and
          conditions. Webmed Limited, UK or any of its users, or its officers,
          directors, employees, agents, licensors and suppliers does not take
          any responsibility for any harm that may result from the use of
          contents on these third party websites.
        </p>
        <p>
          8. Jurisdiction: The domains www.webmedcentral.com,
          www.webmedcentral.co.uk and www.webmedcentralplus.com(also known as
          plus) and all the contents on this portal are owned by Webmed Limited,
          registered at 2 Matlock Avenue, Newcastle Upon Tyne NE3 3GL, United
          Kingdom. The contents can however be accessed from or downloaded
          anywhere in the world and at the same time authors from around the
          world are encouraged to post their articles and communications on our
          portal. The laws of England and Wales govern your conditions of use
          and you agree that the English courts shall have exclusive
          jurisdiction in any dispute.
        </p>
        <p>
          9. WebmedCentral portals www.webmedcentral.com and
          www.webmedcentral.co.uk provides exceptional freedom of expression to
          biomedical scientists and contents do not undergo any editorial or
          peer review prior to publication. People posting the contents are
          solely responsible for their communications and the consequences of
          posting those communications. Webmed Limited does not assume any
          responsibility for the consequences of any communications on or
          arising from use of its websites. In cases where you feel threatened
          or believe someone else is in danger, you should contact us so that we
          can remove such contents from the portal and at the same time please
          warn your local law enforcement agency immediately.
        </p>
        <p>
          10. Our portals www.webmedcentral.com and www.webmedcentral.co.uk do
          not screen communications in advance and is not responsible for
          screening or monitoring material posted by author, or reviewers or any
          other person or entity. If notified by a user of a communication
          (article or review or comment) which does not conform to the terms and
          conditions of the use of this portal, we will investigate the
          allegation and determine, in our sole discretion, whether to remove or
          request the removal of such communication. Webmed Limited reserves the
          right to remove communications, which are abusive, illegal,
          disruptive, or that otherwise fail to conform to these Terms and
          Conditions. In addition to the removal rights described above, we
          reserve the right to terminate a user's access to any or all areas on
          the website upon user's breach of these Terms and Conditions. Webmed
          Limited reserves the right (but is not obligated) to edit or delete
          any communication(s) posted on website regardless of whether such
          communication(s) violate these standards for content.
        </p>
        <p>
          Furthermore Webmed Limited reserves the right (but is not obligated)
          to monitor, edit, or disclose any type of posting on the Interactive
          Areas for adherence to the Terms and Conditions, if required in the
          course of normal operation and maintenance of www.webmedcentral.com
          www.webmedcentral.co.uk and www.webmedcentralplus.com, or if required
          to do so by law or in the good-faith belief that such action is
          necessary to: (i) comply with the law or comply with legal process
          served; (ii) protect and defend the rights of www.webmedcentral.com or
          others; or (iii) act in an emergency to protect the personal safety of
          our users or the public.
        </p>
        <p>
          Webmed Limited has no liability or responsibility to members or any
          other person or entity for performance or non performance of the
          aforementioned screening activities.
        </p>
        <p>
          Webmed Limited does not represent or guarantee the truthfulness,
          accuracy, or reliability of any of the material posted on the web site
          or endorse any opinions expressed by such users. Any reliance on
          content posted on the website is entirely at the risk of the user.
        </p>
        <p>
          11. Indemnity: You agree to defend, indemnify and hold Webmed Limited,
          its officers, directors, employees, agents, licensors and suppliers,
          harmless from and against any claims, actions or demands, liabilities
          and settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your use
          of the content or the interactive areas in a manner that violates or
          is alleged to violate these Terms and Conditions. Webmed Limited shall
          provide notice to you promptly of any such claim, suit, or proceeding
          and shall reasonably cooperate with you, at your expense, in your
          defence of any such claim, suit or proceeding.
        </p>
        <p>
          12. Webmed Limited may impose reasonable rules and regulations
          regarding the use of its services from time to time to the extent
          necessary to ensure compliance.
        </p>
        <p>
          Minors should be supervised when using the Internet. Adults as well as
          minors should be aware of what is on the Internet and to have the
          concept of what is accepted and what should be avoided. The following
          are unacceptable uses of the contents on our websites .
        </p>
        <p>
          • Abuse of system and network resources: including but not limited to
          employing posts or programs which consume excessive CPU time or
          storage space; permitting use of mail services, mail forwarding
          capabilities, POP accounts, or auto responders other than your own
          account; or resale of access to CGI scripts installed on our servers.
        </p>
        <p>
          • Improper Conduct: Including but not limited to posting defamatory,
          scandalous or private information about an individual without his or
          her consent, intentionally inflicting emotional distress, or violating
          trademarks, copyrights, or other intellectual property rights.
        </p>
        <p>
          • Illegality: In any form, including but not limited to activities
          such as unauthorized distribution or copying of copyrighted software,
          violation of export restrictions, harassment, fraud, trafficking in
          obscene material, drug dealing, and other illegal activities.
        </p>
        <p>
          • Abuse of the Internet: Including but not limited to activities such
          as using a non-existent email return address on a commercial
          solicitation, spamming (sending unsolicited advertising to numerous
          email addresses or newsgroups and/or generating a significantly higher
          volume of outgoing email than a normal user), trolling (posting
          outrageous messages to generate numerous responses), mail bombing
          (sending multiple messages without significant new content to the same
          user), subscribing someone else to a mailing list without that
          person's permission, cross-posting articles to an excessive number of
          newsgroups, or attempting without authorization to enter into a
          secured computer system. Webmed limited reserves the right to
          determine what constitutes net abuse.
        </p>
        <p>
          13. Complete Agreement: Except as expressly provided in a particular
          "legal notice" on Webmed Limited, these terms and conditions
          constitute the entire agreement between you and Webmed limited, with
          respect to the use of any of our websites, print publications, and any
          software and content contained therein. By visiting any of our
          websites, or by using one of its services, you are accepting the
          practices described in this statement. We may amend this statement at
          any time. We will post those changes on this page. Any questions
          regarding this statement should be directed to Webmed limited. You can
          use our contact us option on the websites for further clarification.
        </p>
        <p>
          Thank you for your cooperation with these Terms and Conditions. We
          hope you find our websites helpful and convenient to use.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsandConditions;
