import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import whyvideo from "../components/img/Video 1_ WebmedCentral - Why_.mp4";
import Footer from "./Footer";

const Aboutus = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
        <div className="container-fluid card">
          <br />
          <div className="row">
            <div className="col-xl-5 col-lg-6 d-flex justify-content-center align-items-stretch position-relative">
              <video controls width="500" height="400">
                <source src={whyvideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5 card">
              <h3>About us</h3>
              <p>
                Webmed Limited, UK is owned and managed by Kamal Mahawar
                (Consultant General Surgeon in UK), Deepak Kejariwal (Consultant
                Gastroenterologist in UK), and Manish Jain (Management
                Consultant in UK). We are an independent group with no links to
                the existing players in pharmaceutical, publishing or any other
                industry.
              </p>
              <p>
                We believe that biomedical publishing has not been able to keep
                pace with advancing technology. Biomedical publishing needs to
                evolve to empower authors, reviewers and readers. WebmedCentral
                publishes all articles without prior peer review in a climate of
                post publication peer review led by authors but supported by our
                team of advisors and editors..
              </p>
              <p>
                <NavLink to="/peerreviewpolicy" className="" target="_blank">
                  {" "}
                  Our Peer Review Policy
                </NavLink>{" "}
                and Our
                <NavLink to="/publicationpolicy" className="" target="_blank">
                  {" "}
                  Publication Policy
                </NavLink>{" "}
                for further details..
              </p>
            </div>
          </div>
        </div>
       
      </section>
      <Footer />
    </>
  );
};

export default Aboutus;
