import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const CopyrightPolicy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
   
      <div className="card">
      

        <p style={{ fontWeight: "bold" }}>
          <div className="card-header" style={{ color: '#067ADC', fontSize: '16px' }}>Copyright Policy</div>
      
        </p>

        <p>
          The domains www.webmedcentral.com, www.webmedcentral.co.uk and
          www.webmedcentralplus.com are owned by Webmed limited, registered at
          35 Rosemount,Durham, DH1 5GA United Kingdom. The pronouns "you" and
          "your" are used to refer to the end user, while "we," "us", "our"
          “company”, www.webmedcentral.com, www.webmedcentral.co.uk and
          www.webmedcentralplus.com (also known as plus) and “WebmedCentral” are
          used to refer to Webmed limited.{" "}
        </p>

        <p style={{ fontWeight: "bold" }}> For Authors:</p>
        <p>
          In submitting any article/review/comment on any of our websites, the
          submitting person certifies that s/he is either the sole author of the
          article/review/comment or has the authority from all other co-authors
          to submit the article/review/comment on our portal. Alternatively the
          submitting person may have the copyright of the article/review/comment
          transferred to them by the authors. In such cases, please submit the
          copyright transfer document to us. Please upload the document when
          asked to do so during the article submission process. It is solely the
          responsibility of the person submitting article/review comment to
          ensure that no third party copyrights are violated.
        </p>
        <p>
          The content (article/review/comment) may have been published
          elsewhere. You can submit any article/review to us, as long as it does
          not infringe any existing copyright or any other third party rights.
          The submitting person should have the full authority to enter into
          this agreement and, in granting rights to Webmed limited, should not
          be in breach of any other obligation or third party rights. Webmed
          Limited will reserve the right to use the content submitted on any of
          its websites or in any future print form. Webmed Limited also reserves
          the rights to enter into any commercial agreement for the versions of
          the contents published on its websites or in any future print form. We
          will reserve the right to sell reprints of articles and reviews
          published with us to third parties for our commercial purposes. The
          article/review/comment must not contain anything unlawful, libellous,
          or which would, if published, constitute a breach of contract or of
          confidence or of commitment given to secrecy.
        </p>
        <p>
          Authors or the copyright holder own complete responsibility to ensure
          scientific and ethical standard that is expected of every manuscript
          submitted to us. They should also be able to give an undertaking that
          to the best of their knowledge all statements contained in it
          purporting to be facts are true and any formula or instruction
          contained in the article/review/comment will not, if followed
          accurately, cause any injury, illness or damage to the user. We at
          WebmedCentral take no responsibility for the accuracy, completeness,
          or usefulness of any information provided on our websites, nor shall
          we be liable for any direct, indirect, incidental, special,
          consequential or punitive damages arising out of their use.
        </p>
        <p>
          We do not require authors of journal articles/reviews/comments to
          assign us their copyright. Authors are free to reproduce and publish
          the work elsewhere in the future under the terms of this {" "}
          <NavLink
            to="http://creativecommons.org/licenses/by/3.0/"
            target="_blank"
          >
            Creative Commons License
          </NavLink>
          . Under this license, authors retain ownership of the copyright for
          their content, but allow anyone to download, reuse, reprint, modify,
          distribute, and/or copy the content as long as the original authors
          and source are cited. No permission is required from the authors or
          the publishers for these purposes. For any reuse or redistribution of
          a work, users must also make clear the license terms under which the
          work was published.
        </p>
        <p>
          This broad license was developed to facilitate free access to, and
          unrestricted reuse of, original works of all types. Applying this
          standard license to your own work will ensure that it is freely and
          openly available in perpetuity.
        </p>
        <p>
          Appropriate attribution can be provided by simply citing the original
          article.
        </p>
        <p>
          WebmedCentral allows all published research articles to be placed in
          any digital archive immediately on publication. Authors are free to
          archive articles themselves. In this regard, WebmedCentral perpetually
          authorises LOCKSS members to archive and restore all publications on
          our portal through the LOCKSS system.
        </p>
        <p style={{ fontWeight: "bold" }}>For Users:</p>
        <p>
          WebmedCentral authorises you to search, retrieve, save, download and
          print out copies of articles or other items from our portal. You are
          free to use these for research, presentation purposes, or teaching
          purposes or any other purpose under the terms of this{" "}
          <NavLink
            to="http://creativecommons.org/licenses/by/3.0/"
            target="_blank"
          >
            Creative Commons License
          </NavLink>
          . By visiting WebmedCentral or any related sites, or by using one of
          its services, you are accepting the practices described in this
          Copyright Statement. We may amend this statement at any time. If we
          make changes to our copyright policy, we will post those changes on
          this page so that you are always aware of our latest policies.
        </p>
        <p>
          Any questions regarding our Copyright Policy Statement should be
          directed to Webmed limited. You can contact us, if you have any
          questions or concerns about this policy.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default CopyrightPolicy;
