import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Allarticles.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTours } from "../redux/features/tourSlice";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";

const SearchbyType = () => {
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tour.tours);

  const [selectedArticleType, setSelectedArticleType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Display 5 articles per page

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
    dispatch(getTours());
  }, [dispatch]);

  const articleTypeCounts = {};
  tours.forEach((article) => {
    const { articletype } = article;
    if (articletype in articleTypeCounts) {
      articleTypeCounts[articletype]++;
    } else {
      articleTypeCounts[articletype] = 1;
    }
  });

  const handleArticleTypeClick = (articletype) => {
    setSelectedArticleType(articletype);
    setCurrentPage(1); // Reset current page when changing article type
  };

  const filteredTours = selectedArticleType
    ? tours.filter((article) => article.articletype === selectedArticleType)
    : tours;

  const currentItems = filteredTours.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalItemCount = filteredTours.length;

  const pageCount = Math.ceil(totalItemCount / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-result-box card-box">
                <hr />

                {/* Display article type filters */}
                <div className="row">
                  <div className="col-md-12">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <br />
                      Article type:
                      {Object.entries(articleTypeCounts).map(
                        ([articletype, count]) => (
                          <div
                            key={articletype}
                            style={{
                              margin: "8px",
                              padding: "5px",
                              border: "1px solid #ccc",
                              cursor: "pointer",
                              background:
                                selectedArticleType === articletype
                                  ? "#f0f0f0"
                                  : "none",
                            }}
                            onClick={() =>
                              handleArticleTypeClick(
                                selectedArticleType === articletype
                                  ? null
                                  : articletype
                              )
                            }
                          >
                            {`${articletype} (${count})`}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <hr />

                {/* Display article type results */}
                <div className="search-item">
                  {currentItems.map((article) => (
                    <div key={article._id}>
                      <h4 className="mb-1">
                        <Link to={`/article/${article._id}`}>
                          {article.title}
                        </Link>
                      </h4>
                      <p>Tags: {article.tags.join(", ")}</p>
                      <div className="font-13 text-success mb-3">
                        Submitted by {article.usertitle} {article.name}
                      </div>
                      <div style={{ color: "#000000" }}>
                        {article.articleinfo[0].content}
                      </div>
                      <br />
                      <hr />
                    </div>
                  ))}

                  {pageCount > 1 && (
                    <div className="col-md-12">
                      <ul className="pagination justify-content-end pagination-split mt-0">
                        {Array.from({ length: pageCount }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => paginate(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="clearfix" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* container */}
      </div>

      <Footer />
    </>
  );
};

export default SearchbyType;
