import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import * as api from "../api";

export const showUser = createAsyncThunk(
  "user/showUser",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await api.showUser(data, id);
      console.log("dsdsdsds22", response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



// export const verifyUser = createAsyncThunk(
//   "user/verify",
//   async ({ id, token }, { rejectWithValue }) => {
//     try {
//       const response = await axios.get(
//         `http://localhost:3001/users/${id}/verify/${token}`
//       );
//       return response.data.message;
//     } catch (error) {
//       return rejectWithValue("Error verifying email");
//     }
//   }
// );


export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async ({id, token, data}, { rejectWithValue }) => {
    try {
      const response = await api.verifyEmail(id, token, data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue("Error verifying email");
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.fetchAllUsers(); // Use your actual fetch function
      return response.data;
    } catch (error) {
      return rejectWithValue("Error fetching all users");
    }
  }
);



const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [], 
    error: "",
    loading: false,
    verified: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showUser.pending, (state) => {
        state.loading = true;
      })

      // .addCase(showUser.fulfilled, (state, action) => {
      //   state.loading = false;
      //   localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
      //   state.users = action.payload;
      // })


      .addCase(showUser.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("profile", JSON.stringify({ ...action.payload }));
        state.users = Array.isArray(action.payload) ? action.payload : [];
      })
      



      .addCase(showUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.verified = action.payload === "Email verified successfully";
        // state.verified = true;
      })

      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.users = []; // Set users to an empty array on fetch failure
      });


  },
});

export default userSlice.reducer;
