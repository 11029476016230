import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showUser } from "../redux/features/userSlice";
import { fetchAllUsers } from "../redux/features/userSlice";
import { useParams } from "react-router-dom";
import profilepic from "./img/samplepic.jpg";
import { getAllTours } from "../redux/features/tourSlice";
import { fetchReviews } from "../redux/features/reviewSlice";
import { Link } from "react-router-dom";
import { Oval as Loader } from "react-loader-spinner";

import Navbar from "./Navbar";
import Footer from "./Footer";

const Editorsprofile = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const tours = useSelector((state) => state.tour.allTours);
  const reviews = useSelector((state) => state.review.reviews);
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingReviews, setLoadingReviews] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(showUser(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getAllTours()).then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchReviews()).then(() => setLoadingReviews(false));
  }, [dispatch]);

  const filterUser = users.filter(
    (user) =>
      user._id === id && user.editorstatus && user.editor && user.verified
  );

  const filterTour = tours.filter(
    (tour) => tour.creator === id && tour.approved === true
  );

  const filterReviews = reviews.filter((review) => review.userId === id);

  const totalReviewCount = reviews.filter(
    (review) => review.userId === id
  ).length;

  const totalapprovedarticles = tours.filter(
    (tour) => tour.creator === id && tour.approved === true
  ).length;

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <div className="card">
        {filterUser.length > 0 &&
          filterUser.map((user) => (
            <div key={user._id}>
              <section style={{ backgroundColor: "#eee" }}>
                <div className="card container py-5">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card mb-6">
                        <div className="card-body text-center">
                          <img
                            className="img-fluid"
                            src={user.profileImage || profilepic}
                            alt="profilepic"
                            style={{
                              height: "265px",
                              width: "265px",
                              objectFit: "cover",
                            }}
                          />
                          <br />
                          <br />

                          <span className="my-3">
                            {" "}
                            <h6>
                              {user.title}
                              {". "} {user.firstName}
                              {"  "}
                              {user.middleName}
                              {"  "}
                              {user.lastName}
                            </h6>
                          </span>
                          <span className="text-muted mb-1">
                            {user.designation}
                          </span>
                          <p className="text-muted mb-4">
                            {user.institute}, {user.country}
                          </p>
                          {/* <div className="d-flex justify-content-center mb-2">
                          <button type="button" className="btn btn-primary">Follow</button>
                          <button type="button" className="btn btn-outline-primary ms-1">Message</button>
                        </div> */}
                        </div>
                      </div>
                      {user.homepage && (
                      <div className="card mb-4 mb-lg-0">
                        <div className="card-body p-0">
                        <ul className="list-group list-group-flush rounded-3">
                              <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                <i className="fas fa-globe fa-lg text-warning" />
                                <div className="col-sm-11">
                                  <Link
                                    to={`${user.homepage}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {user.homepage}
                                  </Link>
                                </div>
                              </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    </div>
                    <div className="col-lg-8">
                      <div className="card mb-4">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Biography</p>
                            </div>
                            <div className="col-sm-9">
                              <p style={{ color: "#067ADC" }}>
                                {user.biography}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Keywords</p>
                            </div>
                            <div className="col-sm-9">
                              <p style={{ color: "#067ADC" }}>{user.keywords}</p>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Speciality of interest</p>
                            </div>

                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.category
                                  .filter(
                                    (category) => category.isChecked === true
                                  )
                                  .map((category) => category.name)
                                  .join(", ")}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Academic Positions</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.positions}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Research interests:</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.interests}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Recent publications</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.publications}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Place of work</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.place}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Supplementary Information</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.information}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Peer review experience</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.review}
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="mb-0">Additional Information</p>
                            </div>
                            <div className="col-sm-9">
                              <span style={{ color: "#067ADC" }}>
                                {user.other}
                              </span>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card mb-4 mb-md-0">
                            <div className="card-body">
                              <p className="mb-4">
                                <span
                                  className="card-header text-primary font-italic me-1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total Published Articles {"  "}
                                  <span
                                    className="fw-bolder"
                                    style={{
                                      display: "inline-block",
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "50%",
                                      background: "#067ADC",
                                      color: "#FFFFFF",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      lineHeight: "24px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    {totalapprovedarticles}
                                  </span>
                                </span>{" "}
                              </p>
                              {loading ? (
                                <p>
                                We are fetching the articles.. Kindly hold on ...
                                <br />
                                <Loader />
                                </p>
                                
                              ) : filterTour.length > 0 ? (
                                filterTour.slice(0, 5).map((tour) => (
                                  <div key={tour._id}>
                                    <Link
                                      to={`/article/${tour.articleId}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {tour.title.slice(0, 30)}
                                    </Link>
                                    <hr />
                                  </div>
                                ))
                              ) : (
                                <p>No artciles posted so far ...</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card mb-4 mb-md-0">
                            <div className="card-body">
                              <p className="mb-4">
                                <span
                                  className="card-header text-primary font-italic me-1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total Reviewed Articles{" "}
                                  <span
                                    className="fw-bolder"
                                    style={{
                                      display: "inline-block",
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "50%",
                                      background: "#067ADC",
                                      color: "#FFFFFF",
                                      fontWeight: "bold",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      lineHeight: "24px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    {totalReviewCount}
                                  </span>
                                </span>{" "}
                              </p>
                              {loadingReviews ? (
                                <p>Loading...</p>
                              ) : filterReviews.length > 0 ? (
                                filterReviews.slice(0, 5).map((review) => (
                                  <div key={review._id}>
                                    <Link
                                      to={`/ViewReview/${review._id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {review.title.slice(0, 30)}
                                    </Link>
                                    <hr />
                                  </div>
                                ))
                              ) : (
                                <p style={{ color: "#F74B02" }}>No reviews posted so far... We wish 
                                {"  "}"
                              {user.title}
                              {". "} {user.firstName}
                              {"  "}
                              {user.middleName}
                              {"  "}
                              {user.lastName}" {"  "}
                            will go through the articles and post his valuable reviews to help the medical community
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
      </div>

      <Footer />
    </>
  );
};

export default Editorsprofile;
