import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { fetchAllUsers } from "../redux/features/userSlice";
import "../CategoryArticles.css";

const CategoryArticles = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { categoryname } = useParams();
  const { tours } = location.state || { tours: [] };
  const users = useSelector((state) => state.user.users);

  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;
  const totalPages = Math.ceil(tours.length / articlesPerPage);
  const maxPageNumbersToShow = 5;

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  // Calculate the articles to display based on the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = tours.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pages = [];

    // Always show the first page
    pages.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={1 === currentPage ? "active" : ""}
      >
        1
      </button>
    );

    // Determine the range of page numbers to show around the current page
    const startPage = Math.max(
      2,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    const endPage = Math.min(
      totalPages - 1,
      startPage + maxPageNumbersToShow - 1
    );

    if (startPage > 2) {
      pages.push(<span key="ellipsis1">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages - 1) {
      pages.push(<span key="ellipsis2">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={totalPages === currentPage ? "active" : ""}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const stripTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <>
      <Navbar />
      <br /> <br /> <br /> <br /> <br />
      <div className="card mb-0">
        <p style={{ fontWeight: "bold" }}>
          <div className="card-header">
            {" "}
            Articles for Category:
            <span style={{ color: "#067ADC" }}> {categoryname}</span>
          </div>
        </p>

        {currentArticles.map((article) => (
          <div key={article._id}>
            <h4 className="mb-1">
              <Link to={`/article/${article.articleId}`}>
                {article.title.slice(0, 70)}
                {"....."}
              </Link>
            </h4>
            <p>
              Tags: {article.tags.join(", ").slice(0, 10)}
              {"....."}
            </p>
            <span style={{ color: "#067ADC" }}>
              <span className="pi pi-user" style={{ color: "#F74B02" }}>
                {" "}
              </span>{" "}
              {"    "}
              Submitted by :{" "}
            </span>{" "}
            {"  "}
            <span style={{ color: "#000000" }}>
              {users.map((user) => {
                if (user._id === article.creator) {
                  return `${user.title}. ${user.firstName} ${user.middleName}  ${user.lastName}`;
                }
                return null;
              })}
            </span>
            <Row>
              <Col md={5}>
                <label
                  htmlFor="title"
                  className="col-sm-3.5 col-form-label"
                  style={{ color: "#067ADC" }}
                >
                  <span className="pi pi-calendar" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {"  "}
                  Submitted on:{" "}
                </label>

                <span style={{ color: "#000000", marginLeft: "0px" }}>
                  {` ${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(article.createdAt))}`}
                </span>
              </Col>
              <Col md={5}>
                <label
                  htmlFor="title"
                  className="col-sm-3.5 col-form-label"
                  style={{ color: "#067ADC" }}
                >
                  <span className="pi pi-calendar" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {"  "}
                  Approved on:
                </label>

                <span style={{ color: "#000000", marginRight: "0px" }}>
                  {` ${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(article.publishedAt))}`}
                </span>
              </Col>
            </Row>
            <div style={{ color: "#000000" }}>
                    {article.articleinfo[0].content &&
                    typeof article.articleinfo[0].content === "string" ? (
                      <p className="fst-normal" style={{ color: "#000000" }}>
                        {stripTags(
                          article.articleinfo[0].content.slice(0, 150)
                        )}
                      </p>
                    ) : (
                      <p className="fst-normal">Content not available</p>
                    )}
                  </div>
            <hr />
          </div>
        ))}

        {/* Pagination Controls */}
        <div className="pagination">{renderPageNumbers()}</div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryArticles;
