import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../redux/features/userSlice";
import Navbar from "./Navbar";
import Footer from "./Footer";

const VerifyUser = () => {
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.auth.user);
  const { id, token } = useParams();

  useEffect(() => {
    dispatch(verifyEmail({ id, token }));
  }, [dispatch, id, token]);

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
   
 
   
      <section className="card mb-0 vh-100">
        <div className="container py-10 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-10 col-lg-5 col-xl-5 offset-xl-1 card mb-10">
              <h3 className="card-header" style={{ color: '#F74B02' }}>
              <span className="pi pi-verified" style={{ color: '#0ACFB2' }}> </span> {"   "}
                Email verified successfully !!! 
              </h3>
              <br />
              <p className="card-title text-center">
                 

                <NavLink
                to="/login"
                className="glightbox play-btn mb-4"
              > Please 
              
              <span style={{ color: '#F74B02' }}> login </span> {"   "}
              
               to access and start publishing your articles ...</NavLink> 

              </p>
              

              <div
                style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                className="container d-flex align-items-center justify-content-center vh-50"
              >
                
          
              </div>
            
            </div>
          </div>
        </div>
      </section>
   
      <Footer />
    </div>
  );
};

export default VerifyUser;
