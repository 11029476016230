import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { fetchReviews } from "../redux/features/reviewSlice";
import { getTour } from "../redux/features/tourSlice";
import { Button } from "react-bootstrap";
import { fetchAllUsers } from "../redux/features/userSlice";
import "../articleview.css";

const ViewReviews = () => {
  const { Id } = useParams();
  const { tour } = useSelector((state) => state.tour);
  const review = useSelector((state) => state.review);
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; // You can adjust this number as needed

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReviews());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Id) {
      dispatch(getTour(Id));
      // console.log("ii", Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  const reviewsForArticle = review.reviews.filter(
    (review) => review.artid === tour.articleId
  );

  const totalReviews = reviewsForArticle.length;
  // Calculate the indexes of the items to display based on pagination
  const indexOfLastReview = currentPage * itemsPerPage;
  const indexOfFirstReview = indexOfLastReview - itemsPerPage;
  const currentReviews = reviewsForArticle
    .reverse()
    .slice(indexOfFirstReview, indexOfLastReview);

  // const reviewCountForArticle = reviewsForArticle.length;
  // const sortedReviewsForArticle = reviewsForArticle.sort(
  //   (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
  // );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card title text-center">
        <Link
          to={`/article/${tour.articleId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <h4 className="mb-1" style={{ color: "#067ADC" }}>
            {tour.title}
          </h4>
        </Link>
        <span>
          Total{" "}
          <span
            style={{
              display: "inline-block",
              width: "24px", // Adjust the width and height as needed
              height: "24px",
              borderRadius: "50%",
              background: "#067ADC",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "24px",
              marginRight: "8px", // Add spacing if needed
            }}
          >
            {totalReviews}{" "}
          </span>
          reviews posted for the article.
          {"     "} {"     "}
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="ml-2"
          >
            Previous
          </Button>
          {"     "}
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={indexOfLastReview >= totalReviews}
            className="ml-2"
          >
            Next
          </Button>
        </span>
      </div>

      {/* <div className="row justify-content-center">
        <span className="card col-sm-12 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="ml-2"
            >
              Previous
            </Button>
            {"     "}
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={indexOfLastReview >= totalReviews}
              className="ml-2"
            >
              Next
            </Button>
          </div>
        </span>
      </div> */}

      {totalReviews === 0 ? (
        <p>No reviews posted for this article yet.</p>
      ) : (
        <div className="row col-sm-12">
          {currentReviews.map((review) => (
            <div key={review._id} className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h2>{review.title} </h2>
                  {users.map((user) => {
                    if (user._id === review.userId) {
                      return (
                        <span key={user._id} style={{ color: "#067ADC" }}>
                          <span
                            className="pi pi-user"
                            style={{ color: "#F74B02" }}
                          ></span>{" "}
                          Submitted by : {"     "}
                          <span style={{ color: "#000000" }}>
                            {review.anonymously === "yes"
                              ? "Anonymous user"
                              : `${user.title}. ${user.firstName} ${
                                  user.middleName ? user.middleName + " " : ""
                                }${user.lastName}`}
                          </span>
                          <br />
                          {"     "}
                          <span
                            className="pi pi-calendar"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>
                          {"     "}
                          <label htmlFor="title" style={{ color: "#067ADC" }}>
                            Posted on{"           "} :{"     "}{" "}
                          </label>
                          <span style={{ color: "#000000" }}>
                            {" "}
                            {"     "}
                            {`${new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }).format(new Date(review.createdAt))}`}
                          </span>
                        </span>
                      );
                    }
                    return null;
                  })}
                </div>

                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    What are the main claims of the paper and how important are
                    they?:
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.claims}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Are these claims novel? If not, please specify papers that
                    weaken the claims to the originality of this one.
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.novel}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Are the claims properly placed in the context of the
                    previous literature?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.properly}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Do the results support the claims? If not, what other
                    evidence is required?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.support}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    If a protocol is provided, for example for a randomized
                    controlled trial, are there any important deviations from
                    it? If so, have the authors explained adequately why the
                    deviations occurred?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.protocol}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Is the methodology valid? Does the paper offer enough
                    details of its methodology that its experiments or its
                    analyses could be reproduced?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.methodology}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Would any other experiments or additional information
                    improve the paper? How much better would the paper be if
                    this extra work was done, and how difficult would such work
                    be to do, or to provide?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.experiments}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Is this paper outstanding in its discipline? (For example,
                    would you like to see this work presented in a seminar at
                    your hospital or university? Do you feel these results need
                    to be incorporated in your next general lecture on the
                    subject?) If yes, what makes it outstanding? If not, why
                    not?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.outstanding}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Any other comments?:
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.comments}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Were you invited by the author for your reviews?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.invited}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Have you previously published on this or a similar topic?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.previously}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Please confirm that you have suitable experience and
                    knowledge to review this article
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.suitable}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Do you have any competing interest(s) in posting this
                    review?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.interest}
                    </label>
                  </p>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      className="pi pi-question-circle"
                      style={{ color: "#F74B02" }}
                    >
                      {" "}
                    </span>{" "}
                    Do you wish to submit this review anonymously?
                    <br />
                    <label htmlFor="title" style={{ color: "#067ADC" }}>
                      <span
                        className="pi pi-comment"
                        style={{ color: "#F74B02" }}
                      >
                        {" "}
                      </span>{" "}
                      {review.anonymously}
                    </label>
                  </p>
                </div>
              </div>
            </div>
          ))}

          <div>
            {/* Pagination controls */}
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            {"   "}
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={indexOfLastReview >= totalReviews}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ViewReviews;
