import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../Allarticles.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTours, clearTours } from "../redux/features/tourSlice";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";
import { fetchReviews } from "../redux/features/reviewSlice";
import { Col, Row } from "react-bootstrap";
import { fetchAllUsers } from "../redux/features/userSlice";
import { Oval as Loader } from "react-loader-spinner";

const Allarticles = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTours, setFilteredTours] = useState([]);
  const dispatch = useDispatch();
  const { tours, totalTours } = useSelector((state) => state.tour);
  // const tours = useSelector((state) => state.tour.tours);
  // const { tours } = useSelector((state) => state.tour);
  const reviews = useSelector((state) => state.review.reviews);
  const categories = useSelector((state) => state.category);
  const users = useSelector((state) => state.user.users);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [articletypesWithCount, setArticletypesWithCount] = useState([]);
  const [articletype, setArticletype] = useState("");
  const [reviewSort, setReviewSort] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const itemsPerPage = 20;

  const onInputChange = (e) => {
    setArticletype(e.target.value);
  };

  // console.log("reviews11", reviews);

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchCategoriesAsync());
    dispatch(fetchReviews());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearTours());
    dispatch(
      getTours({ pageNumber: currentPage, pageSize: itemsPerPage })
    ).then(() => {
      setLoading(false);
      setPaginationLoading(false); // Set loading to false after new data is fetched
    });
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    searchQuery,
    selectedCategories,
    articletype,
    reviewSort,
  ]);

  useEffect(() => {
    const articletypeCounts = tours.reduce((acc, tour) => {
      if (tour.approved) {
        acc[tour.articletype] = (acc[tour.articletype] || 0) + 1;
      }
      return acc;
    }, {});

    const articletypesWithCountArray = Object.keys(articletypeCounts).map(
      (articletype) => ({
        articletype,
        count: articletypeCounts[articletype],
      })
    );

    setArticletypesWithCount(articletypesWithCountArray);
  }, [tours]);

  const handleCategoryFilter = (category) => {
    setSelectedCategories((prevSelectedCategories) => {
      const isSelected = prevSelectedCategories.includes(category);
      if (isSelected) {
        return prevSelectedCategories.filter((c) => c !== category);
      } else {
        return [...prevSelectedCategories, category];
      }
    });
  };

  const handleClearCategories = () => {
    setSelectedCategories([]);
  };

  const searchWords = searchQuery
    .split(",")
    .map((word) => word.trim().toLowerCase());

  useEffect(() => {
    const filteredResults = tours.filter((article) => {
      const isCategoryMatch =
        selectedCategories.length === 0
          ? true
          : selectedCategories.includes(article.subjectcategory);
      const isReviewSortMatch =
        !reviewSort ||
        reviews.some((review) => review.artid === article.articleId);
      const isArticleTypeMatch =
        articletype === "" || articletype === "Select"
          ? true
          : article.articletype === articletype;
      return isCategoryMatch && isArticleTypeMatch && isReviewSortMatch;
    });

    const searchFilteredResults = filteredResults
      // .reverse()
      .map((article) => {
        const totalReviews = reviews.filter(
          (review) => review.artid === article.articleId
        ).length;
        return {
          ...article,
          totalReviews,
        };
      })
      .filter(
        (article) =>
          article.approved === true &&
          (searchQuery
            ? searchWords.some(
                (word) =>
                  article.title.toLowerCase().includes(word) ||
                  article.tags.some((tag) =>
                    tag.toLowerCase().includes(word)
                  ) ||
                  article.articleinfo[0].content.toLowerCase().includes(word)
              )
            : true)
      );

    setFilteredTours(searchFilteredResults);
  }, [
    tours,
    selectedCategories,
    searchQuery,
    searchWords,
    articletype,
    reviewSort,
    reviews,
  ]);

  // console.log("totalTours", totalTours)
  const totalPages = Math.ceil( totalTours / itemsPerPage);

  const paginate = (pageNumber) => {
    setPaginationLoading(true); // Set loading to true when pagination is clicked
    setCurrentPage(pageNumber);
  };

  const handleRemoveCategory = (event, category) => {
    event.stopPropagation();
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.filter((c) => c !== category)
    );
  };

  const renderPaginationButtons = () => {
    const maxPageButtons = 5;
    const maxPagesOnEachSide = Math.floor(maxPageButtons / 2);

    let startPageIndex, endPageIndex;

    if (totalPages <= maxPageButtons) {
      startPageIndex = 0;
      endPageIndex = totalPages;
    } else {
      if (currentPage <= maxPagesOnEachSide) {
        startPageIndex = 0;
        endPageIndex = maxPageButtons;
      } else if (currentPage >= totalPages - maxPagesOnEachSide) {
        startPageIndex = totalPages - maxPageButtons;
        endPageIndex = totalPages;
      } else {
        startPageIndex = currentPage - maxPagesOnEachSide - 1;
        endPageIndex = startPageIndex + maxPageButtons;
      }
    }

    const pageButtons = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    )
      .slice(startPageIndex, endPageIndex)
      .map((page) => (
        <li
          key={page}
          className={`page-item ${currentPage === page ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(page)}>
            {page}
          </button>
        </li>
      ));

    return (
      <>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {pageButtons}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </>
    );
  };

  const stripTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const articlesUpToCurrentPage = currentPage * itemsPerPage;

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <section className="card mb-0 vh-50">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div style={{ marginTop: "0px" }} className="card mb-4">
                <div className="card-header" style={{ color: "#067ADC" }}>
                  <span
                    style={{
                      color: "#F74B02",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    Select
                  </span>
                  <h5>Subject</h5>
                </div>
                <span style={{ color: "#067ADC" }}> </span>
                {categories.map((category) => {
                  // Filter articles based on the current category
                  const articlesInCategory = tours.filter(
                    (article) =>
                      article.subjectcategory === category.name &&
                      article.approved === true
                  );

                  const categoryStyle = {
                    margin: "8px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                    background: selectedCategories.includes(category.name)
                      ? "#ff8c00" // orange background for selected categories
                      : "none",
                    color: selectedCategories.includes(category.name)
                      ? "#fff"
                      : "#000",
                    position: "relative",
                  };

                  return (
                    <div
                      key={category._id}
                      style={categoryStyle}
                      onClick={() => handleCategoryFilter(category.name)}
                    >
                      <span>{category.name}</span>
                      <span style={{ marginLeft: "5px", color: "#067ADC" }}>
                        ({articlesInCategory.length} articles)
                      </span>
                      {selectedCategories.includes(category.name) && (
                        <button
                          className="btn btn-secondary btn-sm"
                          style={{
                            position: "absolute",
                            top: "2px",
                            right: "2px",
                            padding: "2px 5px",
                          }}
                          onClick={(e) =>
                            handleRemoveCategory(e, category.name)
                          }
                        >
                          X
                        </button>
                      )}
                    </div>
                  );
                })}
                <button
                  style={{ marginTop: "10px" }}
                  className="btn btn-secondary btn-sm"
                  onClick={handleClearCategories}
                >
                  Clear Categories
                </button>
              </div>
            </div>

            <div className="col-md-9">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8 offset-md-2"></div>
                </div>

                <div className="card mb-4">
                  <div className="card-header" style={{ color: "#067ADC" }}>
                    <div className="input-group">
                      <input
                        type="text"
                        id
                        name
                        className="form-control"
                        placeholder="Enter search terms separated by commas"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="card-body">
                    Total{" "}
                    <span style={{ color: "#067ADC" }}>Approved Articles </span>
                    matching your search criteria is{" "}
                    <span
                      className="fw-bolder"
                      style={{
                        display: "inline-block",
                        width: "40px", // Adjust the width and height as needed
                        height: "30px",
                        borderRadius: "50%",
                        background: "#067ADC",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: "24px",
                        marginRight: "8px", // Add spacing if needed
                      }}
                    >
                      {/* {filteredTours.length} */}
                      {articlesUpToCurrentPage}
                    </span>
                    out of{" "}
                    <span
                      className="fw-bolder"
                      style={{
                        display: "inline-block",
                        width: "40px", // Adjust the width and height as needed
                        height: "30px",
                        borderRadius: "50%",
                        background: "#F74B02",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: "24px",
                        marginRight: "8px", // Add spacing if needed
                      }}
                    >
                      {" "}
                      {totalTours}{" "}
                    </span>
                    Submitted articles.
                    {/* <div className="col-md-12">
                    <ul className="pagination justify-content-end pagination-split mt-0">
                      {renderPaginationButtons()}
                    </ul>
                  </div> */}
                  </div>
                </div>

                <Row>
                  <Col md={8}>
                    <div
                      className="card-header mb-4 vh-30"
                      style={{ color: "#067ADC" }}
                    >
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <span
                          style={{
                            color: "#F74B02",
                            fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                          Sort by Article Type:
                        </span>
                        <div className="input-group" style={{ width: "250px" }}>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="articletype"
                            value={articletype}
                            onChange={onInputChange}
                          >
                            <option value="Select">
                              Please select the Article type ( * )
                            </option>
                            {articletypesWithCount.map((item) => (
                              <option
                                key={item.articletype}
                                value={item.articletype}
                              >
                                {`${item.articletype} (${item.count || 0})`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="card-header" style={{ color: "#067ADC" }}>
                      <div style={{ display: "flex", alignItems: "right" }}>
                        <span
                          style={{
                            color: "#F74B02",
                            fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                          Review Sort:
                        </span>
                        <div className="input-group" style={{ width: "100px" }}>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={reviewSort ? "ReviewSort" : ""}
                            onChange={() => setReviewSort(!reviewSort)}
                          >
                            <option value="select">Select</option>
                            <option value="ReviewSort">Review Sort</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    {renderPaginationButtons()}
                  </ul>
                </nav>

                {loading || paginationLoading ? (
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    className="d-flex justify-content-center"
                  />
                ) : (
                  <div>
                    {filteredTours.slice(0, articlesUpToCurrentPage).map((article) => (
                      <div key={article._id}>
                        <h4 className="mb-1">
                          <Link to={`/article/${article.articleId}`}>
                            {article.title.slice(0, 55)}
                            {"....."}

                            {article.totalReviews > 0 && (
                              <span
                                class="badge rounded-pill bg-primary"
                                style={{ fontSize: "10px" }}
                              >
                                {" "}
                                <span
                                  className="fw-bolder"
                                  style={{
                                    display: "inline-block",
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    background: "#F74B02",
                                    color: "#FFFFFF",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    lineHeight: "16px",
                                    marginRight: "4px",
                                  }}
                                >
                                  {article.totalReviews}
                                </span>
                                Reviews posted
                              </span>
                            )}
                          </Link>
                        </h4>
                        <label
                          htmlFor="title"
                          className="col-sm-12 col-form-label"
                          style={{ color: "#067ADC" }}
                        >
                          <span
                            className="pi pi-tags"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {"  "}
                          Keywords:{" "}
                          <span style={{ color: "#000000" }}>
                            {article.tags.join(", ").slice(0, 20)}
                            {"....."}
                          </span>
                        </label>
                        <span style={{ color: "#067ADC" }}>
                          {"  "}
                          <span
                            className="pi pi-user"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>
                          Submitted by:
                        </span>{" "}
                        {"  "}
                        <span style={{ color: "#000000" }}>
                          {/* Find the user whose _id matches article.creator */}
                          {users.map((user) => {
                            if (user._id === article.creator) {
                              return `${user.title}. ${user.firstName} ${user.middleName} ${user.lastName}`;
                            }
                            return null;
                          })}
                        </span>
                        <Row>
                          <Col md={5}>
                            <label
                              htmlFor="title"
                              className="col-sm-12 col-form-label"
                              style={{ color: "#067ADC" }}
                            >
                              <span
                                className="pi pi-calendar"
                                style={{ color: "#F74B02" }}
                              >
                                {" "}
                              </span>
                              Submitted on:
                              <span
                                style={{
                                  color: "#000000",
                                  marginLeft: "0px",
                                }}
                              >
                                {` ${new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(article.createdAt))}`}
                              </span>
                            </label>
                          </Col>
                          <Col md={5}>
                            <label
                              htmlFor="title"
                              className="col-sm-12 col-form-label"
                              style={{ color: "#067ADC" }}
                            >
                              <span
                                className="pi pi-calendar"
                                style={{ color: "#F74B02" }}
                              >
                                {" "}
                              </span>
                              Approved on:{" "}
                              <span
                                style={{
                                  color: "#000000",
                                  marginRight: "0px",
                                }}
                              >
                                {` ${new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }).format(new Date(article.publishedAt))}`}
                              </span>
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5}>
                            <label
                              htmlFor="title"
                              className="col-sm-12 col-form-label"
                              style={{ color: "#067ADC" }}
                            >
                              <span
                                className="pi pi-table"
                                style={{ color: "#F74B02" }}
                              >
                                {" "}
                              </span>
                              Article Type:
                              <span
                                style={{
                                  color: "#000000",
                                  marginLeft: "0px",
                                }}
                              >{"    "}
                                {article.articletype}
                              </span>
                            </label>
                          </Col>
                          <Col md={5}>
                            <label
                              htmlFor="title"
                              className="col-sm-12 col-form-label"
                              style={{ color: "#067ADC" }}
                            >
                              <span
                                className="pi pi-th-large"
                                style={{ color: "#F74B02" }}
                              >
                                {"  "}
                              </span>
                              Article Category:{" "}
                              <span
                                style={{
                                  color: "#000000",
                                  marginRight: "0px",
                                }}
                              >
                                {article.subjectcategory}
                              </span>
                            </label>
                          </Col>
                        </Row>

                        {article.articleinfo[0].content &&
                        typeof article.articleinfo[0].content === "string" ? (
                          <p
                            className="fst-normal"
                            style={{ color: "#000000" }}
                          >
                            {stripTags(
                              article.articleinfo[0].content.slice(0, 150)
                            )}
                          </p>
                        ) : (
                          <p className="fst-normal">Content not available</p>
                        )}
                        <hr />
                      </div>
                    ))}
               
                  </div>
                )}
                <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        {renderPaginationButtons()}
                      </ul>
                    </nav>

                <div className="tab-content">
                  <div className="tab-pane active" id="home">
                    <div className="row"></div>
                  </div>
                  {/* end All results tab */}
                  {/* Users tab */}
                  <div className="tab-pane" id="users">
                    {/* ... (users tab code) ... */}
                  </div>
                  {/* end Users tab */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Allarticles;