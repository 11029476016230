import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api";
import axios from "axios";
import { BASE_URL } from "../../components/Config";

export const createTour = createAsyncThunk(
  "tour/createTour",
  async ({ updatedTourData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.createTour(updatedTourData);
      toast.success("Article Submitted Successfully");
      navigate("/payment");
      return response.data;
    } catch (err) {
      if (err.response) {
        // If err.response exists, use it to extract the error message
        return rejectWithValue(err.response.data);
      } else {
        // If err.response is undefined, handle it appropriately
        return rejectWithValue({ message: "An error occurred" });
      }
    }
  }
);

export const getToursWithCounts = createAsyncThunk(
  'tours/getToursWithCounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/tour/getToursWithCounts`);
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = response.data;
      if (!data || !data.categoriesWithTours) {
        throw new Error('Invalid API response structure');
      }
      return data.categoriesWithTours;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllTours = createAsyncThunk("tours/getAllTours", async () => {
  try {
    const response = await fetch(`${BASE_URL}/tour/all`);
    // console.log('Response status:', response.status); 
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    // console.log("API response:", data.totalTours); 
    // Check if the data field exists and is not null
    if (!data || !data.data) {
      throw new Error('Invalid API response structure');
    }
    return { tours: data.data, totalTours: data.totalTours };
  } catch (error) {
    // console.error("Fetch error:", error);
    throw error;
  }
});

export const getTours = createAsyncThunk('tours/getTours', async ({ pageNumber, pageSize }) => {
  const response = await fetch( `${BASE_URL}/tour?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  // return data.data; // Assuming the response has a 'data' field
  return { tours: data.data, totalTours: data.totalTours};
});

export const getTour = createAsyncThunk(
  "tour/getTour",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.getTour(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const likeTour = createAsyncThunk(
  "tour/likeTour",
  async ({ _id }, { rejectWithValue }) => {
    try {
      const response = await api.likeTour(_id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToursByUser = createAsyncThunk(
  "tour/getToursByUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await api.getToursByUser(userId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTour = createAsyncThunk(
  "tour/deleteTour",
  async ({ id, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await api.deleteTour(id);
      toast.success(response.data.message);
      navigate("/dashboard");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTour = createAsyncThunk(
  "tour/updateTour",
  async ({ id, updatedTourData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await api.updateTour(updatedTourData, id);
      toast.success("Article Updated Successfully");
      navigate("/dashboard");
      return response.data;
    } catch (err) {
      if (err.response) {
        // If err.response exists, use it to extract the error message
        return rejectWithValue(err.response.data);
      } else {
        // If err.response is undefined, handle it appropriately
        return rejectWithValue({ message: "An error occurred" });
      }
    }
  }
);

export const searchTours = createAsyncThunk(
  "tour/searchTours",
  async (searchQuery, { rejectWithValue }) => {
    try {
      const response = await api.getToursBySearch(searchQuery);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToursByTag = createAsyncThunk(
  "tour/getToursByTag",
  async (tag, { rejectWithValue }) => {
    try {
      const response = await api.getTagTours(tag);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRelatedTours = createAsyncThunk(
  "tour/getRelatedTours",
  async (tags, { rejectWithValue }) => {
    try {
      const response = await api.getRelatedTours(tags);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postComment = createAsyncThunk(
  "tour/postComment",
  async ({ id, comment, userid, toast }, thunkAPI) => {
    try {
      const response = await axios.post(`${BASE_URL}/tour/${id}/${userid}`, {
        comment: comment,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      throw error; // You can handle error responses based on your application's needs
    }
  }
);

const tourSlice = createSlice({
  name: "tour",
  initialState: {
    tours: [],
    allTours: [],
    tour: {},
    userTours: [],
    tagTours: [],
    relatedTours: [],
    currentPage: 1,
    numberOfPages: null,
    error: "",
    loading: false,
    reviews: [],
    pageNumber: 1, // Initial page number
    pageSize: 20, // Number of items per page
    totalTours: 0,
    totalPages: 0,
    categoriesWithTours: [],
  },

  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearTours: (state) => {
      state.tours = [];
      state.totalTours = 0;
    },
  },
  extraReducers: {
    [createTour.pending]: (state, action) => {
      state.loading = true;
    },
    [createTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tours = [action.payload];
    },
    [createTour.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getToursWithCounts.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getToursWithCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoriesWithTours = action.payload;
    },
    [getToursWithCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getAllTours.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllTours.fulfilled]: (state, action) => {
      state.loading = false;
      // state.tours = action.payload.tours;
      // state.totalTours = action.payload.totalTours;
      // state.tours = action.payload.tours || [];
      state.allTours = action.payload.tours || [];
      state.totalTours = action.payload.totalTours || 0;
    },
    [getAllTours.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getTours.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTours.fulfilled]: (state, action) => {
      state.loading = false;
      state.tours = action.payload.tours;
      state.totalTours = action.payload.totalTours;
    },
    [getTours.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getTour.pending]: (state, action) => {
      state.loading = true;
    },
    [getTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
    [getTour.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getToursByUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getToursByUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.userTours = action.payload;
    },
    [getToursByUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [deleteTour.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTour.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.userTours = state.userTours.filter((item) => item._id !== id);
        state.tours = state.tours.filter((item) => item._id !== id);
      }
    },
    [deleteTour.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [updateTour.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTour.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { id },
      } = action.meta;
      if (id) {
        state.userTours = state.userTours.map((item) =>
          item._id === id ? action.payload : item
        );
        state.tours = state.tours.map((item) =>
          item._id === id ? action.payload : item
        );
      }
    },
    [updateTour.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [likeTour.pending]: (state, action) => {},
    [likeTour.fulfilled]: (state, action) => {
      state.loading = false;
      const {
        arg: { _id },
      } = action.meta;
      if (_id) {
        state.tours = state.tours.map((item) =>
          item._id === _id ? action.payload : item
        );
      }
    },
    [likeTour.rejected]: (state, action) => {
      state.error = action.payload.message;
    },

    [searchTours.pending]: (state, action) => {
      state.loading = true;
    },
    [searchTours.fulfilled]: (state, action) => {
      state.loading = false;
      state.tours = action.payload;
    },
    [searchTours.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getToursByTag.pending]: (state, action) => {
      state.loading = true;
    },
    [getToursByTag.fulfilled]: (state, action) => {
      state.loading = false;
      state.tagTours = action.payload;
    },
    [getToursByTag.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getRelatedTours.pending]: (state, action) => {
      state.loading = true;
    },
    [getRelatedTours.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedTours = action.payload;
    },
    [getRelatedTours.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [postComment.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [postComment.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      // Assuming your tour has an id, you can update the comment for the specific tour
      const { id, comment } = action.payload;
      const tourToUpdate = state.tours.find((tour) => tour.id === id);
      if (tourToUpdate) {
        tourToUpdate.comments.push(comment);
      }
    },
    [postComment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setCurrentPage, setPageNumber, clearTours } = tourSlice.actions;
export default tourSlice.reducer; 