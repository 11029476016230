import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PublicationPolicy = () => {
  return (
    <>
     <Navbar />
    <br />
    <br/>
    <br />
    <br/>
    <br />
 
    <div className="card">
     
        <p style={{ fontWeight: "bold" }}>
          <div className="card-header" style={{ color: '#067ADC', fontSize: '16px' }}>Our Publication Policy</div>
      
        </p>

       
      We publish a range of articles without any prepublication peer review in
      virtually every biomedical discipline. We aim to publish all articles
      within 48 hours of submission. There is a rapid in house checking process
      to ensure that all but the clearly irrelevant and unscientific work is
      published within 48 hours of submission. WebmedCentral encourages
      scientists and readers to submit their reviews on the published material
      freely. We reserve the right to remove any inappropriate reviews. We also
      ask our reviewers about their credentials in the field so that our readers
      may judge the information accordingly. All the information supplied to us
      is dealt in accordance with our privacy policy. If any article which is
      obviously flawed or unscientific or harmful comes to your notice, please
      contact us and your concerns will be dealt with appropriately.
      <br/>
    <br />  <br/>
    <br />
      </div>
   
    
    <Footer />
    </>
 
  );
};

export default PublicationPolicy;
