import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import wmceditors from "../components/img/WebmedCentral_Editorial_responsibilities.pdf";
import { NavLink } from "react-router-dom";

const Editors = () => {
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = wmceditors;
    anchor.download = "WebmedCentral_Editorial_responsibilities.pdf";
    anchor.click();
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <br />
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            About WebmedCentral Editors
          </div>
        </p>
        <p>
          WebmedCentral Editors are reviewers, who believe in our publishing
          model. They are internationally renowned in their field and have made
          substantial contribution to science. Their main role is to enhance
          relationship between WebmedCentral and biomedical scientists by
          understanding the platform, providing constructive feedback, and help
          develop it further. They also guide WebmedCentral on the scope of its
          service and major policy issues.
        </p>
        <p>
          <br />
          <button className="btn btn-primary" onClick={handleDownload}>
            Download
          </button>
          <br /> <br />
          <NavLink to="/editorsview" target="_blank">
            <span style={{ color: "#F74B02" }}>View all</span>{" "}
            <h5>
              WebmedCentral{" "}
              <span style={{ color: "#F74B02", display: "inline-block" }}>
                Editors
              </span>
            </h5>
          </NavLink>
        </p>
        <br /> <br />
      </div>

      <Footer />
    </>
  );
};

export default Editors;
