import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Cookiepolicy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Cookies Policy
          </div>
        </p>

        <p style={{ fontWeight: "bold" }}>About Cookies</p>

        <p>
          When you visit our website, like most others, small pieces of data are
          sent to and retrieved from your browser, mostly in the form of
          ‘cookies’. These cookies can be used to store information about you or
          your device (computer, tablet or mobile) which can be used to present
          you with a more personalised web experience. By continuing to use this
          site, you are agreeing that we can use cookies in this way. You can
          choose to block cookies in your browser, but this may stop some
          websites from working properly.
        </p>

        <p style={{ fontWeight: "bold" }}>Compliance Cookie</p>
        <p>
          This cookie allows us to know whether or not you have given consent to
          the use of cookies and therefore do not need to be presented with this
          consent request message again.
        </p>

        <p style={{ fontWeight: "bold" }}>Performance Cookies</p>
        <p>
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site. All information these cookies collect is aggregated
          and therefore anonymous. If you do not allow these cookies we will not
          know when you have visited our site, and will not be able to monitor
          its performance.
        </p>

        <p>
          Cookie IDs: PHPSESID, uname, rememberme, wmc_cookie_cls_clicked,
          __utma, __utmb, __utmc, __utmz
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Cookiepolicy;
