import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUsers } from "../redux/features/userSlice";
import profilepic from "./img/samplepic.jpg";
import Header from "./Header";
import Navbar from "./Navbar";
import "../Editorsview.css";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";

const Editorsview = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const profilesPerPage = 8;
  const isLoading = useSelector((state) => state.user.loading);

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  if (isLoading) {
    // Add loading spinner or message here
    return <div>Loading...</div>;
  }

  if (!users.length) {
    // Handle case where users array is empty
    return <div>No users available.</div>;
  }

  const filteredUsers = users.filter(
    (user) => user.editorstatus && user.editor && user.verified === true
  );

  const totalPageCount = Math.ceil(filteredUsers.length / profilesPerPage);
  const visiblePages = 5; // Number of visible page numbers around the current page

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPageCount <= visiblePages) {
      startPage = 1;
      endPage = totalPageCount;
    } else {
      const halfVisible = Math.floor(visiblePages / 2);
      if (currentPage <= halfVisible) {
        startPage = 1;
        endPage = visiblePages;
      } else if (currentPage + halfVisible >= totalPageCount) {
        startPage = totalPageCount - visiblePages + 1;
        endPage = totalPageCount;
      } else {
        startPage = currentPage - halfVisible;
        endPage = currentPage + halfVisible;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={`pagination-button ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;

  const currentProfiles = filteredUsers.slice(
    indexOfFirstProfile,
    indexOfLastProfile
  );

  return (
    <>
      <Navbar />
      <Header />
      <br />
      <br />
      <br />
      <br />

      <br />

      {/* Team Start */}
      <div className="card container-xxl py-0">
        <div className="container">
          {/* <div
            className="text-center mx-auto mb-0 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h3>
            <br />
            <br />
            WebmedCentral Editorial Team</h3>
            
          </div> */}
          <br /> <br />
          <p style={{ fontWeight: "bold" }}>
            <div
              className="card-header"
              style={{ color: "#067ADC", fontSize: "16px" }}
            >
              WebmedCentral Editorial Team
            </div>
          </p>
          <p>
            WebmedCentral Advisory Board members are eminent scientists, who
            believe in our publishing model. They are internationally renowned
            in their field and have made substantial contribution to science.
            Their main role is to enhance relationship between WebmedCentral and
            biomedical scientists by understanding the platform, providing
            constructive feedback, and help develop it further. The advisory
            board members provide general advice to "Board of Directors" of
            Webmed Limited on scientific and publishing matters. They also guide
            WebmedCentral on the scope of its service and major policy issues.
          </p>
          <div className="row g-4">
            {currentProfiles.map((user, index) => (
              <div
                key={index}
                className={`col-lg-3 col-md-6 wow fadeInUp`}
                data-wow-delay={`${0.1 * (index + 1)}s`}
              >
                <div className="team-item position-relative rounded overflow-hidden">
                  <div
                    className="overflow-hidden"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={user.profileImage || profilepic}
                      alt="profilepic"
                      style={{
                        height: "265px",
                        width: "265px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="team-text bg-light text-center p-1">
                    <h5>
                      {`${user.title}. ${user.firstName} ${user.middleName}  ${user.lastName}`}
                    </h5>
                    {/* {user.positions} */}
                    {/* <p className="text-primary">{user.interests}</p> */}

                    {/* {user.positions.split(' ').slice(0, 2).join(' ')}
                    <p className="text-primary"> {user.positions.split(' ').length > 10 && '...'}</p> */}

                    {/* {user.positions.split(" ").slice(0, 2).join(" ")}
                    {user.positions.split(" ").length > 10 && "..."} */}
                    {user.designation}
                    {",  "}
                    {user.institute}

                    <NavLink to={`/Editorsprofile/${user._id}`}>
                      <br />
                      <Button className="btn btn-primary">View Profile</Button>
                    </NavLink>
                  </div>

                  {/* Additional content or social links */}
                  <div className="team-text bg-light text-center p-0">
                    {/* Add your additional content or social links here */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">{renderPageNumbers()}</div>
          <br />
          <br />
          <br />
          <br /> 
          <br />
          <br /> 
          <br />
          <br />
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
};

export default Editorsview;
