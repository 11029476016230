import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Ourphilosophy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Our Philosophy
          </div>
        </p>

        <p>
          We at WebmedCentral feel that biomedical publishing needs to evolve.
          In current times of unlimited publishing capacity, barriers to
          publishing need to be reduced and every scientist should have an
          opportunity to contribute. At the same time the present system of
          review needs to be broadened in its scope to accommodate every comment
          or criticism of the published work We are attempting to make the
          process of publishing more inclusive, efficient, accountable,
          economical, unbiased and transparent.
        </p>
        <p>
          WebmedCentral publishes all submitted research immediately. All but
          the clearly irrelevant and unscientific work is published. Our unique
          publication system is designed to empower authors and provide them
          with complete freedom of expression. They can publish their research
          and views without any peer pressure and irrespective of widely held
          peer belief on the topic. Peers on the other hand have the huge
          opportunity to publicly review and comment on the work. We believe
          that serious authors and reviewers will find this privilege empowering
          whereas casual author and reviewers, misusing this service, will have
          nothing to gain from publishing substandard content under their names.
          It will hopefully make "publication" a non issue in due course and
          move the emphasis away from it towards conducting quality research and
          writing good manuscripts.
        </p>
        <p>
          We aim to ensure that all articles are reviewed by at least two
          nominated experts. The peer review process begins immediately after
          publication of the article.All our reviews are visible online in
          keeping with our approach to complete transparency in biomedical
          scientific communications. Authors then have the opportunity to
          respond to the criticism or submit a modified manuscript..
        </p>
        <p>
          Traditionally biomedical journals have published articles from authors
          at no cost and then delivered them to readers at a price. More
          recently with the advent of open access publishing, authors are being
          charged significant sums of money by some journals to share the cost.
          With WebmedCentral’s innovative method of post publication peer
          review, we minimise the expense and time, while retaining the
          advantages of open access publication. We publish most articles within
          48 hours of submission. Our article processing charges are GBP 50.00
          per article. There is an article processing charges waiver option for
          disadvantaged authors.
        </p>
        <p>
          WebmedCentral have provided scientific community with an alternative
          method of communicating using post publication peer review model. This
          model of immediate publication with post publication peer review has
          overcome many difficulties posed by the traditional prepublication
          peer review system, which may at times hinder transparent scientific
          communication, delay the publication process and add to the cost of
          publishing. There is also a possibility of inherent bias within it
          leaving manuscripts at the mercy of editors and reviewers.
          WebmedCentral has full faith in the honesty and integrity of the
          scientific community and we firmly believe that most researchers and
          authors who have something to contribute should have an opportunity to
          do so. Each piece of research then finds its own place in scientific
          literature based on its merit.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Ourphilosophy;
