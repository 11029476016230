import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Premiumservice = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Premium Service
          </div>
        </p>

        <p>
          WebmedCentral is pleased to provide a premium upload service to
          authors on popular demand. Authors using this service will just need
          to upload/email the entire manuscript and we will submit it on the web
          portal for them. Please follow the steps mentioned below to use this
          service.
        </p>

        <p>Fill in the Premium Upload Service form</p>

        <p>Charged at GBP 100.00 per article</p>

        <p>Upload/Email required files</p>

        <p>
          WebmedCentral team works with you to submit your article on the portal
        </p>

        <p>We upload the article in draft format</p>

        <p>
          You review the draft and make amendments or email comments. We make
          the changes (maximum two revisions within 7 days)
        </p>

        <p>You approve the final draft and submit the article</p>
      </div>
      <Footer />
    </>
  );
};

export default Premiumservice;
