import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FileBase from "react-file-base64";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTour, updateTour } from "../redux/features/tourSlice";
import { Tabs, Tab, Row, Col, Button, Container, Stack } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import AddReviewersForm from "./AddReviewerForm";
import { fetchCategoriesAsync } from "../redux/features/categorySlice";
import { fetchCountriesAsync } from "../redux/features/countrySlice";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "../TextEditor.css";
import "quill/dist/quill.snow.css";
import "primeicons/primeicons.css";
import "../Addeditarticle.css";
import { Oval as Loader } from "react-loader-spinner";
import { toast } from "react-toastify";
import katex from "katex";
import "katex/dist/katex.min.css";

const initialState = {
  title: "",
  fundingsource: "",
  competinginterests: "",
  tags: [],
  approvingbody: "",
  authors: [],
  reviewers: [],
  imageFile: "",
  subjectcategory: "",
  articletype: "Select",
  // articleinfo: [],
  illustrations: [
    {
      image: "",
      title: "",
    },
  ],
};

const AddEditArticle = () => {
  const [tourData, setTourData] = useState(initialState);
  const [author, setAuthor] = React.useState([]);
  const [authors, setAuthors] = useState([]);
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [visible, setVisible] = useState(true);
  const [reviewer, setReviewer] = useState([]);
  const [loading, setLoading] = useState(false);

  const setReviewerValue = (reviewerValue) => {
    setReviewer([...reviewerValue], reviewerValue);
  };

  const handleEditAuthor = (index) => {
    setAuthor(authors[index]);
    setEditIndex(index);
  };

  const handleSaveAuthor = (event) => {
    event.preventDefault();

    if (editIndex !== -1) {
      // Updating an existing author
      const updatedAuthors = [...authors];
      updatedAuthors[editIndex] = author;
      setAuthors(updatedAuthors);
    } else {
      // Adding a new author
      setAuthors([...authors, author]);
    }

    setAuthor({
      title: "",
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      designation: "",
      deptinstitute: "",
      correspondingAuthor: false,
    });

    setEditIndex(-1);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const AuthorInput = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle the case when event.target is undefined
    if (!event.target) {
      return;
    }

    // setAuthor((prevAuthor) => ({
    //   ...prevAuthor,
    //   [name]: name === "email" ? value : (type === "checkbox" ? checked : capitalizeFirstLetter(value)),
    // }));

    setAuthor((prevAuthor) => ({
      ...prevAuthor,
      [name]:
        type === "checkbox"
          ? checked
          : name === "email"
          ? value
          : capitalizeFirstLetter(value),
    }));
  };

  // setAuthor((prevAuthor) => ({
  //   ...prevAuthor,
  //   [name]: type === "checkbox" ? checked : value,
  //   [name]: name === "email" ? value : capitalizeFirstLetter(value),
  // }));

  const categories = useSelector((state) => state.category);
  const countries = useSelector((state) => state.country);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategoriesAsync());
    dispatch(fetchCountriesAsync());
  }, [dispatch]);

  const { userTours } = useSelector((state) => ({ ...state.tour }));
  const { user } = useSelector((state) => ({ ...state.auth }));

  const {
    title,
    fundingsource,
    competinginterests,
    imageFile,
    subjectcategory,
    approvingbody,
    articletype,
  } = tourData;
  const { id } = useParams();

  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update tour data based on input type
    if (
      type === "radio" ||
      type === "checkbox" ||
      type === "textarea" ||
      type === "text"
    ) {
      setTourData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : capitalizeFirstLetter(value),
      }));
    } else {
      setTourData((prevState) => ({
        ...prevState,
        [name]: value ? value : capitalizeFirstLetter(value),
      }));
    }
  };

  const handleClear = () => {
    setTourData({
      title: "",
      fundingsource: "",
      competinginterests: "",
      tags: [],
      subjectcategory: "",
      approvingbody: "",
      articletype: "",
      articleinfo: "",
    });
    setAuthors([]);
    // setArticledetails([]); // Reset articleDetails state
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(capitalizeFirstLetter(value));
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    // key === " " --- This will add even if you give spacebar ..we dont need it so
    // simple give key === "" with no space and this can be removed also but kept
    if (
      (key === "," || key === "Enter" || key === "") &&
      trimmedInput.length > 0
    ) {
      e.preventDefault();
      const updatedTags = [...tags, trimmedInput]; // Include existing tags and new tag
      setTags(updatedTags);
      setTourData((prevState) => ({ ...prevState, tags: updatedTags }));
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setTourData((prevState) => ({ ...prevState, tags: tagsCopy }));
      setInput(poppedTag);
    }

    if (key === "Tab" || key === "Escape") {
      // Handle tab or escape key press here
    }

    setIsKeyReleased(false);
  };

  const handleBlur = () => {
    const trimmedInput = input.trim();

    if (trimmedInput.length > 0) {
      const updatedTags = [...tags, trimmedInput]; // Include existing tags and new tag
      setTags(updatedTags);
      setTourData((prevState) => ({ ...prevState, tags: updatedTags }));
      setInput("");
    }
  };

  // const onKeyDown = (e) => {
  //   const { key } = e;
  //   const trimmedInput = input.trim();

  //   if (
  //     (key === "," || key === "Enter" || key === " ") &&
  //     trimmedInput.length &&
  //     !tags.includes(trimmedInput)
  //   ) {
  //     e.preventDefault();
  //     const updatedTags = [...tags, trimmedInput]; // Include existing tags and new tag
  //     setTags(updatedTags);
  //     setTourData((prevState) => ({ ...prevState, tags: updatedTags }));
  //     setInput("");
  //   }

  //   if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
  //     const tagsCopy = [...tags];
  //     const poppedTag = tagsCopy.pop();
  //     e.preventDefault();
  //     setTags(tagsCopy);
  //     setTourData((prevState) => ({ ...prevState, tags: tagsCopy }));
  //     setInput(poppedTag);
  //   }

  //   setIsKeyReleased(false);
  // };

  const deleteTag = (index) => {
    const tagsCopy = [...tags];

    if (index >= 0 && index < tagsCopy.length) {
      tagsCopy.splice(index, 1); // Remove the tag at the specified index
      setTags(tagsCopy);
      setTourData((prevState) => ({ ...prevState, tags: tagsCopy }));
    }
  };

  // const deleteTag = (index) => {
  //   setTags(prevState => prevState.filter((tag, i) => i !== index))
  // }

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const handleDeleteImage = () => {
    setTourData({ ...tourData, imageFile: "" });
  };

  const deleteAuthor = (index) => {
    const updatedAuthors = authors.filter((_, i) => i !== index);
    setAuthors(updatedAuthors);
  };

  const [currentTab, setCurrentTab] = React.useState(0);

  const [titles, setTitles] = useState([""]);
  const [contents, setContents] = useState([""]);

  const [articleinfo, setArticleInfo] = useState([{ title: "", content: "" }]);

  const handleTitleChange = (event, index) => {
    const newTitles = [...titles];
    newTitles[index] = event.target.value;
    setTitles(newTitles);
  };

  const handleContentChange = (newContent, index) => {
    setContents((prevContents) => {
      const newContents = [...prevContents];
      newContents[index] = newContent;
      return newContents;
    });
  };

  // const handleContentChange = (event, index) => {
  //   const newContents = [...contents];
  //   newContents[index] = event.target.value;
  //   setContents(newContents);
  // };

  const handleAddField = () => {
    const newArticleInfo = [...articleinfo, { titles, contents }];
    setArticleInfo(newArticleInfo);
    setTitles([...titles, ""]);
    setContents([...contents, ""]);
  };

  const handleDelete = (index) => {
    const newTitles = [...titles];
    const newContents = [...contents];
    newTitles.splice(index, 1);
    newContents.splice(index, 1);
    setTitles(newTitles);
    setContents(newContents);
  };

  const [illustrationimage, setIllustrationImage] = useState([]);

  const IllustrationImageUpload = (event) => {
    const files = event.target.files;

    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = event.target.result;

        setIllustrationImage((prevIllustrations) => [
          ...prevIllustrations,
          { image, title: "" },
        ]);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleIllustrationTextChange = (event, index) => {
    const { value } = event.target;
    setIllustrationImage((prevState) => {
      const updatedIllustrations = [...prevState];
      updatedIllustrations[index] = {
        ...updatedIllustrations[index],
        title: value,
      };
      return updatedIllustrations;
    });
  };

  const handleDeleteIllustration = (index) => {
    const updatedIllustrations = illustrationimage.filter(
      (_, i) => i !== index
    );
    setIllustrationImage(updatedIllustrations);
  };

  // Define modules for your editor
  const modules = {
    height: 200,
    templates: [
      {
        name: "Template-1",
        html: "<p>HTML source1</p>",
      },
      {
        name: "Template-2",
        html: "<p>HTML source2</p>",
      },
    ],
    minHeight: "300px",
    maxHeight: "1000px",
    buttonList: [
      ["undo", "redo"],
      ["removeFormat"],
      [
        "bold",
        "underline",
        "italic",
        "strike",
        "fontSize",
        "subscript",
        "superscript",
      ],
      ["template"],
      ["fontColor", "hiliteColor"],
      ["align", "horizontalRule", "list"],
      ["table", "image", "math"],
      ["showBlocks", "codeView"],
      ["link", "video"], // Added Math module button
    ],
    imageRotation: false,
    fontSize: [12, 14, 16, 18, 20],
    colorList: [
      [
        "#828282",
        "#FF5400",
        "#676464",
        "#F1F2F4",
        "#FF9B00",
        "#F00",
        "#fa6e30",
        "#000",
        "rgba(255, 153, 0, 0.1)",
        "#FF6600",
        "#0099FF",
        "#74CC6D",
        "#FF9900",
        "#CCCCCC",
      ],
    ],
    imageUploadHandler: (
      image,
      imageUploadErrorMessage,
      imageUploadHandler,
      core
    ) => {
      if (image instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (img.width > 500 || img.height > 500) {
              imageUploadErrorMessage(
                "Image dimensions should not exceed 500x500 pixels"
              );
            } else {
              // Proceed with the default image upload handler
              imageUploadHandler(e.target.result);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(image);
      } else if (
        image instanceof HTMLElement &&
        image.tagName.toLowerCase() === "span" &&
        image.getAttribute("data-tex") !== null
      ) {
        // If the inserted element is a formula span
        const latex = image.getAttribute("data-tex");
        const formulaSpan =
          '<span contenteditable="false">' +
          katex.renderToString(latex) +
          "</span>";
        core.exec("INSERT_HTML", formulaSpan);
        core.insertText("\n"); // Insert a newline after the formula to separate it from subsequent text
      } else {
        // If it's neither an image nor a formula span, proceed with the default upload handler
        imageUploadHandler(image);
      }
    },
    katex: katex, // Provide KaTeX object
    // Allow specific HTML tags and styles
    allowedTags: ["table", "tr", "td", "th", "thead", "tbody", "tfoot"],
    allowedStyles: {
      table: {
        borderCollapse: [/^collapse$/],
        border: [/^\d+px\s+solid\s+#(?:[0-9a-f]{3}){1,2}$/],
      },
      td: {
        border: [/^\d+px\s+solid\s+#(?:[0-9a-f]{3}){1,2}$/],
      },
      th: {
        border: [/^\d+px\s+solid\s+#(?:[0-9a-f]{3}){1,2}$/],
      },
    },
  };

  useEffect(() => {
    if (id) {
      const singleTour = userTours.find((tour) => tour._id === id);
      setTourData({ ...singleTour });
      setTags(singleTour.tags || []); // Set tags state with existing tags
      setAuthors(singleTour.authors || []); // Set authors state with existing authors
      setIllustrationImage(singleTour.illustrations || []);
      setReviewer(singleTour.reviewers || []);
      const retrievedTitles = singleTour.articleinfo.map((info) => info.title);
      const retrievedContents = singleTour.articleinfo.map(
        (info) => info.content
      );
      setTitles(retrievedTitles);
      setContents(retrievedContents);
    } else {
      setTourData(initialState); // Reset tourData to initialState when creating a new tour
      // setAuthors([]); // Reset authors state when creating a new tour
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title && fundingsource && subjectcategory && competinginterests) {
      setLoading(true);
      const newArticleInfo = titles.map((title, index) => ({
        title,
        content: contents[index],
      }));

      const updatedTourData = {
        ...tourData,
        userfirstname: user?.result?.firstName,
        usermiddlename: user?.result?.middleName,
        userlastname: user?.result?.lastName,
        usertitle: user?.result?.title,
        useremail: user?.result?.email,
        authors: authors,
        articleinfo: newArticleInfo,
        illustrations: illustrationimage,
        reviewers: reviewer,
        // pdffile: pdffile,
      };

      if (!id) {
        dispatch(createTour({ updatedTourData, navigate, toast }));
      } else {
        dispatch(updateTour({ id, updatedTourData, navigate, toast }));
      }
      handleClear(); // Clear the fields after successful submission
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      <Navbar />

      <br />
      <br />
      <br />
      <br />
      <br />

      <section style={{ backgroundColor: "#FFFFFF" }}>
        <form onSubmit={handleSubmit} style={{ backgroundColor: "#DCE7ED" }}>
          <br />
          <Container>
            <Row>
              <Col>
                <Tabs
                  activeKey={currentTab}
                  id="controlled-tab-example"
                  style={{ color: "##59BFE9" }}
                >
                  <Tab
                    eventKey={0}
                    title=<div style={{ color: "#000000" }}>
                      Instructions for Authors
                    </div>
                    disabled={currentTab !== 0}
                  >
                    <div className="card mb-0">
                      <div className="card-header" style={{ color: "#067ADC" }}>
                        Read the instructions carefully before submitting the
                        article
                      </div>
                      <div className="card-body">
                        <div className="input-group">
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              1
                            </span>{" "}
                            Manuscripts should be composed in accordance with
                            our
                            <NavLink
                              to="/instructionsforauthors"
                              target="_blank"
                            >
                              {" "}
                              'instructions for authors'
                            </NavLink>
                            .{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              2
                            </span>{" "}
                            You will need name, designation, affiliation and
                            email id for all authors.{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              3
                            </span>{" "}
                            For patient identifiable information, a consent form
                            from the patient or legal guardian should accompany
                            the manuscript.{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              4
                            </span>
                            <NavLink
                              to="/copyrighttransferform"
                              target="_blank"
                            >
                              {" "}
                              Copyright Transfer Form{" "}
                            </NavLink>
                            would only be required if you are not one of the
                            authors.{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              5
                            </span>{" "}
                            All the diagrams, tables, pictures and figures
                            (identified as "Illustration") can be uploaded as
                            JPG, GIF, PNG, GIF, PNG, BMP or in DOC formats.
                            Please label each file as Illustration 1,
                            Illustration 2, Illustration 3 etc.
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              6
                            </span>{" "}
                            You can also upload upto five additional files with
                            the article. These may be original data files,
                            questionnaire files etc . You may use this
                            functionality to upload files in your preferred
                            format. Please give these files a self-descriptive
                            name. Our readers will have free access to these
                            files.{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              7
                            </span>{" "}
                            You will be required to submit the details (names,
                            email ids, designation and professional affiliation)
                            of at least three reviewers. You can enter details
                            of more reviewers, if you so wish.
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              8
                            </span>{" "}
                            We will require information regarding source(s) of
                            funding and competing interest(s).{" "}
                          </p>
                          <p>
                            <span
                              className="fw-bolder"
                              style={{
                                display: "inline-block",
                                width: "24px", // Adjust the width and height as needed
                                height: "24px",
                                borderRadius: "50%",
                                background: "#067ADC",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "24px",
                                marginRight: "8px", // Add spacing if needed
                              }}
                            >
                              9
                            </span>{" "}
                            An article processing charge of{" "}
                            <span
                              style={{
                                color: "#067ADC",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              GBP 50{" "}
                            </span>
                            is payable for articles accepted for publication in
                            WebmedCentral. If your institution, society or other
                            organization is a
                            <NavLink
                              to="http://www.biomedcentral.com/"
                              target="_blank"
                            >
                              {" "}
                              member,{" "}
                            </NavLink>
                            this cost may be wholly or partly covered by the
                            membership arrangement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey={1}
                    title=<div style={{ color: "#274052" }}>
                      Additional Authors
                    </div>
                    disabled={currentTab !== 1}
                  >
                    <div className="card mb-4">
                      <div className="card-header" style={{ color: "#067ADC" }}>
                        <label
                          className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                          htmlFor="exampleCheck1"
                        >
                          Enter additional Authors Details
                        </label>
                      </div>
                      <div className="row">
                        <br />

                        <br />
                        <div>
                        <br />
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="correspondingAuthor"
                              name="correspondingAuthor"
                              checked={author.correspondingAuthor}
                              onChange={AuthorInput}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="correspondingAuthor"
                            >

<span style={{color: "#067ADC",
                      marginRight: "3px",
                      marginLeft: "3px",
                      
                    }}
                  >
                    Check if you would like to make the Author as a
                              corresponding Author for this article
                  </span>

                              
                            </label>
                          </div>
                          <br />
                        </div>

                        <br />
                        <br />

                        <div className="col">
                          <div className="form-outline">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              name="title"
                              value={author.title}
                              onChange={AuthorInput}
                            >
                              <option value="Select">Please select</option>
                              <option value="Dr">Dr</option>
                              <option value="Prof">Prof</option>
                              <option value="Mr">Mr</option>
                              <option value="Miss">Miss</option>
                            </select>
                            <p>{`You selected ${author.title}`}</p>
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="text"
                              id="form8Example3"
                              className="form-control"
                              name="firstname"
                              value={author.firstname}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example3"
                            >
                              First name
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="text"
                              id="form8Example3"
                              className="form-control"
                              name="middlename"
                              value={author.middlename}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example3"
                            >
                              Middle name
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="text"
                              id="form8Example4"
                              className="form-control"
                              name="lastname"
                              value={author.lastname}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example4"
                            >
                              Last name
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="email"
                              id="form8Example5"
                              className="form-control"
                              name="email"
                              value={author.email}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example5"
                            >
                              Email address
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="designation"
                              id="form8Example5"
                              className="form-control"
                              name="designation"
                              value={author.designation}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example5"
                            >
                              Designation
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline">
                            <input
                              type="email"
                              id="form8Example5"
                              className="form-control"
                              name="deptinstitute"
                              value={author.deptinstitute}
                              onChange={AuthorInput}
                            />
                            <label
                              className="form-label"
                              htmlFor="form8Example5"
                            >
                              Department and Institute
                            </label>
                          </div>
                        </div>

                        <div className="col">
                          <select
                            className="form-select"
                            type="country"
                            id="form8Example5"
                            name="country"
                            value={author.country}
                            onChange={AuthorInput}
                          >
                            <option value="Select">Select country</option>
                            {countries.map((country) => (
                              <option key={country.id} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div>
                        <Button
                          className="btn btn-primary"
                          onClick={handleSaveAuthor}
                          style={{
                            border: "1px solid #FFFFFF",
                            borderRadius: "4px",
                            color: "#FFFFFF",
                          }}
                        >
                          {editIndex !== -1 ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>

                    {/* <div className="grid-container"> */}
                    <div className="card">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm">
                            <strong>Title</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Firstname</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Middlename</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Lastname</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Email</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Designation</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Dept/Institute</strong>
                          </div>
                          <div className="col-sm">
                            <strong>Actions</strong>
                          </div>
                        </div>
                      </div>
                    </div>

                    {authors.map((author, index) => (
                      <div className="card" key={index}>
                        <div className="container">
                          <div className="row">
                            <div className="col-sm">{author.title}</div>
                            <div className="col-sm">{author.firstname}</div>
                            <div className="col-sm">{author.middlename}</div>
                            <div className="col-sm">{author.lastname}</div>
                            <div className="col-sm">{author.email}</div>
                            <div className="col-sm">{author.designation}</div>
                            <div className="col-sm">{author.deptinstitute}</div>
                            <div className="col-sm">
                              {/* <i
                                className="bi bi-pencil-square"
                                onClick={() => handleEditAuthor(index)}
                              ></i>
                                <i
                                className="fa-sharp fa-solid fa-trash"
                                onClick={() => deleteAuthor(index)}
                              ></i> */}

                              {"  "}
                              {"  "}

                              <span
                                className="pi pi-file-edit"
                                onClick={() => handleEditAuthor(index)}
                              >
                                {" "}
                              </span>

                              {"  "}

                              <span
                                className="pi pi-trash"
                                style={{ color: "#F74B02" }}
                                onClick={() => deleteAuthor(index)}
                              >
                                {" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Tab>

                  <Tab
                    eventKey={2}
                    title=<div style={{ color: "#274052" }}>Add Reviewers</div>
                    disabled={currentTab !== 2}
                  >
                    <AddReviewersForm setReviewerValue={setReviewerValue} />
                  </Tab>

                  <Tab
                    eventKey={3}
                    title=<div style={{ color: "#274052" }}>
                      Article Details
                    </div>
                    disabled={currentTab !== 3}
                  >
                    <div className="card mb-0">
                      <label
                        className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                        htmlFor="exampleCheck1"
                      >
                        <p>
                          All fields marked as ({" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>{" "}
                          ) are mandatory to proceed to the{" "}
                          <span
                            class="btn btn-primary"
                            style={{
                              color: "#067ADC",
                              padding: "1px 4px",
                              fontSize: "12px",
                            }}
                          >
                            Next
                          </span>{" "}
                          step
                        </p>
                        <div
                          className="card-header"
                          style={{ color: "#067ADC" }}
                        >
                          Choose Subject Category{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </div>
                      </label>
                      <div className="card">
                        <div className="form-check">
                          <div className="categories-container">
                            {categories.map((category, index) => (
                              <div key={category._id} className="category-item">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id={category.name}
                                  name="subjectcategory"
                                  value={category.name}
                                  checked={
                                    tourData.subjectcategory ===
                                    `${category.name}`
                                  }
                                  onChange={onInputChange}
                                />
                                <label
                                  htmlFor={category._id}
                                  className={
                                    tourData.subjectcategory ===
                                    `${category.name}`
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {category.name}
                                </label>
                                {index !== categories.length - 1 && (
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>

                          {/* <input
                        className="form-check-input"
                        type="radio"
                        name="subjectcategory"
                        id="Aging"
                        value="Aging"
                        checked={tourData.subjectcategory === "Aging"}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label" htmlFor="Aging">
                        Aging
                      </label> */}
                        </div>
                      </div>
                      {/* <hr /> */}

                      <label
                        className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                        htmlFor="exampleCheck1"
                      >
                        <br />
                        <div
                          className="card-header"
                          style={{ color: "#067ADC" }}
                        >
                          Enter Basic Information{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </div>
                      </label>

                      <div className="form-floating mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          id="floatingInputfundingsource"
                          placeholder="fundingsource"
                          required
                          autoFocus
                          value={fundingsource}
                          name="fundingsource"
                          onChange={onInputChange}
                        ></textarea>
                        <label htmlFor="floatingInputfundingsource">
                          Please enter here details regarding the source of
                          funding for your article.{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          type="text"
                          className="form-control"
                          id="floatingInputcompetinginterests"
                          placeholder="competinginterests"
                          required
                          autoFocus
                          value={competinginterests}
                          name="competinginterests"
                          onChange={onInputChange}
                        ></textarea>
                        <label htmlFor="floatingInputcompetinginterests">
                          Please enter any competing interests.{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </label>
                      </div>

                      <div className="card">
                        <label className="col-sm-12 align-items-left justify-content-left h-100">
                          Do you want to upload a valid ethical approval/
                          institutional review board approval/ patient consent?{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </label>

                        <p>
                          <div class="col-sm-1 mt-2">
                            <label>
                              <input
                                type="radio"
                                class="mx-2"
                                name="isyes"
                                value="1"
                                checked={visible}
                                onChange={() => setVisible(true)}
                              />
                              Yes
                            </label>
                          </div>
                          <div class="col-sm-2 mt-2">
                            <label>
                              <input
                                type="radio"
                                class="mx-2"
                                name="isyes"
                                value="0"
                                checked={!visible}
                                onChange={() => setVisible(false)}
                              />
                              No
                            </label>
                          </div>
                        </p>
                        {visible ? (
                          <div className="d-flex justify-content-start">
                            {imageFile && (
                              <div>
                                <img
                                  src={imageFile}
                                  alt="Tour"
                                  className="img-thumbnail"
                                  style={{ width: "100px", height: "100px" }}
                                />
                                <button onClick={handleDeleteImage}>
                                  Delete Image
                                </button>
                              </div>
                            )}
                            <FileBase
                              type="file"
                              multiple={false}
                              onDone={({ base64 }) =>
                                setTourData({
                                  ...tourData,
                                  imageFile: base64,
                                })
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <div class="border border-success p-2 mb-2 border border-danger">
                              In order to proceed with your submission, please
                              confirm that the necessary ethical approval has
                              been sought (and from which body). If you are
                              submitting a case report, please confirm that the
                              patients' consent has been sought and can be
                              produced for the viewing of the Editors if
                              required.
                            </div>
                            <br />
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckChecked"
                                checked
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                This is to confirm that the submitted research
                                has the necessary ethical approval and/or
                                patient consent
                              </label>
                            </div>

                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                id="form8Example3"
                                className="form-control"
                                placeholder="Approving body's name"
                                required
                                autoFocus
                                value={approvingbody}
                                name="approvingbody"
                                onChange={onInputChange}
                              />
                              <label htmlFor="form8Example3">
                                Approving body's name{" "}
                                <i
                                  className="bi bi-asterisk"
                                  style={{ color: "red", fontSize: "10px" }}
                                ></i>
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {/* <hr /> */}
                      <label
                        className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                        htmlFor="exampleCheck1"
                      >
                        <br />
                        <div
                          className="card-header"
                          style={{ color: "#067ADC" }}
                        >
                          Enter the article details{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </div>
                      </label>

                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInputArticletitle"
                          placeholder="articletitle"
                          required
                          autoFocus
                          value={title || ""}
                          name="title"
                          onChange={onInputChange}
                        />
                        <label htmlFor="floatingInputArticletitle">
                          Article Title{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </label>
                      </div>
                      <div className="form-floating mb-3 text-end">
                        <input
                          type="text"
                          className="form-control"
                          value={input}
                          placeholder="Enter a tag"
                          onKeyDown={onKeyDown}
                          onChange={onChange}
                          onKeyUp={onKeyUp}
                          onBlur={handleBlur}
                        />{" "}
                        <label htmlFor="floatingInputTag">
                          Type/Enter your tag text and hit{" "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            "Comma"{" "}
                          </span>{" "}
                          or{" "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            "Enter"{" "}
                          </span>{" "}
                          button to{" "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            "Add a tag"{" "}
                          </span>
                          {/* or {"  "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            ","
                          </span>{" "}
                          {"        "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            |
                          </span>{" "}
                          {"  "}
                          To delete a tag use{" "}
                          <span style={{ color: "#000000", fontSize: "20px" }}>
                            "Backspace"{" "}
                          </span>{" "}
                          button{" "} */}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>
                        </label>
                        <div className="tag-list-container">
                          {tags.map((tag, index) => (
                            <div className="tag" key={index}>
                              <span>{tag}</span>
                              <i onClick={() => deleteTag(index)}>
                                <span
                                  className="pi pi-trash"
                                  style={{ color: "#F74B02" }}
                                />
                              </i>{" "}
                              {"    "}
                              {index !== tags.length - 1 &&
                                (index + 1) % 8 !== 0 && <span>,{"    "}</span>}
                              {(index + 1) % 8 === 0 &&
                                index !== tags.length - 1 && <br />}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-outline">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="articletype"
                          value={articletype}
                          onChange={onInputChange}
                          disabled={
                            !tourData.subjectcategory ||
                            !fundingsource ||
                            !competinginterests ||
                            !title ||
                            !(imageFile || approvingbody) ||
                            tags.length === 0
                          }
                        >
                          <option value="Select">
                            Please select the Article type{" "}
                            <i
                              className="bi bi-asterisk"
                              style={{ color: "#F74B02", fontSize: "10px" }}
                            ></i>
                            ( * )
                          </option>
                          <option value="Research articles">
                            Research articles
                          </option>
                          <option value="Case report">Case report</option>
                          <option value="Clinical trials">
                            Clinical trials
                          </option>
                          <option value="Editorials">Editorials</option>
                          <option value="Meta Analysis">Meta Analysis</option>
                          <option value="My Opinion">My Opinion</option>
                          <option value="Original Articles">
                            Original Articles
                          </option>
                          <option value="Research Protocol">
                            Research Protocol
                          </option>
                          <option value="Randomized Controlled Trials">
                            Randomized Controlled Trials
                          </option>
                          <option value="Review Articles">
                            Review Articles
                          </option>
                          <option value="Systematic Review">
                            Systematic Review
                          </option>
                          <option value="WMC Advisor's Column">
                            WMC Advisor's Column
                          </option>
                          <option value="Industry Sponsored Research">
                            Industry Sponsored Research
                          </option>
                        </select>
                        <span
                          style={{
                            color: "#F74B02",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Enabled only after filling all the above mandatory
                          field details{" "}
                          <i
                            className="bi bi-asterisk"
                            style={{ color: "red", fontSize: "10px" }}
                          ></i>{" "}
                        </span>
                        <br />
                      </div>

                      {articletype !== "Select" && (
                        <>
                          <div className="card">
                            <span
                              style={{
                                color: "#F74B02",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ marginRight: "5px", fontSize: "20px" }}
                              >
                                &#8226;
                              </span>
                              Now you can use
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 15.74 15.74"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  fill: "#000",
                                  padding: "4px",
                                }}
                              >
                                <g>
                                  <path
                                    d="M4.41,8.05V3.76H8.7V8.05H4.41Zm5.71,0V3.76h4.3V8.05h-4.3Zm5.74-4.29h4.29V8.05H15.86V3.76Zm-11.45,10V9.48H8.7v4.3H4.41Zm5.71,0V9.48h4.3v4.3h-4.3Zm5.74,0V9.48h4.29v4.3H15.86ZM4.41,19.5V15.21H8.7V19.5H4.41Zm5.71,0V15.21h4.3V19.5h-4.3Zm5.74,0V15.21h4.29V19.5H15.86Z"
                                    transform="translate(-4.41 -3.76)"
                                  ></path>
                                </g>
                              </svg>
                              to add tables
                            </span>
                            <span
                              style={{
                                color: "#F74B02",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ marginRight: "5px", fontSize: "20px" }}
                              >
                                &#8226;
                              </span>
                              use
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 15.75 15.77"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  fill: "#000",
                                  padding: "4px",
                                }}
                              >
                                <g>
                                  <path
                                    d="M8.77,8.72a.88.88,0,0,1-.61-.27.82.82,0,0,1-.25-.61.89.89,0,0,1,.25-.62A.82.82,0,0,1,8.77,7a.81.81,0,0,1,.61.25.83.83,0,0,1,.27.62.81.81,0,0,1-.25.61.91.91,0,0,1-.63.27Zm9.62-5a1.74,1.74,0,0,1,1.76,1.76V17.76a1.74,1.74,0,0,1-1.76,1.76H6.16A1.74,1.74,0,0,1,4.4,17.76V5.51A1.74,1.74,0,0,1,6.16,3.75H18.39Zm0,1.75H6.16v8L8.53,11.8a.94.94,0,0,1,.54-.17.86.86,0,0,1,.54.2L11.09,13l3.64-4.55a.78.78,0,0,1,.34-.25.85.85,0,0,1,.42-.07.89.89,0,0,1,.39.12.78.78,0,0,1,.28.29l2.24,3.67V5.51Zm0,12.24V15.6L15.3,10.53,11.89,14.8a.89.89,0,0,1-.59.32.82.82,0,0,1-.64-.18L9,13.62,6.16,15.74v2Z"
                                    transform="translate(-4.4 -3.75)"
                                  ></path>
                                </g>
                              </svg>
                              to upload images
                            </span>
                            <span
                              style={{
                                color: "#F74B02",
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ marginRight: "5px", fontSize: "20px" }}
                              >
                                &#8226;
                              </span>
                              use
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 15.75 15.77"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  fill: "#000",
                                  padding: "4px",
                                }}
                              >
                                <g>
                                  <path
                                    d="M17.19,5.73a1,1,0,0,0,.71-.29,1,1,0,0,0,.28-.7,1,1,0,0,0-1-1H7.35a1,1,0,0,0-1,1,.77.77,0,0,0,.13.47h0l4.58,6.43L6.68,17.81a1.25,1.25,0,0,0-.29.71.94.94,0,0,0,.28.7.92.92,0,0,0,.69.28H17.2a1,1,0,0,0,.71-.28,1,1,0,0,0,0-1.39.92.92,0,0,0-.71-.29H9.26l3.87-5.43a.86.86,0,0,0,0-.95L9.26,5.73h7.93Z"
                                    transform="translate(-6.38 -3.77)"
                                  ></path>
                                </g>
                              </svg>
                              to add Math Formulas
                            </span>
                          </div>

                          <br />

                          {titles.map((title, index) => (
                            <div key={index}>
                              <div className="form-floating mb-3">
                                <input
                                  className="form-control"
                                  name={`title-${index}`}
                                  label={`Title ${index + 1}`}
                                  fullWidth
                                  value={title}
                                  id="you can enter/have your own heading like Abstract, Introduction etc"
                                  placeholder="you can enter/have your own heading like Abstract, Introduction etc"
                                  onChange={(e) => handleTitleChange(e, index)}
                                />
                                <label htmlFor="you can enter/have your own heading like Abstract, Introduction etc">
                                  you can enter/have your own heading like
                                  Abstract, Introduction etc
                                </label>
                              </div>

                              <SunEditor
                                theme="snow"
                                name={`content-${index}`}
                                setContents={contents[index]}
                                // value={contents[index]}
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                onChange={(newContent) =>
                                  handleContentChange(newContent, index)
                                }
                                style={{ height: "400px" }}
                                setOptions={modules}
                              />

                              <Button
                                className="btn btn-primary"
                                onClick={() => handleDelete(index)}
                              >
                                DELETE
                              </Button>
                              <br />
                              <br />
                            </div>
                          ))}
                        </>
                      )}

                      {articletype !== "Select" && (
                        <div>
                          <Button
                            className="btn btn-primary"
                            onClick={handleAddField}
                          >
                            Click to add more TITLES and respective CONTENT for
                            the articles
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>

                  <Tab
                    eventKey={4}
                    title=<div style={{ color: "#274052" }}>Illustrations</div>
                    disabled={currentTab !== 4}
                  >
                    <div className="card mb-0">
                      <label
                        className="col-sm-10 col-form-label col-form-label-sm fw-bolder"
                        htmlFor="exampleCheck1"
                      >
                        Upload Illustrations
                      </label>
                      <br />
                      <div>
                        <input
                          type="file"
                          multiple
                          onChange={IllustrationImageUpload}
                        />

                        {illustrationimage.map((illustration, index) => (
                          <div key={index}>
                            <br />
                            <img
                              src={illustration.image}
                              alt="Selected"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <input
                              type="text"
                              placeholder="Enter image text"
                              value={illustration.title}
                              onChange={(event) =>
                                handleIllustrationTextChange(event, index)
                              }
                            />
                            <button
                              type="button"
                              onClick={() => handleDeleteIllustration(index)}
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-12">
                      {/* <button type="submit" className="btn btn-primary btn-lg">
                        {id ? "Update" : "Submit"}
                      </button> */}
                    </div>
                  </Tab>
                  <Tab
                    eventKey={5}
                    title=<div style={{ color: "#274052" }}>Payments</div>
                    disabled={currentTab !== 5}
                  >
                    <div className="card mb-0">
                      <br />
                      <br />
                      You are almost done with the Article Submission process.
                      <label style={{ color: "#067ADC" }}>
                        {" "}
                        Click
                        <b> "Submit" </b> to finalize and complete the payment
                        process.
                      </label>
                      <br />
                      <br />
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            <Stack gap={4} direction="horizontal" className="mt-2">
              <Button
                className="success"
                disabled={currentTab === 0}
                onClick={() => setCurrentTab((prev) => prev - 1)}
                style={{ border: "1px solid #FFFFFF", borderRadius: "4px" }}
              >
                Prev
              </Button>

              {/* <Button
    className="success"
    disabled={
      currentTab === 4 || currentTab === 5 || editIndex !== -1
    }
    onClick={() => setCurrentTab((prev) => prev + 1)}
    style={{ border: '1px solid #FFFFFF', borderRadius: '4px', color: '#FFFFFF' }}
  >
    Next
  </Button> */}

              <Button
                className="success"
                disabled={
                  (currentTab === 3 &&
                    (!tourData.subjectcategory ||
                      !fundingsource ||
                      !competinginterests ||
                      !title ||
                      !(imageFile || approvingbody) ||
                      tags.length === 0 ||
                      articletype === "Select")) ||
                  currentTab === 5 ||
                  editIndex !== -1
                }
                onClick={() => setCurrentTab((prev) => prev + 1)}
                style={{
                  border: "1px solid #FFFFFF",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                }}
              >
                Next
              </Button>

              <Button
                className="success"
                type="submit"
                disabled={
                  currentTab === 0 ||
                  currentTab === 1 ||
                  currentTab === 2 ||
                  currentTab === 3 ||
                  currentTab === 4
                  // !pdfGenerated  // Disable the "Submit" button if PDF is not generated
                }
                style={{ border: "1px solid #FFFFFF", borderRadius: "4px" }}
                onClick={handleSubmit}
              >
                {loading ? (
                  // Show loader when the form is being submitted
                  <Loader type="Oval" color="#FFFFFF" height={20} width={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
            <br />
            {/* <p>Current tab index is {currentTab}</p> */}
          </Container>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default AddEditArticle;
