import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Copyrighttransferform = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

     


      <div className="card mb-4">
        <p style={{ fontWeight: "bold" }}>
         
          <div className="card-header" style={{ color: '#067ADC' }}>

          <h2>copyright transfer form</h2></div>

        </p>

     



        <div className="card-body">
                  <div className="input-group">
                  <p>
          This form is for a user who wants to publish an article on
          WebmedCentral, s/he is not an author of. WebmedCentral does not ask
          authors to transfer article copyrights to us. Please refer to our
          Copyright policy for details. 
          <br />
          <br />
          
          <p>COPYRIGHT TRANSFER FORM</p> 
          The copyright to
          the article “................article title........” is transferred to
          ..................(Submitting Person/Organization name) . The
          author(s) (or corresponding author) warrant(s) that his/her/their
          contribution is original and that he/she/they has/have full power to
          make this grant. The corresponding author signs for and accepts
          responsibility for releasing the article on behalf of any and all
          co-authors. The copyright transfer covers the right and license to
          reproduce, publish, distribute and archive the article in all forms
          and media of expression now known or developed in the future,
          including reprints, translations, photographic reproductions,
          microform, electronic form (offline, online) or any other
          reproductions.
          <p> Signature of Corresponding </p> 
         
          <p>Author: Corresponding</p>
          <p>Author’s name: Article </p>
          <p>Title: Date:</p>
        </p>
                  </div>
                </div>


      
      </div>
      <Footer />
    </>
  );
};

export default Copyrighttransferform;
