import React from "react";
import { NavLink } from "react-router-dom";

const Slide = (props) => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1>{props.name}</h1>
          <h2>{props.title}</h2>
          <NavLink to="/Allarticles" className="btn-get-started scrollto">
            <span
              style={{
                color: "#FFFFFF",
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "16px",
                borderBottom: "1px solid #FFFFFF",
              }}
            >
              Search
            </span>
            Articles
          </NavLink>
          {"     "}

          {/* <NavLink to="/addarticle" className="btn-get-started scrollto"><span style={{ color: '#FFFFFF', marginRight: '10px', fontWeight: 'bold', fontSize: '16px', borderBottom: '1px solid #F74B02' }}>Submit </span>Article</NavLink> */}
        </div>
      </section>
      {/* <!-- End Hero --> */}
    </>
  );
};

export default Slide;
