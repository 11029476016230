import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const Readers = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
   
      <div className="card">
      

        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
           Readers
          </div>
        </p>



        <p>
          Contents on this and other websites owned by Webmed Limited, UK are
          solely intended for the use of biomedical scientists and researchers
          for their scentific communications. They are not meant for use by
          members of general public and they are warned that their use of our
          websites is solely at their own risk. All the material published on
          this portal is free to the end user. Contents on this website (WMC) do
          not undergo any prepublication peer review and are published in a
          climate of post publication peer review. You do not have to register
          with us in order to read or download an article for private use. Your
          use of this website is in accordance with our <NavLink to="/termsandconditions" target="_blank">
          {" "}  "Terms and Conditions", </NavLink>

          <NavLink to="/disclaimer" target="_blank">
          {" "} "Disclaimers",  </NavLink>

          <NavLink to="/copyrightpolicy" target="_blank">
          {" "}  "Our Copyright Policy", </NavLink>
          
          <NavLink to="/privacypolicy" target="_blank">
          {" "}  "Our Privacy Policy", </NavLink>
          
          and       <NavLink to="/advertisingpolicy" target="_blank">
          {" "}   "Our
          Advertising Policy" </NavLink>
          . If you come across anything on this portal which
          is factually wrong or malicious, please 
          
          <NavLink to="/contact" target="_blank">
          {" "} 
          contact us.</NavLink>
          
          We may, at our
          sole discretion, remove the objectionable content from our website. If
          you come across anything on our portal, which you think can bring harm
          to somebody, please contact us and at the same time also inform the
          local legal authorities.
        </p>
        <p>
          You are encouraged to post reviews/comments on articles published on
          this portal. You will have to register with us in order to post a
          review/comment on any of the published articles. Please click on
          appropriate links on the article pages to post your review/comment.
          Our use of your information is in accordance with our 
          <NavLink to="/privacypolicy" target="_blank">
          {" "} 
          privacy policy.</NavLink>
       
          We do not take any responsibility for any advertising or third party
          links on our website. You click on such links at your own risk and any
          further use of such links is governed by the policies and terms and conditions of those websites. Hope you find this website useful!

          


        </p>
      </div>
      <Footer />
    </>
  );
};

export default Readers;
