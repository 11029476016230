import React, { useEffect, useState } from "react";
import Choose from "../components/Choose";
import Navbar from "../components/Navbar";
// import Departments from '../components/Departments';
// import Aboutus from '../components/Aboutus';
import Footer from "../components/Footer";
import Slide from "../components/Slide";
// import Editor from '../components/Editor';
import { NavLink } from "react-router-dom";

const Home = () => {
  const [showModal, setShowModal] = useState(true); // State to control modal visibility

  useEffect(() => {
    // Close the modal after 5 seconds
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, 50000000);

    // Cleanup function to clear the timeout when component unmounts
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect will run once after the initial render

  return (
    <>
      <Navbar />

      {/* Modal HTML */}
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                  style={{ color: "#067ADC", fontSize: "15px" }}
                >
                  We are happy to share the latest updates on WebmedCentral !!!
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  "Welcome to our revamped website! Whether you're a first-time
                  visitor or a returning member of our community, we're thrilled
                  to have you here.
                </p>
                {/* <p>For those who are discovering us for the first time, we extend a warm greeting and invite you to explore the open access and guaranteed publication of articles and many that our website. 
      From informative articles and engaging blog posts to exciting features and useful resources, there's something here for everyone.</p>
     */}
                <p>
                  To our loyal users who have been with us on this journey,
                  welcome back! We're excited to unveil our refreshed look and
                  enhanced features designed with you in mind. We appreciate
                  your continued support and feedback as we strive to provide
                  you with an even better experience.
                </p>
                <p>Please check the below instructions </p>
                <ul style={{ "list-style-type": "disc", "margin-left": "5px" }}>
                  <li style={{ "margin-bottom": "5px" }}>
                    All existing users can access the account using
                    <span style={{ color: "#F74B02" }}> "email"</span> and
                    password as{" "}
                    <span style={{ color: "#F74B02" }}> temppass</span> where
                    you can change the password after login using
                    <NavLink
                      to="https://webmedcentral.com/forgotpassword"
                      target="_blank"
                    >
                      {" "}
                      "forgot password"{" "}
                    </NavLink>
                  </li>
                  <li style={{ "margin-bottom": "10px" }}>
                    The complete data migration is still in process and takes
                    few weeks more.
                  </li>
                  <li style={{ "margin-bottom": "10px" }}>
                    If any articles submitted are found missing after reviewing
                    your account page or any of the images, illustrations data
                    etc are missing in the article view, kindly send an e-mail
                    to{" "}
                    <NavLink
                      to="mailto:support@webmedcentral.co.uk"
                      target="_blank"
                    >
                      {" "}
                      support@webmedcentral.co.uk{" "}
                    </NavLink>
                    with the article details where our team will submit the same
                    on your behalf and publish it.
                  </li>
                </ul>
                <p>
                  Kindly take some time to navigate through our new site,
                  discover new content and connect with our community.{" "}
                </p>
                <p>
                  Our team sincerely working hard to work on the pending
                  features, mobile apps and data migration tasks to make the
                  site very user friendly helping the medical community people
                  and authors to access the content free of cost.
                </p>
                <p>
                  Thank you for being a part of WebmedCentral family—we couldn't
                  do it without you!
                </p>
                <p> Happy exploring!"</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide name="Open access" title="Post Publication Peer Review" />
      <br />
      <Choose />

      {/*     
    <Departments />
    <Editor /> */}
      {/* <Aboutus /> */}
      <Footer />
    </>
  );
};

export default Home;
