import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const InstructionsforAuthors = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Instructions for authors
          </div>
        </p>

        <p>
          WebmedCentral publishes articles in virtually every biomedical
          discipline. You can publish a manuscript on our website if you are
          either the sole author of the manuscript, or in case of multi author
          work, have permission from all the co-authors to do so. You may
          publish an article on WebmedCentral even if you are not one of the
          authors as long as authors have transferred the article copyrights to
          you. In publishing an article on WebmedCentral, you must not violate
          any copyrights or other third party rights. If you are not one of the
          authors, we would like to see the copyright transfer form stating that
          authors have transferred the article copyrights to you. You would be
          required to upload this form during article submission.
        </p>

        <p style={{ fontWeight: "bold" }}>Manuscript Submission:</p>
        <p>
          The article can only be submitted online. We are unable to process
          manuscripts submitted to us through post. This is necessary to keep
          our operational costs low. We have however just launched a premium
          paid service for busy authors. Please contact us if you are having
          difficulties submitting an article on WebmedCentral and we will try to
          help you. Authors are advised to read our Information for Authors
          section and also read “about us”, “our peer review policy”, “our
          copyright policy”, “our privacy policy”, “our advertising policy”,
          “terms and conditions” and “disclaimers” before submitting any article
          on this website. The submitting author is responsible for ensuring
          that all authors have seen the final draft of the manuscript and
          approved it.
        </p>
        <p style={{ fontWeight: "bold" }}> Criteria for Authorship:</p>

        <p>
          WebmedCentral strongly discourages both honorary and ghost
          authorships. We believe that only those who have contributed
          significantly to the article should claim authorship. At the same time
          all contributions must be acknowledged suitably. We fully endorse the
          ICMJE (International Committee of Medical Journal Editors) criteria
          for authorship which state that authorship credit should only be based
          on substantial contribution to (a)conception and design, or analysis
          and interpretation of data; and to (b)drafting the article or revising
          it critically for important intellectual content; and on (c)final
          approval of the version to be published. Conditions (a), (b) and (c)
          must all be met. Participation only in the acquisition of funding or
          the collection of data does not justify authorship, but it is good
          scientific practice to acknowledge other form of contributions
          appropriately. It is the responsibility of the submitting author to
          ensure that all the authors meet the criteria of authorship. It is
          also the responsibility of the authors collectively to make sure that
          no such author meeting all the above criteria has been denied
          authorship. All other contributions must be appropriately
          acknowledged.
        </p>

        <p style={{ fontWeight: "bold" }}> Patient confidentiality:</p>

        <p>
          If there is a chance that a patient may be identified from a case
          report, illustration, or paper, authors must submit a written consent
          form duly signed by the patient or a legal guardian (where it is not
          possible to obtain patient’s signature because of physical disability,
          minor patient, critical illness or death). Please scan and upload this
          form while submitting your article. We will want a consent form even
          if a picture has been taken using black bands across the eyes.
        </p>

        <p>
          WebmedCentral does not own responsibility if the authors fail to
          obtain relevant consents before publishing their manuscripts on our
          website. We presume that all the authors have composed manuscripts in
          line with our policies and instructions for authors. It is solely the
          responsibility of the authors to make sure that consents are obtained
          from patients or their legal guardian as and when necessary.
          WebmedCentral will perform random checks on the manuscript for
          compliance with our instructions and guidelines. Manuscripts not
          meeting our standards and guidelines would be removed from our
          website. We may also bar any such author(s) from publishing on
          WebmedCentral in future and share this information with others in
          biomedical publishing.
        </p>
        <p>
          Failure to obtain consent will mean that personal information about a
          patient cannot be published on WebmedCentral except in the rare
          situation of immense public health importance. Such manuscripts would
          be published solely on the discretion of WebmedCentral. Authors, while
          submitting their manuscripts must make it absolutely clear that they
          have not been able to obtain the consent stating also the reasons for
          the failure. Authors must also explain why they still believe that it
          is in general public interest that manuscript still be published on
          WebmedCentral. We are constantly reviewing our policies on these
          issues.
        </p>
        <p style={{ fontWeight: "bold" }}>Ethical considerations:</p>
        <p>
          ebmedCentral insists that authors seek approval from relevant ethical
          committees before conducting human investigations and animal
          experiments. Such ethical issues must be clearly mentioned in the
          manuscript with details of approval. If any such work comes to
          knowledge of WebmedCentral, where appropriate ethical standards have
          not been met, we will remove such work from our website. We advise our
          authors to make themselves aware about World Medical Association
          Declaration of Helsinki. WebmedCentral insists that authors follow
          these principles in conduct of scientific research.
        </p>

        <p style={{ fontWeight: "bold" }}>
          Illustrations (Pictures, Tables, Diagrams and Figures):
        </p>
        <p>
          For the sake of convenience, we do not differentiate between pictures,
          figures, tables, diagrams etc and they are collectively referred to as
          Illustrations. Authors must scan and save all individual illustrations
          as separate picture files (jpeg, tiff, png, bmp etc) on their
          computer. Files must be named appropriately (For example
          Illustration1, Illustration2, Illustration3).
        </p>
        <p>
          It is possible that the illustration may not be displayed online or
          shown in our pdf version in the best possible magnification required
          for viewing. It is hence strongly recommended that you submit all
          illustrations separately as one or more additional files. You may
          choose to compile all illustrations in an MS Word and submit it as an
          additional file. However you must appropriately identify the
          illustration in your manuscript while referring to them. For example
          you could refer to Illustration 1, Illustration 2 etc. Illustrations
          additional file should be named as “Illustration Additional File” and
          the original illustration numbers must be retained in this file too.
          Please refer to section on Additional files for further details in
          this regard.
        </p>
        <p>
          Tables should be simple to read and authors must not duplicate the
          information in the text. Colour photographs are preferred for obvious
          reasons. Authors are also encouraged to use colours in illustrations
          to make them attractive and user-friendly. Please check for correct
          orientation before saving the files. Please make sure that you include
          explanations of symbols and shading within the figure. The figures and
          tables must be properly labelled including the actual numbers as
          opposed to percentages. Efforts should be made to include all the
          numerical data on the figures in an easily comprehensible way. If any
          of the tables or figures submitted has been published elsewhere,
          written consent for republication should be obtained from the
          copyright holder (usually the publisher). Such consent form can then
          be scanned, saved, and uploaded as an additional file.
        </p>
        <p style={{ fontWeight: "bold" }}>References:</p>
        <p>
          WebmedCentral accepts any of the commonly followed referencing styles.
          Please however follow a consistent theme across your article.
        </p>
        <p style={{ fontWeight: "bold" }}>Electronic citations:</p>
        <p>
          You may know of other websites that will be of interest to people
          reading your article. If you know the web addresses (URLs) of those
          sites, please include them in the relevant places in the text of your
          article.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Source of funding and competing interests:
        </p>
        <p>
          Please declare the information regarding source of funding and
          competing interests during article submission. A competing interest
          exists when your professional judgement may be influenced by any other
          interest in the publication of an article/video/review/comment on our
          portal. This other interest may be financial gains, share holding in a
          company that manufactures the drug or device under study, grants,
          professional rivalry or personal beliefs. Essentially it arises when
          authors have an interest that may influence, with or without their
          knowledge, their interpretation and presentation of scientific
          material. We believe that our
          authors/reviewers/commentators/WebmedCentral editors should make these
          interests clear before posting any content on this portal.
        </p>
        <p style={{ fontWeight: "bold" }}>Our role:</p>
        <p>
          Most of our publication process is automated and author driven.
          Authors are in full control of their manuscript. After an article is
          submitted, authors would be sent an email to indicate that the article
          has been received by us. The article then needs to be approved by
          WebmedCentral before being published. We aim to publish most articles
          within 48 hours of submission.
        </p>

        <p style={{ fontWeight: "bold" }}>Article Categories:</p>
        <p>
          Please click here for a list of article categories in which we accept
          articles.
        </p>
        <p style={{ fontWeight: "bold" }}>Subject Categories:</p>
        <p>
          Please click here for a list of subject categories in which we accept
          articles.
        </p>
        <p style={{ fontWeight: "bold" }}>Preparation of manuscripts:</p>
        <p>
          Please refer to our checklist for submission for the list of things
          you would need to submit an article on WebmedCentral
        </p>
        <p>
          We allow our authors the flexibility of submitting additional files if
          they have any. For example, you may have separate data files,
          questionnaire files or other files that you might want to make
          available for the readers. Please name them appropriately (like
          Additional File 1, Additional File 2 etc.) and cite them in the text
          of the article when referred to. You are encouraged to submit
          illustrations as additional files too.
        </p>
        <p>
          You would be asked to select an article category (like case report,
          review article etc.) and a subject category (allergy, immunology,
          surgery etc.) for your article.
        </p>
        <p>
          You would be asked to enter key words for your article (up to ten).
        </p>
        <p style={{ fontWeight: "bold" }}>Peer review policy:</p>
        <p>
          You will be asked to identify a minimum of five referees from the
          WebmedCentral Editorial board. You can choose appropriate referees by
          visiting their profile or using a key word search appropriate to the
          research being submitted. However, you may invite more to generate a
          wider debate around your work. If you would like to invite more
          reviewers or if you have not found suitable editors from amongst our
          team, please recommend other specialist reviewers. Please ensure that
          these reviewers have experience in the subject of your manuscript.
          Further, these reviewers should not be in the same institution as
          yourself or have been co-authors with any of the authors of your
          manuscript within the last three years. Any referees suggestions not
          from our editorial board will be randomly checked to ensure they meet
          the above criteria. Our peer review process is entirely transparent
          with all reviews being freely available on our portal for the readers.
          You may choose to respond to the reviews using the author’s comment
          section.
        </p>
        <p>
          We ask referees for a detailed review. This is published alongside
          your article. We also ask our referees to select one of three statuses
          to judge the scientific value of the submitted article: Suitable for
          indexing (similar to ‘suitable for publication’ or minor revisions in
          the traditional pre- publication peer reviewed model), Suitable for
          indexing with major revisions’ (similar to suitable for publication
          with major revisions in the traditional model) or Not suitable for
          indexing (similar to ‘reject’ in the traditional model).
        </p>
        <p>
          Articles that have attracted satisfactory ratings (‘suitable for
          indexing’) from two reviewers automatically move to ‘WebmedCentral
          Peer Reviewed’ category. We hope that these articles will be indexed
          by appropriate bodies in near future. We strongly encourage you to
          submit revised versions of articles with additional information to
          satisfy queries raised.
        </p>
        <p style={{ fontWeight: "bold" }}>Abstract:</p>
        <p>
          Though there is no word limit for composing abstracts, most abstracts
          can give readers a brief summary of the article in about 250 words.
          Most abstracts are structured under the following four subheadings -
          Background, Methods, Results and Conclusions.
        </p>
        <p style={{ fontWeight: "bold" }}>Abbreviations:</p>
        <p>
          The use of abbreviations must be minimised. All the abbreviations must
          be expanded on their first use and a list provided at the end of the
          article under the appropriate heading.
        </p>
        <p style={{ fontWeight: "bold" }}>Equations:</p>
        <p>
          Please upload equations as illustrations. You may choose to copy all
          equations in a word file (.doc or .docx) and allocate numbers in the
          order of appearance. They would need to be referenced in the
          manuscript. Please note that the equations would appear at the end of
          the manuscript.
        </p>
        <p style={{ fontWeight: "bold" }}>Style:</p>
        <p>
          We advise authors to write in a clear, direct, and active style. The
          only purpose of scientific communication is to exchange information
          effectively and that should be done in plain and simple English as
          much as possible. Please avoid using long and complicated sentences.
          We do not impose restrictions regarding use of British or American
          English as long as authors consistently follow one style throughout
          their manuscript.
        </p>
        <p>
          Scientific measurements should be given in SI units, except for blood
          pressure, which may be expressed in mm Hg. Authors are responsible for
          the accuracy of their work, including all statistical calculations and
          drug doses. Drugs should normally be referred to by their generic
          names to avoid confusion. When quoting specific materials, equipment
          and proprietary drug names the name and address of the manufacturer
          must be given in parentheses. It is also common practice to spell out
          numbers less than 10.
        </p>
        <p>
          WebmedCentral does not impose unnecessary restrictions on authors. Our
          authors are free to present the information in a way they think it
          would be easiest for the reader to comprehend.
        </p>
        <p style={{ fontWeight: "bold" }}>Statistics:</p>
        <p>
          We advise our authors to seek appropriate professional statistical
          advice at an early stage in the conduct of a research and make sure
          that all the data analysis is done using sound statistical practices.
          It is not possible for biomedical researchers to have a thorough grasp
          of what is clearly a very difficult stream of science and we advise
          all our authors to run their final manuscript past a qualified
          statistician to weed out discrepancies and inaccuracies. It is good
          scientific practice to detail the statistical methods and techniques
          used in the manuscript for the benefit of individual reader. Please
          provide the original data in additional files where necessary. This
          would further enable readers to judge your manuscript accordingly. And
          it would help reviewers in future to analyse the data presented in
          your study.
        </p>
        <p style={{ fontWeight: "bold" }}>Guidelines to conduct your study:</p>
        <p>
          1. For reporting randomized controlled trials authors are advised to
          consult CONSORT guidelines. Please submit the CONSORT checklist as an
          additional file and the flowchart as an illustration with the
          manuscript. 2. The Research protocol can also be submitted on our
          website separately. However please make sure that you choose the
          article category properly and that Research Protocols are clearly
          identified. 3. If you are submitting a systematic review please follow
          the PRISMA (formerly QUORUM) guidelines. 4. Please follow STARD
          guidelines for studies on diagnostic accuracy.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Role of professional medical writers in peer-reviewed publications:
        </p>
        <p>
          WebmedCentral appreciates that some authors may want to use the
          services of professional medical writers. However bearing in mind our
          completely transparent approach towards biomedical publishing, it must
          be made clear to the authors and the medical writer in question must
          be suitably acknowledged in the appropriate section of the manuscript.
          And the payments to these authors should be elaborated in our
          appropriate section regarding funding and sources of funding
          identified. Medical writers have professional responsibilities to
          ensure that the papers they write are scientifically valid and are
          written in accordance with generally accepted ethical standards.
          Medical writers must naturally have access to all relevant data while
          writing papers. It is however the responsibility of the authors to
          check the manuscript for complete accuracy and make absolutely sure
          that it says exactly what they intended to say.
        </p>
        <p style={{ fontWeight: "bold" }}>Plagiarism:</p>
        <p>
          Plagiarism is the use of others’ published and unpublished ideas or
          words (or other intellectual property) without attribution or
          permission, and presenting them as new and original rather than
          derived from an existing source. This applies whether the ideas or
          words are taken from abstracts, research grant applications, ethics
          committee applications, or unpublished or published manuscripts in any
          publication format (print or electronic). Plagiarism is a serious
          scientific misconduct.
        </p>
        <p style={{ fontWeight: "bold" }}>Scientific misconduct:</p>
        <p>
          If you have any concerns regarding the scientific content of any
          article or the conduct of the authors published on WebmedCentral,
          please contact us immediately. We are aware that some authors would
          try to misuse this wonderful completely transparent biomedical
          publishing service to promote their personal motives and WebmedCentral
          strongly opposes such practices. In such cases, we will initially
          write to authors for clarification and may ask them to provide us with
          additional data. If we are not satisfied with the authors’ response,
          the article will be removed from WebmedCentral. The decision of
          WebmedCentral shall be final and binding on authors in such cases. We
          may also report such authors on our website and share the information
          with others in the field of biomedical publishing.
        </p>
        <p style={{ fontWeight: "bold" }}>
          Reporting clinical trials conducted by pharmaceutical companies:
        </p>
        <p>
          Please ensure that clinical trials sponsored by pharmaceutical
          companies follow the guidelines on good publication practice. These
          guidelines aim to ensure that such trials are published in a
          responsible and ethical manner. The guidelines cover companies’
          responsibility to endeavour to publish results of all studies,
          companies’ relations with investigators, measures to prevent redundant
          or premature publication, methods to improve trial identification and
          the role of professional medical writers.
        </p>
        <p style={{ fontWeight: "bold" }}>Corrections and revisions:</p>
        <p>
          We have no post submission article editing mechanisms to weed out
          grammatical and other errors. It is the authors’ responsibility to
          ensure the manuscript for complete accuracy before submitting it on
          our website. We can make minor changes from the admin section, on the
          authors' request. The authors can, however, submit revised versions of
          the article. We allow any numbers of revised articles to be published
          on our website. The revised versions can be linked to the previous
          version by providing the unique WMC article number at resubmission. It
          is hoped that basic information would be entered correctly the first
          time.
        </p>
        <p style={{ fontWeight: "bold" }}>Responding to reviewers:</p>
        <p>
          Authors are encouraged to respond to the reviews or comments. This can
          be done by posting a comment in response. Please use "post a comment"
          for this functionality. Alternatively, for major changes, the authors
          can submit revised versions of the manuscript. The purpose of revised
          version is primarily to answer and accommodate the concerns and
          reviews made on the article on WebmedCentral and elsewhere. Our
          readers would always have access to the previous versions of the
          article.
        </p>
        <p style={{ fontWeight: "bold" }}>Withdrawing articles:</p>
        <p>
          Articles once published cannot be withdrawn by the authors. If the
          authors want to alter something in their original submission, they
          would have to submit revised version of the paper.
        </p>
        <p>
          WebmedCentral, however reserves the right to unpublish papers that are
          fraudulent, have been plagiarised, are clear examples of scientific
          misconduct or do not comply with our guidelines.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default InstructionsforAuthors;
