import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/features/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import PrivateRoute from "./components/PrivateRoute";
import AddEditArticle from "./pages/AddEditArticle";
import EditUser from "./pages/EditUser";
import Categories from "./components/Categories";
import VerifyUser from "./components/EmailVerify";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ArticleView from "./pages/ArticleView";
import PostReview from "./pages/PostReview";
import ViewReviews from "./pages/ViewReviews";
import Allarticles from "./components/Allarticles";
import ViewReview from "./pages/ViewReview";
import PaymentPage from "./components/PaymentPage";
import SuccessPage from "./components/SuccessPage";
import SearchbyType from "./components/SearchbyType";
import CategoryArticles from "./pages/CategoryArticles";
import Viewcomments from "./pages/Viewcomments";
import Config from "./components/Config";
import Editorsview from "./components/Editorsview";
import Aboutus from "./components/Aboutus";
import PeerReviewPolicy from "./pages/PeerReviewPolicy";
import PublicationPolicy from "./pages/PublicationPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandConditions from "./pages/TermsandConditions";
import Disclaimers from "./pages/Disclaimers";
import CopyrightPolicy from "./pages/CopyrightPolicy";
import AdvertisingPolicy from "./pages/AdvertisingPolicy";
import InstructionsforAuthors from "./pages/InstructionsforAuthors";
import Checklistforarticlesubmission from "./pages/Checklistforarticlesubmission";
import Readers from "./pages/Readers";
import Editors from "./pages/Editors";
import Authors from "./pages/Authors";
import Ourphilosophy from "./pages/Ourphilosophy";
import Contact from "./pages/Contact";
import Whypublishwithus from "./pages/Whypublishwithus";
import Cookiepolicy from "./pages/Cookiepolicy";
import Articletypes from "./pages/Articletypes";
import Patientconsentform from "./pages/Patientconsentform";
import Charges from "./pages/Charges";
import Premiumservice from "./pages/Premiumservice";
import Presscenter from "./pages/Presscenter";
import Copyrighttransferform from "./pages/Copyrighttransferform";
import Editorsprofile from "./components/Editorsprofile";
import ArticlesByType from "./pages/ArticlesByType";
import Pleaseread from "./components/Pleaseread";
import CategoriesList from "./components/CategoriesList";

const App = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  useEffect(() => {
    dispatch(setUser(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/article/:id" element={<ArticleView />}></Route>
        <Route path="/about" element={<Aboutus />}></Route>
        <Route path="/peerreviewpolicy" element={<PeerReviewPolicy />}></Route>

        <Route path="/Editorsprofile/:id" element={<Editorsprofile />}></Route>

        <Route
          path="/publicationpolicy"
          element={<PublicationPolicy />}
        ></Route>
        <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
        <Route
          path="/termsandconditions"
          element={<TermsandConditions />}
        ></Route>
        <Route path="/disclaimer" element={<Disclaimers />}></Route>
        <Route path="/copyrightpolicy" element={<CopyrightPolicy />}></Route>
        <Route
          path="/advertisingpolicy"
          element={<AdvertisingPolicy />}
        ></Route>
        <Route
          path="/instructionsforauthors"
          element={<InstructionsforAuthors />}
        ></Route>
        <Route
          path="/checklistforarticlesubmission"
          element={<Checklistforarticlesubmission />}
        ></Route>
        <Route path="/readers" element={<Readers />}></Route>
        <Route path="/editors" element={<Editors />}></Route>
        <Route path="/authors" element={<Authors />}></Route>

        <Route path="/charges" element={<Charges />}></Route>

        <Route path="/philosophy" element={<Ourphilosophy />}></Route>
        <Route path="/whypublishwithus" element={<Whypublishwithus />}></Route>
        <Route path="/cookiepolicy" element={<Cookiepolicy />}></Route>
        <Route
          path="/copyrighttransferform"
          element={<Copyrighttransferform />}
        ></Route>

        <Route path="/config" element={<Config />}></Route>

        <Route path="/Editorsview" element={<Editorsview />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/editorsview" element={<Editorsview />}></Route>
        <Route path="/articletypes" element={<Articletypes />}></Route>


        
        

        <Route
          path="/articletypes/:articletype"
          element={<ArticlesByType />}
        ></Route>

        <Route
          path="/patientconsentform"
          element={<Patientconsentform />}
        ></Route>
        <Route path="/charges" element={<Charges />}></Route>
        <Route path="/premiumservice" element={<Premiumservice />}></Route>
        <Route path="/presscenter" element={<Presscenter />}></Route>

        <Route path="/Allarticles" element={<Allarticles />} />

        <Route path="/Searchbytype" element={<SearchbyType />} />
        <Route path="/ViewReview/:reviewId" element={<ViewReview />} />

        <Route path="/comments/:Id" element={<Viewcomments />} />
        <Route path="/pleaseread" element={<Pleaseread />}></Route>
        <Route path="/categorieslist" element={<CategoriesList />}></Route>

        

        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <PaymentPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment/success"
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
        />
        <Route path="/ViewReviews/:Id" element={<ViewReviews />} />

        <Route
          path="/postreview/:id"
          element={
            <PrivateRoute>
              <PostReview />
            </PrivateRoute>
          }
        ></Route>

        <Route path="/categories" element={<Categories />}></Route>

        <Route
          path="/categories/:categoryname"
          element={<CategoryArticles />}
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/users/:id/verify/:token" element={<VerifyUser />} />

        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>

        <Route
          path="/users/resetpassword/:userId/:token"
          element={<ResetPassword />}
        />

        <Route
          path="/addarticle"
          element={
            <PrivateRoute>
              <AddEditArticle />
            </PrivateRoute>
          }
        />

        <Route
          path="/editarticle/:id"
          element={
            <PrivateRoute>
              <AddEditArticle />
            </PrivateRoute>
          }
        />

        <Route
          path="/edituser/:id"
          element={
            <PrivateRoute>
              <EditUser />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
