import React from "react";
import { NavLink } from "react-router-dom";

const Choose = () => {
  return (
    <>
      {/* <!-- ======= Why Us Section ======= --> */}
      <section id="why-us" className="card why-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
              <div className="content">
                <h4>Guaranteed biomedical publication within 48 hours</h4>

                <ul style={{ listStyleType: "disc", marginLeft: "5px" }}>
                  <li style={{ marginBottom: "5px" }}>Open access</li>
                  <li style={{ marginBottom: "10px" }}>
                    Post Publication Peer Review
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Transparency in scientific communications
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Team of highly acclaimed Editors
                  </li>
                </ul>

                <div className="text-center">
                  <NavLink to="#" className="more-btn">
                    Learn More <i className="bx bx-chevron-right"></i>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-8 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-receipt"></i>
                      <h4>Empowering publishing</h4>
                      <p>
                        Biomedical publishing needs to evolve to empower
                        authors, reviewers and readers.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-cube-alt"></i>
                      <h4>Unique publication</h4>
                      <p>
                        Our unique publication system is designed to empower
                        authors and provide them with complete freedom of
                        expression
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 d-flex align-items-stretch">
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className="bx bx-images"></i>
                      <h4>Efficient and economical</h4>
                      <p>
                        We are attempting to make the process of publishing more
                        inclusive, efficient, accountable, economical, unbiased
                        and transparent
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End .content--> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Why Us Section --> */}
    </>
  );
};

export default Choose;
