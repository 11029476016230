import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "./img/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {

  const [aboutDropdownVisible, setAboutDropdownVisible] = useState(false);
  const [authorsDropdownVisible, setAuthorsDropdownVisible] = useState(false);

  const handleAboutMouseEnter = () => {
    setAboutDropdownVisible(true);
  };

  const handleAboutMouseLeave = () => {
    setAboutDropdownVisible(false);
  };

  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <div>
            <img
              src={logo}
              alt="logo"
              width="400"
              height="300"
              className="img-fluid"
            />
            <p
              style={{
                display: "inline-block",
                fontStyle: "italic",
                fontSize: "14px",
              }}
            >
              Open Access Biomedical Publisher Using Post Publication Peer
              Review
            </p>
          </div>

          <Navbar id="navbar" expand="lg" className="navbar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  exact
                >
                  Home
                </NavLink>

                <NavDropdown
        title="About"
        id="basic-nav-dropdown"
        show={aboutDropdownVisible}
        onMouseEnter={handleAboutMouseEnter}
        onMouseLeave={handleAboutMouseLeave}
      >
                  <div className="dropdown-item">
                    <NavLink to="/about">About</NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/philosophy">Our Philosophy</NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/peerreviewpolicy">
                      Our Peer Review Policy
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/publicationpolicy">
                      Our Publication Policy
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/whypublishwithus">
                      Why Publish With us
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/cookiepolicy">Cookie Policy</NavLink>
                  </div>
                  <div className="dropdown-divider" />
                  <div className="dropdown-item">
                    <NavLink to="/presscenter">Press Center</NavLink>
                  </div>
                </NavDropdown>

                <NavDropdown
        title="Authors"
        id="basic-nav-dropdown"
        show={authorsDropdownVisible}
        onMouseEnter={() => setAuthorsDropdownVisible(true)}
        onMouseLeave={() => setAuthorsDropdownVisible(false)}
      >
                  <div className="dropdown-item">
                    <NavLink to="/authors">Authors</NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/instructionsforauthors">
                      Instructions for Authors
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/checklistforarticlesubmission">
                      Check list for Submitting an Article
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/patientconsentform">
                      Patient consent form
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to="/charges">charges</NavLink>
                  </div>
                  <div className="dropdown-divider" />
                  <div className="dropdown-item">
                    <NavLink to="/premiumservice">Premium service</NavLink>
                  </div>
                </NavDropdown>

                <NavLink
                  to="/readers"
                  className="nav-link"
                  activeClassName="active"
                >
                  Readers
                </NavLink>

                <NavLink
                  to="/editors"
                  className="nav-link"
                  activeClassName="active"
                >
                  Editors
                </NavLink>

                <NavLink
                  to="/contact"
                  className="nav-link"
                  activeClassName="active"
                >
                  Contact
                </NavLink>

                <NavLink
                  to="/Allarticles"
                  className="nav-link"
                  activeClassName="active"
                >
                  <span style={{ color: "#067ADC" }}>Search Articles</span>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <NavLink to={"/addarticle"} className="appointment-btn scrollto">
            <span className="d-none d-md-inline">Submit an</span> Article
          </NavLink>
        </div>
      </header>
      {/* <!-- End Header --> */}
    </>
  );
};

export default Header;
