import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import powerpoint from "../components/img/wmc-plus-ppt.png";
import pptFile from "../components/img/WMC-PPT.ppt";
import brochurepdf from "../components/img/WMC-WMCP-Brochure.pdf";
import brochure1 from "../components/img/wmc-plus-brochure-1.png";
import brochure from "../components/img/wmc-plus-brochure.png";
import wmcpluslogo from "../components/img/webmedcentral-plus-logo.png";
import wmclogo from "../components/img/webmedcentral-logo.png";
import pluslogozip from "../components/img/webmedcentral-plus-logos.zip";
import logozip from "../components/img/webmedcentral-logos.zip";
import whyvideo from "../components/img/Video 1_ WebmedCentral - Why_.mp4";
import howvideo from "../components/img/Video 2_ WebmedCentral - How_.mp4";
import "../Presscenter.css";

const Presscenter = () => {
  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = pptFile;
    anchor.download = "WMC-PPT.ppt";
    anchor.click();
  };

  const handleDownloadBrochure = () => {
    const anchor = document.createElement("a");
    anchor.href = brochurepdf;
    anchor.download = "brochurepdf";
    anchor.click();
  };

  const handleDownloadPlusLogo = () => {
    const anchor = document.createElement("a");
    anchor.href = pluslogozip;
    anchor.download = "webmedcentral-plus-logos.zip";
    anchor.click();
  };

  const handleDownloadLogo = () => {
    const anchor = document.createElement("a");
    anchor.href = logozip;
    anchor.download = "webmedcentral-logos.zip";
    anchor.click();
  };

  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Press-Center-tab"
                data-bs-toggle="tab"
                data-bs-target="#Press-Center"
                type="button"
                role="tab"
                aria-controls="Press-Center"
                aria-selected="true"
              >
                Press Center
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Profile
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Contact
              </button>
            </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="Press-Center"
              role="tabpanel"
              aria-labelledby="Press-Center-tab"
            >
              <br />
              <h4>WebmedCentral History</h4>
              <p>
                WebmedCentral (
                <NavLink to="/" target="_blank">
                  www.webmedcentral.com
                </NavLink>{" "}
                also known as WMC) "Terms and Conditions", is a unique platform
                that publishes biomedical scientific content without any
                pre-publication peer review. That there was a need for such a
                platform became obvious when within a short span of time, it has
                already got thousands of registered users and hundreds of
                articles (1000 at last count).
              </p>

              <div className="container">
                <video controls width="500" height="400">
                  <source src={whyvideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {"          "}

                <video controls width="500" height="400">
                  <source src={howvideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <hr />

              <h4>Power Point</h4>
              <p>
                <img
                  src={powerpoint}
                  alt="logo"
                  width="600"
                  height="400"
                  className="img-fluid"
                />
              </p>
              <button className="btn btn-primary" onClick={handleDownload}>
                Download Power Point
              </button>

              <hr />

              <h4>Brochure</h4>
              <div className="brochure-container">
                <div className="brochure-image">
                  <img
                    src={brochure1}
                    alt="logo"
                    width="300"
                    height="200"
                    className="img-fluid"
                  />
                </div>
                <div className="brochure-image">
                  <img
                    src={brochure}
                    alt="logo"
                    width="300"
                    height="200"
                    className="img-fluid"
                  />
                </div>
              </div>
              <br />

              <button
                className="btn btn-primary"
                onClick={handleDownloadBrochure}
              >
                Download Brochure
              </button>

              <hr />

              <h4>Logos</h4>

              <div className="brochure-image">
                <img
                  src={wmcpluslogo}
                  alt="pluslogo"
                  width="700"
                  height="200"
                  className="img-fluid"
                />
                <br />

                <button
                  className="btn btn-primary"
                  onClick={handleDownloadPlusLogo}
                >
                  Download Plus Logos
                </button>

                <br />
                <br />
                <br />
              </div>
              <br />
              <div className="brochure-image">
                <img
                  src={wmclogo}
                  alt="logo"
                  width="700"
                  height="200"
                  className="img-fluid"
                />
              </div>

              <button className="btn btn-primary" onClick={handleDownloadLogo}>
                Download WMC Logos
              </button>
            </div>
            {/* 
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              ...
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Presscenter;
