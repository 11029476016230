import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../components/Config";

export const postReview = createAsyncThunk(
  "review/postReview",
  async (
    {
      formData,
      id,
      artid,
      userid,
      articletitle,
      usertitle,
      userfirstname,
      usermiddlename,
      userlastname,
      navigate,
      toast,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}/reviews/${id}`, {
        formData,
        id,
        artid,
        userid,
        articletitle,
        usertitle,
        userfirstname,
        usermiddlename,
        userlastname,
      });

      // This is another method using fetch instead of axios
      // const response = await fetch(`${BASE_URL}/reviews/${id}`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ formData, id, artid, userid,articletitle,  usertitle, userfirstname,usermiddlename,userlastname }),
      // });
      // console.log("response", response)
      toast.success(response.data.message);
      navigate(`/article/${artid}`);

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchReviews = createAsyncThunk(
  "review/fetchReviews",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/reviews`);
      return response.data; // response.data contains the reviews array
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// export const fetchReviews = createAsyncThunk(
//     "review/fetchReviews",
//     async (articleId, { rejectWithValue }) => {
//       try {
//         const response = await axios.get(`http://localhost:3001/reviews/${articleId}`);
//         if (!response.ok) {
//           const errorData = await response.json();
//           return rejectWithValue(errorData);
//         }
//         const data = await response.json();
//         return data;
//       } catch (error) {
//         return rejectWithValue(error.message);
//       }
//     }
//   );

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    error: "",
    loading: false,
    reviews: [],
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: {
    [postReview.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    // [postReview.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   const { id, formData } = action.payload;
    //   const reviewToUpdate = state.reviews.find((review) => review.id === id);
    //   if (reviewToUpdate) {
    //     reviewToUpdate.reviews.push(formData);
    //   }
    // },

    [postReview.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    [postReview.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [fetchReviews.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchReviews.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.reviews = action.payload;
    },
    [fetchReviews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setCurrentPage } = reviewSlice.actions;

export default reviewSlice.reducer;
