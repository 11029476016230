import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PeerReviewPolicy = () => {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="card">
        <p style={{ fontWeight: "bold" }}>
          <div
            className="card-header"
            style={{ color: "#067ADC", fontSize: "16px" }}
          >
            Our Peer Review Policy
          </div>
        </p>
        We publish all submitted research articles immediately on our portal.
        There is a rapid in house checking process to ensure that all but the
        clearly irrelevant and unscientific work is published within 48 hours of
        submission. The peer review process begins immediately after publication
        of the article. We endeavour to ensure that all articles are reviewed by
        at least two peers. Authors choose potential reviewers from amongst
        theWebmedCentral Editorial board. We also encourage authors to invite
        other reviewers to generate a wider debate around their work. We advise
        our authors to avoid choosing reviewers from their institution or anyone
        who they have collaborated within the last 3 years. Any suggested
        reviewer who is not part of our editorial team is randomly checked to
        ensure there is no conflict of interest. Articles that have attracted
        satisfactory ratings from two reviewers automatically move to
        ‘WebmedCentral Peer Reviewed’ category. We hope that these articles will
        be indexed by appropriate bodies in near future. Our peer review process
        is entirely transparent with all reviews being freely available on our
        portal for the readers. Reviewers can however choose to stay anonymous
        if they wish. Authors can respond to the reviews using authors' comment
        section and the readers would have full access to the entire communiqué.
        Our intention is to generate a healthy debate on each published work. We
        encourage authors to submit revised versions of articles with additional
        information to satisfy queries raised. This ensures that manuscripts
        never become outdated.
      </div>

      <Footer />
    </>
  );
};

export default PeerReviewPolicy;
