import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategories } from "../api";

// Async action to fetch categories
export const fetchCategoriesAsync = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const categories = await fetchCategories();
    return categories;
  }
);

// Category reducer
const categorySlice = createSlice({
  name: "category",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default categorySlice.reducer;
