import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import profilepic from "../components/img/samplepic.jpg";
import { getTour } from "../redux/features/tourSlice";
import { fetchAllUsers } from "../redux/features/userSlice";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Viewcomments = () => {
  const { Id } = useParams();
  const { tour } = useSelector((state) => state.tour);
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Fetch all users when the component mounts and whenever it renders
    dispatch(fetchAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (Id) {
      dispatch(getTour(Id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  // Check if tour is undefined or if comments is not an array
  if (!tour || !Array.isArray(tour.comments)) {
    return <div>Loading...</div>; // You can show a loading indicator or handle this case as needed
  }

  const commentsPerPage = 5;
  const totalPages = Math.ceil(tour.comments.length / commentsPerPage);
  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, startPage + 4);

      if (startPage > 1) {
        pageNumbers.push(1, "...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        pageNumbers.push("...", totalPages);
      }
    }

    return pageNumbers;
  };

  const startIndex = (currentPage - 1) * commentsPerPage;
  const endIndex = startIndex + commentsPerPage;
  const displayedComments = [...tour.comments]
    .reverse()
    .slice(startIndex, endIndex);

  const commentCountForArticle = tour.comments.length;

  const handlePageChange = (page) => {
    if (typeof page === "number") {
      setCurrentPage(page);
    } else if (page === "...") {
      // Handle the case when ellipsis is clicked, e.g., load more pages
      // You can implement the logic to load more pages here
    }
  };

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />

      <section className="card">
        Comments posted for the article titled
        <br />
       
        <div className="card container card-title text-center">
        <Link
          to={`/article/${tour.articleId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <h4 className="mb-1" style={{ color: "#067ADC" }}>
            {tour.title} {commentCountForArticle}
          </h4>
        </Link>
        </div>
        <div className="pagination d-flex justify-content-end">
          {getPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(number)}
              className={`btn btn-outline-primary page-button ${
                currentPage === number ? "active" : ""
              }`}
            >
              {number}
            </button>
          ))}
        </div>
        <br />
        {tour &&
          tour.comments &&
          displayedComments.map((comment) => {
            // Find the user corresponding to the comment's userid
            const matchingUser = users.find(
              (user) => user._id === comment.userid
            );

            // Check if a matching user is found
            if (matchingUser) {
              return (
                <React.Fragment key={comment._id}>
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img
                        class="rounded-circle"
                        // src="https://dummyimage.com/50x50/ced4da/6c757d.jpg"
                        src={matchingUser.profileImage || profilepic}
                        alt="ProfileImage"
                        style={{
                          marginTop: "0px",
                          maxWidth: "75px",
                          maxHeight: "75px",
                        }}
                      />
                    </div>

                    <div class="card col-sm-8">
                      <div class="fw-lite">
                        <label
                          htmlFor="title"
                          className="col-sm-0 col-form-label"
                          style={{ color: "#067ADC" }}
                        >
                          <span
                            className="pi pi-user"
                            style={{ color: "#F74B02" }}
                          >
                            {" "}
                          </span>{" "}
                          {"  "}
                        </label>{" "}
                        {matchingUser.title}
                        {".  "}
                        {matchingUser.firstName}
                        {"  "}
                        {matchingUser.middleName}
                        {"  "}
                        {matchingUser.lastName}
                      </div>
                      <span style={{ color: "#067ADC" }}>
                        " {comment.comment} "
                      </span>

                      <label
                        htmlFor="title"
                        className="col-sm-12 col-form-label"
                        style={{ color: "#067ADC" }}
                      >
                        <span
                          className="pi pi-calendar"
                          style={{ color: "#F74B02" }}
                        >
                          {" "}
                        </span>{" "}
                        {` ${new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(comment.postedDate))}`}
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={comment._id}>
                  {/* Render comment with placeholders for user info */}
                  Unknown User
                  <span style={{ color: "#067ADC" }}>{comment.comment}</span>
                </React.Fragment>
              );
            }
          })}
        {/* {displayedComments.map((comment) => (
          <div key={comment._id} class="d-flex">
            <div class="flex-shrink-0">
              <img
                class="rounded-circle"
                src={comment.image || profilepic}
                alt="ProfileImage"
                style={{
                  marginTop: "0px",
                  maxWidth: "75px",
                  maxHeight: "75px",
                }}
              />
            </div>
            <div class="card col-sm-8">
              <div class="fw-lite">
                <label
                  htmlFor="title"
                  className="col-sm-0 col-form-label"
                  style={{ color: "#067ADC" }}
                >
                  <span className="pi pi-user" style={{ color: "#F74B02" }}>
                    {" "}
                  </span>{" "}
                  {"  "}
                </label>{" "}
                {comment.title}
                {".  "}
                {comment.firstname}
                {"  "}
                {comment.middlename}
                {"  "}
                {comment.lastname}
              </div>
              <span style={{ color: "#067ADC" }}>" {comment.comment} "</span>
              <br />
              <label
                htmlFor="title"
                className="col-sm-12 col-form-label"
                style={{ color: "#067ADC" }}
              >
                <span className="pi pi-calendar" style={{ color: "#F74B02" }}>
                  {" "}
                </span>{" "}
                {`${new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(comment.postedDate))}`}
              </label>
            </div>
          </div>
        ))} */}
        <div className="pagination">
          {getPageNumbers().map((number, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(number)}
              className={`btn btn-outline-primary page-button ${
                currentPage === number ? "active" : ""
              }`}
            >
              {number}
            </button>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Viewcomments;
